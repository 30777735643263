import { EnumMetadata } from "@classes/enumUtils";

export enum TriageLevel {
	level1, level2
}

export namespace TriageLevel {
	const enumData: EnumMetadata<TriageLevel>[] = [
		[TriageLevel.level1, "Level 1", "level1"],
		[TriageLevel.level2, "Level 2", "level2"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): TriageLevel {
		return values.get(value);
	}

	export function toString(value: TriageLevel): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: TriageLevel): string {
		return pgEnums.get(value);
	}

	export function allValues(): TriageLevel[] {
		return enumData.map( item => item[0] );
	}
}
