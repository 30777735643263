export interface InvestigationCategory {
	id: string;
	name: string;
	sortOrder: number;
	count: number;
	readonly readonly: boolean;
}

export namespace InvestigationCategory {
	export function toJSON(src: InvestigationCategory) {
		return {
			"id": src.id,
			"name": src.name,
			"sortOrder": src.sortOrder,
			"count": src.count,
			"readonly": src.readonly
		};
	}

	export function parse(src: any): InvestigationCategory {
		return {
			"id": src.id,
			"name": src.name,
			"sortOrder": src.sortOrder,
			"count": src.count,
			"readonly": src.readonly
		};
	}

	export function clone(src: InvestigationCategory): InvestigationCategory {
		return {
			"id": src.id,
			"name": src.name,
			"sortOrder": src.sortOrder,
			"count": src.count,
			"readonly": src.readonly
		};
	}

	export function empty() {
		return {
			"id": undefined,
			"name": undefined,
			"sortOrder": undefined,
			"count": undefined,
			"readonly": false
		};
	}
}
