export enum Gender {
	male, female, other
}

export namespace Gender {

	export function allValues(): Gender[] {
		return [Gender.male, Gender.female, Gender.other];
	}

	export function parse(src: string): Gender {
		switch (src) {
			case 'male': return Gender.male;
			case 'female': return Gender.female;
			case 'other': return Gender.other;
			default: return undefined;
		}
	}

	export function toString(src: Gender): string {
		switch (src) {
			case Gender.male: return 'Male';
			case Gender.female: return 'Female';
			case Gender.other: return 'Other';
			default: return undefined;
		}
	}

	export function toPostgresEnum(src: Gender): string {
		switch (src) {
			case Gender.male: return 'male';
			case Gender.female: return 'female';
			case Gender.other: return 'other';
			default: return undefined;
		}
	}
}
