import { Injectable } from "@angular/core";
import { User } from "@classes/user";
import { Title } from '@angular/platform-browser';
import { Branding, Theme, ThemeElement } from "@classes/theme";

@Injectable({ "providedIn": "root" })
export class BrandingService {

	private static readonly defaultFavicon = '/assets/favicon.ico';
	private static readonly defaultTitle = 'Plan Magic';

	constructor(private titleService: Title) {}

	public setDocumentTitle(title: string) {
		this.titleService.setTitle(title || BrandingService.defaultTitle);
	}

	public setFavicon(theme: Theme) {
		const faviconElement: HTMLLinkElement = document.querySelector('link[rel="icon"]');
		faviconElement.href = theme.logo || BrandingService.defaultFavicon;
	}

	public setup(theme: Theme, documentTitle?: string) {
		this.setDocumentTitle(documentTitle);
		this.setFavicon(theme);
		this.setTheme(theme);
	}

	public setTheme(theme: Theme) {
		(theme?.items || []).forEach( themeElement => {
			if (!!themeElement.varName) {
				document.documentElement.style.setProperty(`--${themeElement.varName}`, themeElement.value || "");
			}
		});
	}
}
