import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { OfflineAuthService } from '@services/offlineAuth.service';
import { UserType, User } from '@classes/user';
import { StorageService, SessionStorageService } from '@services/storage.service';
import { StringUtils, DateUtils } from '@classes/utils';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { ENotAuthorised, EPasswordResetRequired } from '@classes/errors';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';
import { APIClass } from 'aws-amplify';
import { API } from '@classes/api';
import { BrandingService } from '@services/branding.service';
import { Permissions, Permission } from '@classes/permissions';

@Injectable({ providedIn: 'root' })
export class AuthService {

	private static defaultLoginFailureMessage = 'Incorrect username or password.';

	private _currentAuthState = undefined;
	private _signedIn = false;
	private _user: User = undefined;
	private _subscription: Subscription;
	private _api: APIClass;

	private _awsAuthUser: any;

	public get isLoggedIn(): boolean {
		return this._signedIn;
		// return this._signedIn && this._user !== undefined;
	}

	public get currentUser(): User {
		return this._user;
	}

	public get isAdmin(): boolean {
		return this._signedIn && this._user.accountType === UserType.Admin;
	}

	public get isUser(): boolean {
		return this._signedIn && this._user.accountType === UserType.User;
	}

	public get isClient(): boolean {
		return this._signedIn && this._user.accountType === UserType.Participant;
	}

	public get isMarauderAdmin(): boolean {
		return User.isMarauderAdmin(this.currentUser);
	}

	private get passwordChangeRequired(): boolean {
		return this._currentAuthState === "requireNewPassword";
	}

	public readonly userLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);

	public async logout() {
		this._user = undefined;
		this._awsAuthUser = undefined;
		this._signedIn = false;

		SessionStorageService.clear();

		await Promise.all([StorageService.entities.clear(), StorageService.general.clear()]);

		this.amplifyService.auth().signOut();
		this.router.navigate(["/"]);
		this.userLoaded$.next(false);

		// Reload the window to prevent weird behaviour when logging into a different account
		setTimeout( () => { window.location.reload(); }, 200);
	}

	constructor(
		private amplifyService: AmplifyService,
		private offlineAuthService: OfflineAuthService,
		private branding: BrandingService,
		private router: Router) {

		this._api = amplifyService.api();
		this._subscription = this.amplifyService.authStateChange$.subscribe(this.authSubscription);

		const auth = this.amplifyService.auth();

		auth.currentAuthenticatedUser().catch( err => {
			console.log(err);
		});
	}

	public get usersHomePage(): string {
		if (!this._user) {
			return "/";
		}

		switch (this._user.accountType) {
			case UserType.Admin: return this.isClientManager() ? '/admin/client-manager/dashboard' : '/admin/dashboard';
			case UserType.User: return '/user/dashboard';
			default: return '/client/dashboard';
		}
	}

	private isClientManager(): boolean {
		if (User.isAdmin(this._user)) {
			const permission = Permission("Client Manager", "Is Client Manager");
			return this._user.permissions?.includes( Permissions.getPermissionId(permission) );
		}
		return false;
	}

	private async loadUser(cognitoUser: any, redirectPath?: string | string[]): Promise<void> {

		DialogService.show(`Loading your account${StringUtils.ellipsis}`);

		if (this.offlineAuthService.isOffline) {
			const auth = this.amplifyService.auth();
			const authInfo = await auth.currentUserInfo();
			this.offlineAuthService.setIdentity(authInfo.id);
		}

		try {
			this._user = await this.fetchUser();
			this.maintenanceMode = this._user.maintenance;

			this.branding.setup(this._user.theme, this._user.planManagerName);

			const defaultPath = [ this.usersHomePage ];
			redirectPath = redirectPath ?? defaultPath;
			redirectPath = Array.isArray(redirectPath) ? redirectPath : [ redirectPath ];

			DialogService.hide();
			this._signedIn = true;
			this.router.navigate(redirectPath);
			this.userLoaded$.next(true);

		}
		catch (err) {
			DialogService.hide();
			this._signedIn = false;
			this._user = null;
			console.log(err);
		}
	}

	/**
	* Fetch the user from the remote server.
	* Implemented here instead of using "user.service.ts" to avoid a circular dependancy problem.
	* When fetching the currently logged in user, the server response also contains the permissions list
	* which we use to initialise the "Permissions" helper class.
	*
	* @return {Promise} A promise that resolves to the currently logged in user.
	*/
	private async fetchUser(): Promise<User> {
		try {

			let requestOptions: any = {};
			requestOptions.headers = requestOptions.headers || {};

			if (this.offlineAuthService.isOffline) {
				requestOptions.headers['x-identityId'] = this.offlineAuthService.getIdentity();
			}

			const response = await this._api.get(API.toString(API.system), "user", requestOptions);
			Permissions.init(response.permissions);
			return User.parse(response.user);
		}
		catch (e) {
			console.log(e);
			return Promise.reject(e);
		}
	}

	/**
	* Auth state subscription handler.
	* - Forces users to the "change password" page if Cognito requires it.
	* - Loads the user's data if they are logged in.
	*/
	private authSubscription = async authState => {

		this._currentAuthState = authState.state;

		switch (this._currentAuthState) {
			case "requireNewPassword":

				DialogService.hide();
				this.router.navigate(['/password/change']);
				return;

			case "signedIn":

				this._awsAuthUser = authState.user;
				await this.loadUser(authState.user, SessionStorageService.get("path", true));
				return;

			default:

				this.userLoaded$.next(false);
				this._signedIn = false;
				this._user = null;
		}
	}

	public login(username: string, password: string): Promise<void> {

		username = StringUtils.trim(username ?? "").toLowerCase();
		password = StringUtils.trim(password);

		DialogService.show(`Logging in${StringUtils.ellipsis}`);
		return this.amplifyService.auth().signIn(username, password).catch( err => {

			switch (err.code) {

				case "PasswordResetRequiredException":
					// If a user's password has been reset by an administrator, when they next try to log in
					// they should be redirected to the "Password Reset" page.
					this.router.navigate(['/password/reset']);
					break;

				default:
					// Hide other error messages from the end user, and present a generic "Unable to log in"
					// const msg = err.message || AuthService.defaultLoginFailureMessage;
					const msg = AuthService.defaultLoginFailureMessage;
					NotificationService.error("Unable to log in", msg);
			}

			return Promise.reject();

		}).finally( () => {
			DialogService.hide();
		});
	}

	public forgotPassword(username: string): Promise<void> {
		return this.amplifyService.auth().forgotPassword(username).then();
	}

	public forgotPasswordSubmit(username: string, resetCode: string, newPassword: string): Promise<void> {
		return this.amplifyService.auth().forgotPasswordSubmit(username, resetCode, newPassword).then();
	}

	public changePassword(oldPwd: string, newPwd: string): Promise<void> {
		const auth = this.amplifyService.auth();

		// Handle the (rare) cases when a user has been created in the console.
		// These do not require an "old" password to be sent
		if (this.passwordChangeRequired) {
			return auth.completeNewPassword(this._awsAuthUser, newPwd, null).then();
		}

		return auth.changePassword(this._awsAuthUser, oldPwd, newPwd).catch( e => {
			if (e.name === "NotAuthorizedException") {
				return Promise.reject( new ENotAuthorised() );
			}

			return Promise.reject(e);
		});
	}

	public maintenanceMode: boolean = false;
}
