<maintenance-banner></maintenance-banner>
<div id="gtd">
	<app-header></app-header>
	<section class="section" [class.noscroll]="dialogVisible">
		<router-outlet></router-outlet>
		<notifications></notifications>
	</section>
	<modal></modal>
</div>
<footer id="footer" class="container has-text-right">
	&copy; Copyright {{ date | date : 'yyyy' }}. Powered by the GTD Platform ( Build: 2024.03.01-1).
</footer>
