/**
 * Enum class used to determine which set of features are used in the app.
 * Can be implicitly used as a string.
 * Typically used by {@link AdminPlanManagerOptions}
 */
export enum ProdaApiEnabled {
  /** Proda api functions are disabled and the old functions are enabled */ none = 'none',
  /** Proda api functions are disabled and the old functions are enabled */ both = 'both',
  /** Proda api functions are disabled and the old functions are enabled */ all = 'all'
}

/**
 * Converts any string to an acceptable {@link ProdaApiEnabled} value
 * @param from the thing that is being converted from
 */
export function prodaApiEnabled(from: string): ProdaApiEnabled {
  switch (from) {
    case ProdaApiEnabled.both: return ProdaApiEnabled.both;
    case ProdaApiEnabled.all: return ProdaApiEnabled.all;
    default: return ProdaApiEnabled.none;
  }
}
