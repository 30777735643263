import { Type, Compiler, Injector, Component, OnInit, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, RouterEvent, NavigationStart } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Subject, Subscription } from 'rxjs';
import { User } from "@classes/user";
import { SessionStorageService } from '@services/storage.service';
import { ThemeLoaderService } from "@services/themeLoader.service";
import { Branding } from "@classes/theme";

@Component({
	"selector": "app-header",
	"templateUrl": "./app-header.component.html",
	"styleUrls": ["./app-header.component.scss"]
})
export class AppHeaderComponent implements OnInit, OnDestroy {

  @ViewChild('menu', { "read": ViewContainerRef, "static": true })
  private menuOutlet: ViewContainerRef | undefined;

	private _menuActive: boolean = false; // ⟵ Flag indicating state of the mobile menu
  private _subscription: Subscription;

	private readonly defaultLogo = "assets/marauders.png";
	private menuState: Subject<boolean> = new Subject<boolean>();

	private _branding: Branding;
	private _menuLoaded: boolean = false;

	private async themeLoaded(branding: Branding) {
		this._branding = branding;
	}

	constructor(
		private authService: AuthService,
		private router: Router,
		private compiler: Compiler,
		private injector: Injector,
		private themeLoader: ThemeLoaderService) {

		// Close the mobile menu after a navigation event
		this.router.events.subscribe( e => {
			if (e instanceof NavigationStart) {
				this._menuActive = false;
				this.menuState.next(this._menuActive);
			}
		});

	}

	get isLoggedIn(): boolean {
		return this.authService.isLoggedIn;
	}

	get isAdmin(): boolean {
		return this.authService.isAdmin;
	}

	get isUser(): boolean {
		return this.authService.isUser;
	}

	get isClient(): boolean {
		return this.authService.isClient;
	}

	get logoUrl(): string {
		const user = this.authService.currentUser;
		if (!user) {
			return this._branding?.theme?.logo || undefined;
		}

		if (User.isAdmin(user)) {
			return user.planManagerData?.logo || this.defaultLogo;
		}

		return user.theme?.logo || this._branding?.theme?.logo || this.defaultLogo;
	}

	ngOnInit() {
		this._subscription = this.authService.userLoaded$.subscribe( () => {
			this.menuOutlet?.clear();

			if (!this.isLoggedIn) {
				this.themeLoader.loadThemeFromDomain();
			}
			else if (this.isAdmin) {
				this.loadAdminMenu();
			}
			else if (this.isUser) {
				this.loadUserMenu();
			}
			else if (this.isClient) {
				this.loadClientMenu();
			}
		});

		this.themeLoader.themeLoaded$.subscribe( this.themeLoaded.bind(this) );
	}

	ngOnDestroy() {
		this._subscription.unsubscribe();
	}

	private loadAdminMenu() {
		import("@modules/adminMenu/adminMenu.module")
			.then( m => m.AdminMenuModule )
			.then( module => this.compiler.compileModuleAsync(module) )
			.then( factory => {
				const moduleRef = factory.create(this.injector);
				const componentFactory = moduleRef.instance.resolveMenuComponent();
				this.menuOutlet.clear();
				const { instance } = this.menuOutlet.createComponent(componentFactory);
				instance.menuState = this.menuState;
			});
	}

	private loadUserMenu() {
		import("@modules/contactsMenu/contactsMenu.module")
			.then( m => m.ContactsMenuModule )
			.then( module => this.compiler.compileModuleAsync(module) )
			.then( factory => {
				const moduleRef = factory.create(this.injector);
				const componentFactory = moduleRef.instance.resolveMenuComponent();
				this.menuOutlet.clear();
				const { instance } = this.menuOutlet.createComponent(componentFactory);
				instance.menuState = this.menuState;
			});
	}

	private loadClientMenu() {
		import("@modules/clientMenu/clientMenu.module")
			.then( m => m.ClientMenuModule )
			.then( module => this.compiler.compileModuleAsync(module) )
			.then( factory => {
				const moduleRef = factory.create(this.injector);
				const componentFactory = moduleRef.instance.resolveMenuComponent();
				this.menuOutlet.clear();
				const { instance } = this.menuOutlet.createComponent(componentFactory);
				instance.menuState = this.menuState;
			});
	}


	public toggleMenu() {
		this._menuActive = !this._menuActive;
		this.menuState.next(this._menuActive);
	}

	public get menuActive(): boolean {
		return this._menuActive;
	}
}
