<nav class="navbar is-fixed-top">
	<div class="container">
		<div class="navbar-brand">
			<a class="navbar-item" routerLink="">
				<img *ngIf="logoUrl !== undefined" [src]="logoUrl" alt="Logo" />
			</a>
			<span [class.is-active]="menuActive" class="navbar-burger burger" data-target="navbarMenu" (click)="toggleMenu()">
				<span></span>
				<span></span>
				<span></span>
			</span>
		</div>

		<ng-container #menu></ng-container>


		<div id="navbarMenu" class="navbar-menu" *ngIf="!isLoggedIn">

			<div class="navbar-start">
					<!--ng-container *ngIf="!isAdmin">
						<a class="navbar-item">My Account</a>
						<hr class="navbar-divider">
						<a class="navbar-item" (click)="logout()">Log Out</a>
					</ng-container>

				</ng-container-->
			</div>

			<div class="navbar-end">
				<a *ngIf="!isLoggedIn" class="navbar-item" routerLink="login">Login</a>
			</div>

		</div>

	</div>
</nav>
