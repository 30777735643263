import { Region } from "@classes/regions";
import { EnumMetadata } from "@classes/enumUtils";
import { DateTime } from "luxon";
import { DateUtils, NumberUtils } from "@classes/utils";
import { PhoneNumberUtils } from "@classes/utils";
import { Address } from "@classes/address";
import { AccountStatus } from "@classes/accountStatus";
import { Entity } from "@classes/entities";
import { Gender } from "@classes/gender";
import { AdminPlanManager } from "@classes/planManager";
import { Theme, ThemeElement } from "@classes/theme";
import { LatestPlan } from "@classes/latestPlan";
import { BankVerifiedStatus } from "@classes/bankVerifiedStatus";
import { ClientType } from "@classes/clientType";

export enum UserType {
	Admin, Participant, User
}

export namespace UserType {
	const enumData: EnumMetadata<UserType>[] = [
		[UserType.Admin, "Administrator", "admin"],
		[UserType.Participant, "Client", "participant"],
		[UserType.User, "Contact", "user"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): UserType {
		return values.get(value);
	}

	export function toString(value: UserType): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: UserType): string {
		return pgEnums.get(value);
	}

	export function allValues(): UserType[] {
		return enumData.map( item => item[0] );
	}
}

export enum CredentialsStatus {
	pending, in_progress, error
}

export namespace CredentialsStatus {
	const enumData: EnumMetadata<CredentialsStatus>[] = [
		[CredentialsStatus.pending, "Queued", "pending"],
		[CredentialsStatus.in_progress, "In Progress", "in_progress"],
		[CredentialsStatus.error, "Error", "error"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): CredentialsStatus {
		return values.get(value);
	}

	export function toString(value: CredentialsStatus): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: CredentialsStatus): string {
		return pgEnums.get(value);
	}

	export function allValues(): CredentialsStatus[] {
		return enumData.map( item => item[0] );
	}
}

interface CredentialsRequest {
	date: Date;
	status: CredentialsStatus;
}

namespace CredentialsRequest {
	export function parse(src: any): CredentialsRequest {
		if (!src) {
			return undefined;
		}

		return {
			"date": DateUtils.parse(src.date, true),
			"status": CredentialsStatus.parse(src.status)
		};
	}

	export function toJSON(src: CredentialsRequest): any {
		if (!src) {
			return undefined;
		}

		return {
			"date": DateUtils.toISOString(src.date),
			"status": CredentialsStatus.toPostgresEnum(src.status)
		};
	}

	export function clone(src: CredentialsRequest): CredentialsRequest {
		if (!src) {
			return undefined;
		}

		return {
			"date": DateUtils.clone(src.date),
			"status": src.status
		};
	}
}

interface TranslationService {
	translationServiceRequired: string;
	translationServiceRequiredDetail: string;
}

namespace TranslationService {
	export function parse(src: any): TranslationService {
		if (!src) {
			return undefined;
		}

		return {
			"translationServiceRequired": src.translationServiceRequired,
			"translationServiceRequiredDetail": src.translationServiceRequiredDetail
		};
	}

	export function toJSON(src: TranslationService): any {
		if (!src) {
			return undefined;
		}

		return {
			"translationServiceRequired": src.translationServiceRequired,
			"translationServiceRequiredDetail": src.translationServiceRequiredDetail
		};
	}

	export function clone(src: TranslationService): TranslationService {
		if (!src) {
			return undefined;
		}

		return {
			"translationServiceRequired": src.translationServiceRequired,
			"translationServiceRequiredDetail": src.translationServiceRequiredDetail
		};
	}
}

export interface EmailPrefs {
	monthlyStatement: boolean;
	invoiceApproval: boolean;
}

export namespace EmailPrefs {
	export function blank(): EmailPrefs {
		return {
			"monthlyStatement": false,
			"invoiceApproval": false
		};
	}

	export function equals(a: EmailPrefs, b:  EmailPrefs): boolean {
		if (!(!!a && !!b)) {
			return false;
		}

		return ['monthlyStatement', 'invoiceApproval'].every( field => a[field] === b[field]);
	}

	export function parse(src: any): EmailPrefs {
		if (!src) {
			return undefined;
		}

		return {
			"monthlyStatement": src.monthlyStatement,
			"invoiceApproval": src.invoiceApproval
		};
	}

	export function toJSON(src: EmailPrefs): any {
		if (!src) {
			return undefined;
		}

		return {
			"monthlyStatement": src.monthlyStatement,
			"invoiceApproval": src.invoiceApproval
		};
	}

	export function clone(src: EmailPrefs): EmailPrefs {
		if (!src) {
			return undefined;
		}

		return {
			"monthlyStatement": src.monthlyStatement,
			"invoiceApproval": src.invoiceApproval
		};
	}
}


export interface BasicUser extends Entity {
	name: string;
	firstName: string;
	lastName: string;
	givenName?: string;
	accountType: UserType;
	email: string;
	uniqueEmail: boolean;
	phone: string;
	mobile: string;
	contactMethod: string;
	hasLogin: boolean;
	gender?: Gender;
	translationServiceRequired: string;
	translationServiceRequiredDetail: string;
	credentialsRequested: CredentialsRequest;
	lastAccess?: Date;
	planManager: string;
	planManagerName: string;
	theme?: Theme;
	options?: any;
	maintenance?: boolean; // ⟵ Returned by the server when the user requests their own account.
	bankAccountStatus?: BankVerifiedStatus;
	personalEmail: string;
	personalPhone: string;
	personalMobile: string;
	
}

export interface Contact extends BasicUser {
	address?: Address;
	postalAddress?: Address;
	hasBankDetails: boolean;
	linkedClients: string[];
	staffReferrerId: string;
}

export interface Client extends BasicUser {
	address?: Address;
	postalAddress?: Address;
	region: Region;
	ndisNumber: string;
	dob: Date;
	status: AccountStatus;
	emailPrefs: EmailPrefs;
	latestPlan?: LatestPlan;
	hasBankDetails: boolean;
	clientManagerId: string;
	forceToInvestigation: string;
	referralSource: string;
	staffReferrerId: string;
	pugStatus: string;
	pugsBillingSchedule: boolean;
	pugsBillingScheduleInvoice: boolean;
	pugsBillingScheduleInvoicePaid: boolean;
	clientType?: ClientType;
	invoicesPaidLastMonth: number;
	isPug: boolean;
}

export interface Admin extends BasicUser {
	permissions: string[];
	planManagerData: AdminPlanManager;
	readonly marauder: boolean;
}

// export interface Marauder extends Admin {
// 	impersonatePm: LogoNamedEntity;
// }

export type User = BasicUser | Contact | Client | Admin;

export namespace Admin {
	export function hasPermission(user: Admin, permissionId: string): boolean {
		return user.permissions?.includes(permissionId);
	}
}

export namespace User {

	export function parse(src: any): User {
		if (!src) {
			return undefined;
		}

		let result: User = {
			"id": src.id,
			"firstName": src.firstName,
			"lastName": src.lastName,
			"name": `${src.lastName}, ${src.firstName}`,
			"givenName": src.givenName,
			"accountType": UserType.parse(src.accountType),
			"email": src.email,
			"phone": src.phone,
			"mobile": src.mobile,
			"contactMethod": src.contactMethod,
			"uniqueEmail": src.uniqueEmail,
			"hasLogin": !!src.hasLogin,
			"gender": Gender.parse(src.gender),
			"translationServiceRequired": src.translationServiceRequired,
			"translationServiceRequiredDetail": src.translationServiceRequiredDetail,
			"credentialsRequested": CredentialsRequest.parse(src.credentialsRequested),
			"lastAccess": DateTime.fromISO(src.lastAccess),
			"planManager": src.planManager,
			"planManagerName": src.planManagerName,
			"marauder": src.marauder,
			"theme": Theme.parse(src.theme),
			"maintenance": src.maintenance,
			"bankAccountStatus": BankVerifiedStatus.parse(src.bankAccountStatus),
			"personalEmail": src.personalEmail,
			"personalPhone": src.personalPhone,
			"personalMobile": src.personalMobile
		};

		if (User.isContact(result)) {
			result.address = Address.parse(src.address);
			result.postalAddress = Address.parse(src.postalAddress);
			result.hasBankDetails = src.hasBankDetails;
			result.linkedClients = src.linkedClients;
			result.staffReferrerId = src.staffReferrerId;
		}

		if (User.isClient(result)) {
			result.address = Address.parse(src.address);
			result.postalAddress = Address.parse(src.postalAddress);
			result.ndisNumber = src.ndisNumber;
			result.dob = DateUtils.parse(src.dob);
			result.region = Region.parse(src.region);
			result.status = AccountStatus.parse(src.status);
			result.emailPrefs = EmailPrefs.parse(src.emailPrefs);
			result.latestPlan = LatestPlan.parse(src.latestPlan);
			result.hasBankDetails = src.hasBankDetails;
			result.clientManagerId = src.clientManagerId;
			result.forceToInvestigation = src.forceToInvestigation;
			result.referralSource = src.referralSource;
			result.staffReferrerId = src.staffReferrerId;
			result.pugStatus = src.pugStatus;
			result.pugsBillingSchedule = src.pugsBillingSchedule;
			result.pugsBillingScheduleInvoice = src.pugsBillingScheduleInvoice;
			result.pugsBillingScheduleInvoicePaid = src.pugsBillingScheduleInvoicePaid;
			result.clientType = ClientType.parse(src.clientType);
			result.invoicesPaidLastMonth = src.invoicesPaidLastMonth;
			result.isPug = src.isPug;
		}

		if (User.isAdmin(result)) {
			result.permissions = src.permissions;
			result.planManagerData = AdminPlanManager.parse(src.planManagerData);
		}

		return result;
	}

	export function isContact(user: User): user is Contact {
		return user?.accountType === UserType.User;
	}

	export function isClient(user: User): user is Client {
		return user?.accountType === UserType.Participant;
	}

	export function isAdmin(user: User): user is Admin {
		return user?.accountType === UserType.Admin;
	}

	export function clone(user: User): User {
		let result: User = {...user};
		result.credentialsRequested = CredentialsRequest.clone(user.credentialsRequested);

		if (User.isClient(user)) {
			(<Client>result).address = Address.clone(user.address);
			(<Client>result).postalAddress = Address.clone(user.postalAddress);
			(<Client>result).dob = DateUtils.clone(user.dob);
			(<Client>result).emailPrefs = EmailPrefs.clone(user.emailPrefs);
			(<Client>result).latestPlan = LatestPlan.clone(user.latestPlan);
			(<Client>result).clientManagerId = user.clientManagerId;
			(<Client>result).referralSource = user.referralSource;
			(<Client>result).staffReferrerId = user.staffReferrerId;
			(<Client>result).pugStatus = user.pugStatus;
			(<Client>result).pugsBillingSchedule = user.pugsBillingSchedule;
			(<Client>result).pugsBillingScheduleInvoice = user.pugsBillingScheduleInvoice;
			(<Client>result).pugsBillingScheduleInvoicePaid = user.pugsBillingScheduleInvoicePaid;
			(<Client>result).isPug = user.isPug;
		}

		if (User.isContact(user)) {
			(<Contact>result).address = Address.clone(user.address);
			(<Contact>result).postalAddress = Address.clone(user.postalAddress);
			(<Contact>result).linkedClients = user.linkedClients;
			(<Client>result).staffReferrerId = user.staffReferrerId;
		}

		if (User.isAdmin(user)) {
			(<Admin>result).permissions = [...(user.permissions || [])];
			(<Admin>result).planManagerData = AdminPlanManager.clone(user.planManagerData);
		}
		return result;
	}

	export function toJSON(user: Partial<User>): any {
		let result = {};
		for (let field in user) {

			switch (field) {
				case "impersonatePm":
					break;
				case "planManagerData":
					result[field] = AdminPlanManager.toJSON(user[field]);
					break;
				case "phone":
				case "mobile":
				case "personalPhone":
				case "personalMobile":
					result[field] = PhoneNumberUtils.e164(user[field]) || null;
					break;
				case "credentialsRequested":
					result[field] = CredentialsRequest.toJSON(user[field]);
					break;
				case "accountType":
					result[field] = UserType.toPostgresEnum(user[field]);
					break;
				case "region":
					result[field] = Region.toPostgresEnum(user[field]);
					break;
				case "gender":
					result[field] = Gender.toPostgresEnum(user[field]);
					break;
				case "dob":
					result[field] = DateTime.fromJSDate(user[field]).toISODate();
					break;
				case "lastAccess":
					result[field] = DateTime.fromJSDate(user[field]).toISO();
					break;
				case "status":
					result[field] = AccountStatus.toPostgresEnum( NumberUtils.parse(user[field]) );
					break;
				case "clientType":
					result[field] = ClientType.toPostgresEnum( NumberUtils.parse(user[field]) );
					break;
				case "emailPrefs":
					result[field] = EmailPrefs.toJSON(user[field]);
					break;
				case "latestPlan":
					result[field] = LatestPlan.toJSON(user[field]);
					break;
				case "address":
				case "postalAddress":
					result[field] = Address.toJSON(user[field]);
					break;
				default:
					result[field] = user[field];
			}

		}
		
		return result;
	}

	export function isMarauderAdmin(user: User): boolean {
		return User.isAdmin(user) && user.marauder;
	}
}
