/* tslint:disable:indent no-namespace max-line-length no-trailing-whitespace */
import { EnumMetadata } from '@classes/enumUtils';
import { NumberUtils, DateUtils } from '@classes/utils';
import { AttachedFile } from '@classes/files';
import { SupportItem } from '@classes/supports';
import { LineItemStatus } from '@classes/lineItemStatus';
import { TimeBlog } from '@classes/timeblog';
import { TriageLevel } from '@classes/triageLevel';
import { NamedEntity } from './namedEntity';

export enum LastInvoiceList {
	triaged, investigation
}

export { LineItemStatus } from '@classes/lineItemStatus';

export enum InvoiceStatus {
	triaged, draft, locked, submitted, reconciled, paid, investigation
}

/**
 * {@link https://help.sap.com/docs/SAP_S4HANA_ON-PREMISE/4e132486a4aa48479fa1f73c152633d6/af02951c83b84760bebb395f3c2bb72b.html?version=2020.000&locale=en-US}
 *
 *
 * The GST Code applied to the given item
 */
export enum GSTCode {
	/** Purchase - (Non-capital Expt) GST 10% */
	P1,
	/** Purchase - (Non-capital Expt) GST Exempt & Free */
	P2,
	/** Purchase - (Cap/non-cap.) Out of Scope acq */
	P5
}

/**
 * Enum list of database recognised UUIDs with their readable names.
 */
export enum InvoiceCategoryValues {
	onHoldClientRequest = 'ccf06612-a8f5-350b-9f6d-7d30b644ceaf',
	prodaReset = 'd3889aed-0281-460b-8b3a-fd3673ecab0d'
}

export namespace GSTCode {
	export const gstFraction = 0.1;
}

export enum CommunicationLogType {
	payment_auth
}

interface AutoTriageDetails {
	readonly clientId: string;
	readonly clientName: string;
	readonly invoiceNumber: string;
	readonly providerId: string;
	readonly providerName: string;
}

interface MatchingProvider {
	readonly providerId: string;
	readonly providerName: string;
}

interface RevelioTriageDetails extends AutoTriageDetails {
	readonly invoiceDate: Date;
	readonly invoiceTotal: number;
	readonly invoiceBSB: string;
	readonly providerBSB: string;
	readonly invoiceAccountNumber: string;
	readonly providerAccountNumber: string;
	readonly matchingProvidersByBank: MatchingProvider[];
	readonly dateGenerated: Date;
}

export interface InvoiceTriageDetails {
	readonly triageItemId: string;
	readonly triagedBy: NamedEntity;
	readonly triagedAt: Date;
	readonly dataEntryInstructions: string;
	readonly autoTriageResult: AutoTriageDetails;
	readonly revelioTriageResult: RevelioTriageDetails
}

export interface InvoiceAllocated {
	readonly allocatedBy: NamedEntity;
	readonly allocatedAt: Date;
}

export interface AssignedToInvestigator {
	readonly investigationAssignedTo: NamedEntity;
	readonly investigationAssignedAt: Date;
}

export interface InvoicePayment {
	readonly aba: NamedEntity;
	readonly paidDate: Date;
	readonly ndisPaid: number;
	readonly abaLineItemId: string;
	readonly payeeId: string;
	readonly hasRemittance: boolean;
}

export interface InvoiceSubmission {
	readonly id: string;
	readonly submissionDate: Date;
	readonly extract: number;
	readonly responseId: string;
}

export interface CommunicationLog {
	//readonly triggeredBy: NamedEntity;
	readonly logType: CommunicationLogType;
	readonly emailSentAt: Date;
	//readonly sentTo: string;
}

export interface AvailableInvoice {
	id: string;
	clientId: string;
	clientName: string;
	providerId: string;
	providerName: string;
	invoiceDate: Date;
	invoiceNumber: string;
	status: InvoiceStatus;
	total: number;
	ndisPaid: number;
	createdDate?: Date;
	readyToPay: boolean;
	validBankAccount: boolean;
	reimbursement: boolean;
	reimbursementRecipient: string;
	reimbursementRecipientName: string;
	clientApproved: boolean;
	canPayAfter: Date;
	planManager: string;
	planManagerName: string;
}

export interface TriagedInvoice {
	id: string;
	clientId: string;
	clientName: string;
	providerId: string;
	providerName: string;
	invoiceDate: Date;
	invoiceNumber: string;
	dataEntryInstructions: string;
	triageAttachmentAdded: Date;
	triageAttachmentId: string;
	triageLevel?: TriageLevel;
	attachments: AttachedFile[];
}

export interface Invoice {
	id: string;
	clientId: string;
	clientName: string;
	providerId: string;
	providerName: string;
	adjustment: number;
	comment: string;
	gst?: number;
	invoiceDate: Date;
	invoiceNumber: string;
	status: InvoiceStatus;
	deleted: boolean;
	total: number;
	clientNDISNumber?: string;
	providerAbn?: string;
	createdDate?: Date;
	createdBy?: string;
	createdByName?: string;
	reimbursement: boolean;
	reimbursementRecipient: string;
	reimbursementRecipientName: string;
	numLines?: number;
	clientApproved: boolean;
	canPayAfter: Date;
	nextActionDate: Date;
	planManager: string;
	planManagerName: string;
	attachments: AttachedFile[];
	lineItems: InvoiceLineItem[];
	timeBlogs?: TimeBlog[];
	submissions: InvoiceSubmission[];
	category: string; // Investigation category
	triage: InvoiceTriageDetails[];
	allocated: InvoiceAllocated[];
	assignedToInvestigator: AssignedToInvestigator[];
	payment: InvoicePayment[];
	communicationLog: CommunicationLog[];
	readonly lastTouched: Date;
	readonly firstArrived: Date;
	readonly currentInvestigator: string;
	readonly serverDateTimeLocal: Date;
	readonly serverDateTimeUTC: Date;
}

export interface InvoiceLineItem {
	id: string;
	invoiceId: string;
	supportItemId: string;
	supportItem?: SupportItem; // Not populated from server, but can be attached locally
	date: Date;
	quantity: number;
	rate: number;
	total: number;
	discrepancy: number;
	deleted: boolean;
	gstCode: GSTCode;
	claimReference: number; // TODO why is this a number????
	readonly submissionItemId?: string;
	readonly submissionId?: string;
	readonly responseId?: string;
	readonly extract?: number;
	status: LineItemStatus;
}

export namespace InvoiceStatus {
	const enumData: EnumMetadata<InvoiceStatus>[] = [
		[InvoiceStatus.triaged, 'Triaged', 'triaged'],
		[InvoiceStatus.draft, 'Draft', 'draft'],
		[InvoiceStatus.locked, 'Locked', 'locked'],
		[InvoiceStatus.submitted, 'Submitted', 'submitted'],
		[InvoiceStatus.reconciled, 'Reconciled', 'reconciled'],
		[InvoiceStatus.paid, 'Paid', 'paid'],
		[InvoiceStatus.investigation, 'Investigation', 'investigation']
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): InvoiceStatus {
		return values.get(value);
	}

	export function toString(value: InvoiceStatus): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: InvoiceStatus): string {
		return pgEnums.get(value);
	}

	export function allValues(): InvoiceStatus[] {
		return enumData.map( item => item[0] );
	}

	export function map(): any {
		return enumData.reduce( (acc, cur) => {
			acc[cur[2]] = cur[0];
			return acc;
		}, {});
	}

	export const editableStates: InvoiceStatus[] = [InvoiceStatus.draft, InvoiceStatus.investigation];

	export const abaStates: InvoiceStatus[] = [InvoiceStatus.submitted, InvoiceStatus.reconciled];
}

export namespace GSTCode {
	const enumData: EnumMetadata<GSTCode>[] = [
		[GSTCode.P1, 'P1 - GST Included', 'P1'],
		[GSTCode.P2, 'P2 - GST Free', 'P2'],
		[GSTCode.P5, 'P5 - GST Out of scope', 'P5']
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): GSTCode {
		return values.get(value);
	}

	export function toString(value: GSTCode): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: GSTCode): string {
		return pgEnums.get(value);
	}

	export function allValues(): GSTCode[] {
		return enumData.map( item => item[0] );
	}
}

export namespace CommunicationLogType {
	const enumData: EnumMetadata<CommunicationLogType>[] = [
		[CommunicationLogType.payment_auth, "Pay Auth", "payment_auth"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): CommunicationLogType {
		return values.get(value);
	}

	export function toString(value: CommunicationLogType): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: CommunicationLogType): string {
		return pgEnums.get(value);
	}

	export function allValues(): CommunicationLogType[] {
		return enumData.map( item => item[0] );
	}
}

export namespace InvoiceLineItem {
	export function parse(src: any): InvoiceLineItem {
		if (!src) {
			// debugger;
			return undefined;
		}

		return {
			id: src.id,
			invoiceId: src.invoiceId,
			supportItemId: src.supportItemId,
			date: DateUtils.parse(src.date),
			quantity: NumberUtils.parse(src.quantity),
			rate: NumberUtils.parse(src.rate),
			total: NumberUtils.parse(src.total),
			discrepancy: NumberUtils.parse(src.discrepancy),
			deleted: src.deleted,
			gstCode: GSTCode.parse(src.gstCode),
			claimReference: src.claimReference,
			submissionItemId: src.submissionItemId,
			responseId: src.responseId,
			submissionId: src.submissionId,
			extract: src.extract,
			status: LineItemStatus.parse(src.status)
		};
	}

	export function toJSON(src: InvoiceLineItem): any {
		return {
			id: src.id,
			invoiceId: src.invoiceId,
			supportItemId: src.supportItemId,
			date: DateUtils.toString(src.date),
			quantity: src.quantity,
			rate: src.rate,
			total: src.total,
			discrepancy: src.discrepancy,
			deleted: src.deleted,
			gstCode: GSTCode.toPostgresEnum(src.gstCode),
			claimReference: src.claimReference,
			submissionItemId: src.submissionItemId,
			responseId: src.responseId,
			submissionId: src.submissionId,
			extract: src.extract,
			status: LineItemStatus.toPostgresEnum(src.status)
		};
	}

	export function clone(src: InvoiceLineItem): InvoiceLineItem {
		return {
			id: src.id,
			invoiceId: src.invoiceId,
			supportItem: src.supportItem,
			supportItemId: src.supportItemId,
			date: DateUtils.clone(src.date),
			quantity: src.quantity,
			rate: src.rate,
			total: src.total,
			discrepancy: src.discrepancy,
			deleted: src.deleted,
			gstCode: src.gstCode,
			claimReference: src.claimReference,
			submissionItemId: src.submissionItemId,
			responseId: src.responseId,
			submissionId: src.submissionId,
			extract: src.extract,
			status: src.status
		};
	}

	export function blank(): InvoiceLineItem {
		return {
			id: undefined,
			invoiceId: undefined,
			supportItemId: undefined,
			date: undefined,
			quantity: undefined,
			rate: undefined,
			total: undefined,
			discrepancy: undefined,
			deleted: undefined,
			gstCode: GSTCode.P2,
			claimReference: undefined,
			submissionId: undefined,
			responseId: undefined,
			extract: undefined,
			status: LineItemStatus.draft
		};
	}

	export function equals(item1: InvoiceLineItem, item2: InvoiceLineItem): boolean {

		if (!item1 && !item2) {
			return true;
		}
		else if ((!!item1 || !!item2) && !(!!item1 && !!item2)) {
			return false;
		}

		const scalarProps = [
			'id',
			'invoiceId',
			'supportItemId',
			'quantity',
			'rate',
			'total',
			'discrepancy',
			'deleted',
			'gstCode',
			'claimReference',
			'status',
			'submissionItemId',
			'submissionId',
			'extract'
		];

		const result = scalarProps.every( prop => item1[prop] === item2[prop]);
		return result && item1?.date?.valueOf() === item2?.date?.valueOf();
	}
}

namespace InvoiceSubmission {
	export function parse(src: any): InvoiceSubmission {
		if (!src) {
			return undefined;
		}

		return {
			id: src.id,
			submissionDate: DateUtils.parse(src.submissionDate, true),
			extract: Number(src.extract),
			responseId: src.responseId
		};
	}

	export function toJSON(src: InvoiceSubmission): any {
		if (!src) {
			return undefined;
		}

		return {
			id: src.id,
			submissionDate: DateUtils.toString(src.submissionDate),
			extract: src.extract,
			responseId: src.responseId
		};
	}

	export function clone(src: InvoiceSubmission): InvoiceSubmission {
		if (!src) {
			return undefined;
		}

		return {
			id: src.id,
			submissionDate: DateUtils.clone(src.submissionDate),
			extract: src.extract,
			responseId: src.responseId
		};
	}
}

namespace AutoTriageDetails {
	export function parse(src: any): AutoTriageDetails {
		if (!src) {
			return undefined;
		}

		return {
			"clientId": src.clientId,
			"clientName": src.clientName,
			"invoiceNumber": src.invoiceNumber,
			"providerId": src.providerId,
			"providerName": src.providerName
		};
	}

	export function toJSON(src: AutoTriageDetails): any {
		if (!src) {
			return undefined;
		}

		return {
			"clientId": src.clientId,
			"clientName": src.clientName,
			"invoiceNumber": src.invoiceNumber,
			"providerId": src.providerId,
			"providerName": src.providerName
		};
	}

	export function clone(src: AutoTriageDetails): AutoTriageDetails {
		if (!src) {
			return undefined;
		}

		return {
			"clientId": src.clientId,
			"clientName": src.clientName,
			"invoiceNumber": src.invoiceNumber,
			"providerId": src.providerId,
			"providerName": src.providerName
		};
	}

	export function blank(): AutoTriageDetails {
		return {
			"clientId": undefined,
			"clientName": undefined,
			"invoiceNumber": undefined,
			"providerId": undefined,
			"providerName": undefined
		};
	}
}

namespace RevelioTriageDetails {
	export function parse(src: any): RevelioTriageDetails {
		if (!src) {
			return undefined;
		}

		return {
			"clientId": src.clientId,
			"clientName": src.clientName,
			"invoiceDate": DateUtils.parse(src.invoiceDate, false),
			"invoiceNumber": src.invoiceNumber,
			"invoiceTotal": src.invoiceTotal,
			"providerId": src.providerId,
			"providerName": src.providerName,
			"invoiceBSB": src.invoiceBSB,
			"providerBSB": src.providerBSB,
			"invoiceAccountNumber": src.invoiceAccountNumber,
			"providerAccountNumber": src.providerAccountNumber,
			"matchingProvidersByBank": src.matchingProvidersByBank,
			"dateGenerated": DateUtils.parse(src.dateGenerated, false)
		};
	}

	export function toJSON(src: RevelioTriageDetails): any {
		if (!src) {
			return undefined;
		}

		return {
			"clientId": src.clientId,
			"clientName": src.clientName,
			"invoiceDate": DateUtils.toString(src.invoiceDate),
			"invoiceNumber": src.invoiceNumber,
			"invoiceTotal": src.invoiceTotal,
			"providerId": src.providerId,
			"providerName": src.providerName,
			"invoiceBSB": src.invoiceBSB,
			"providerBSB": src.providerBSB,
			"invoiceAccountNumber": src.invoiceAccountNumber,
			"providerAccountNumber": src.providerAccountNumber,
			"matchingProvidersByBank": src.matchingProvidersByBank,
			"dateGenerated": DateUtils.toString(src.dateGenerated)
		};
	}

	export function clone(src: RevelioTriageDetails): RevelioTriageDetails {
		if (!src) {
			return undefined;
		}

		return {
			"clientId": src.clientId,
			"clientName": src.clientName,
			"invoiceDate": DateUtils.clone(src.invoiceDate),
			"invoiceNumber": src.invoiceNumber,
			"invoiceTotal": src.invoiceTotal,
			"providerId": src.providerId,
			"providerName": src.providerName,
			"invoiceBSB": src.invoiceBSB,
			"providerBSB": src.providerBSB,
			"invoiceAccountNumber": src.invoiceAccountNumber,
			"providerAccountNumber": src.providerAccountNumber,
			"matchingProvidersByBank": src.matchingProvidersByBank,
			"dateGenerated": DateUtils.clone(src.dateGenerated)
		};
	}

	export function blank(): RevelioTriageDetails {
		return {
			"clientId": undefined,
			"clientName": undefined,
			"invoiceDate": undefined,
			"invoiceNumber": undefined,
			"invoiceTotal": undefined,
			"providerId": undefined,
			"providerName": undefined,
			"invoiceBSB": undefined,
			"providerBSB": undefined,
			"invoiceAccountNumber": undefined,
			"providerAccountNumber": undefined,
			"matchingProvidersByBank": undefined,
			"dateGenerated": undefined
		};
	}
}

namespace InvoiceTriageDetails {
	export function parse(src: any): InvoiceTriageDetails {
		if (!src) {
			return undefined;
		}

		return {
			"triageItemId": src.triageItemId,
			"triagedBy": NamedEntity.parse(src.triagedBy),
			"triagedAt": DateUtils.parse(src.triagedAt, true),
			"dataEntryInstructions": src.dataEntryInstructions,
			"autoTriageResult": AutoTriageDetails.parse(src.autoTriageResult),
			"revelioTriageResult": RevelioTriageDetails.parse(src.revelioTriageResult)
		};
	}

	export function toJSON(src: InvoiceTriageDetails): any {
		if (!src) {
			return undefined;
		}

		return {
			"triageItemId": src.triageItemId,
			"triagedBy": NamedEntity.toJSON(src.triagedBy),
			"triagedAt": DateUtils.toString(src.triagedAt),
			"dataEntryInstructions": src.dataEntryInstructions,
			"autoTriageResult": AutoTriageDetails.toJSON(src.autoTriageResult),
			"revelioTriageResult": RevelioTriageDetails.toJSON(src.revelioTriageResult)
		};
	}

	export function clone(src: InvoiceTriageDetails): InvoiceTriageDetails {
		if (!src) {
			return undefined;
		}

		return {
			"triageItemId": src.triageItemId,
			"triagedBy": NamedEntity.clone(src.triagedBy),
			"triagedAt": DateUtils.clone(src.triagedAt),
			"dataEntryInstructions": src.dataEntryInstructions,
			"autoTriageResult": AutoTriageDetails.clone(src.autoTriageResult),
			"revelioTriageResult": RevelioTriageDetails.clone(src.revelioTriageResult)
		};
	}

	export function blank(): InvoiceTriageDetails {
		return {
			"triageItemId": undefined,
			"triagedBy": NamedEntity.blank(),
			"triagedAt": undefined,
			"dataEntryInstructions": undefined,
			"autoTriageResult": AutoTriageDetails.blank(),
			"revelioTriageResult": RevelioTriageDetails.blank()
		};
	}
}

namespace InvoiceAllocated {
	export function parse(src: any): InvoiceAllocated {
		if (!src) {
			return undefined;
		}

		return {
			allocatedBy: NamedEntity.parse(src.allocatedBy),
			allocatedAt: DateUtils.parse(src.allocatedAt, true)
		};
	}

	export function toJSON(src: InvoiceAllocated): any {
		if (!src) {
			return undefined;
		}

		return {
			allocatedBy: NamedEntity.toJSON(src.allocatedBy),
			allocatedAt: DateUtils.toString(src.allocatedAt)
		};
	}

	export function clone(src: InvoiceAllocated): InvoiceAllocated {
		if (!src) {
			return undefined;
		}

		return {
			allocatedBy: NamedEntity.clone(src.allocatedBy),
			allocatedAt: DateUtils.clone(src.allocatedAt)
		};
	}

	export function blank(): InvoiceAllocated {
		return {
			allocatedBy: NamedEntity.blank(),
			allocatedAt: undefined
		};
	}
}

namespace AssignedToInvestigator {
	export function parse(src: any): AssignedToInvestigator {
		if (!src) {
			return undefined;
		}

		return {
			investigationAssignedTo: NamedEntity.parse(src.investigationAssignedTo),
			investigationAssignedAt: DateUtils.parse(src.investigationAssignedAt, true)
		};
	}

	export function toJSON(src: AssignedToInvestigator): any {
		if (!src) {
			return undefined;
		}

		return {
			investigationAssignedTo: NamedEntity.toJSON(src.investigationAssignedTo),
			investigationAssignedAt: DateUtils.toString(src.investigationAssignedAt)
		};
	}

	export function clone(src: AssignedToInvestigator): AssignedToInvestigator {
		if (!src) {
			return undefined;
		}

		return {
			investigationAssignedTo: NamedEntity.clone(src.investigationAssignedTo),
			investigationAssignedAt: DateUtils.clone(src.investigationAssignedAt)
		};
	}

	export function blank(): AssignedToInvestigator {
		return {
			investigationAssignedTo: NamedEntity.blank(),
			investigationAssignedAt: undefined
		};
	}
}

namespace InvoicePayment {
	export function parse(src: any): InvoicePayment {
		if (!src) {
			return undefined;
		}

		return {
			aba: NamedEntity.parse(src.aba),
			paidDate: DateUtils.parse(src.paidDate, true),
			ndisPaid: src.ndisPaid,
			abaLineItemId: src.abaLineItemId,
			payeeId: src.payeeId,
			hasRemittance: src.hasRemittance
		};
	}

	export function toJSON(src: InvoicePayment): any {
		if (!src) {
			return undefined;
		}

		return {
			aba: NamedEntity.toJSON(src.aba),
			paidDate: DateUtils.toString(src.paidDate),
			ndisPaid: src.ndisPaid,
			abaLineItemId: src.abaLineItemId,
			payeeId: src.payeeId,
			hasRemittance: src.hasRemittance
		};
	}

	export function clone(src: InvoicePayment): InvoicePayment {
		if (!src) {
			return undefined;
		}

		return {
			aba: NamedEntity.clone(src.aba),
			paidDate: DateUtils.clone(src.paidDate),
			ndisPaid: src.ndisPaid,
			abaLineItemId: src.abaLineItemId,
			payeeId: src.payeeId,
			hasRemittance: src.hasRemittance
		};
	}

	export function blank(): InvoicePayment {
		return {
			aba: NamedEntity.blank(),
			paidDate: undefined,
			ndisPaid: undefined,
			abaLineItemId: undefined,
			payeeId: undefined,
			hasRemittance: undefined
		};
	}
}

namespace CommunicationLog {
	export function parse(src: any): CommunicationLog {
		if (!src) {
			return undefined;
		}

		return {
			//"triggeredBy": NamedEntity.parse(src.triggeredBy),
			"emailSentAt": DateUtils.parse(src.emailSentAt, true),
			"logType": CommunicationLogType.parse(src.logType)//,
			//"sentTo": src.sentTo
		};
	}

	export function toJSON(src: CommunicationLog): any {
		if (!src) {
			return undefined;
		}

		return {
			//"triggeredBy": NamedEntity.toJSON(src.triggeredBy),
			"emailSentAt": DateUtils.toString(src.emailSentAt),
			"logType": CommunicationLogType.toPostgresEnum(src.logType)//,
			//"sentTo": src.sentTo
		};
	}

	export function clone(src: CommunicationLog): CommunicationLog {
		if (!src) {
			return undefined;
		}

		return {
			//"triggeredBy": NamedEntity.clone(src.triggeredBy),
			"emailSentAt": DateUtils.clone(src.emailSentAt),
			"logType": src.logType//,
			//"sentTo": src.sentTo
		};
	}

	export function blank(): CommunicationLog {
		return {
			//"triggeredBy": NamedEntity.blank(),
			"emailSentAt": undefined,
			"logType": undefined//,
			//"sentTo": undefined
		};
	}
}

export namespace Invoice {

	function compareAttachments(item1: AttachedFile[], item2: AttachedFile[]): boolean {

		// 1) Must have same number of attachments
		if (item1?.length !== item2?.length) {
			return false;
		}

		let result = true;
		if (item1?.length > 0) {

			// 2) Create a set of each file's md5
			const md5Func = (file: AttachedFile) => {
				const metadata = file.toJSON();
				return metadata.md5;
			};
			const fileSet1 = new Set<string>(item1.map( md5Func ));
			const fileSet2 = new Set<string>(item2.map( md5Func ));

			// 3) Make sure that contents of each set is the same
			fileSet1.forEach( hash => {
				result = result && fileSet2.has(hash);
			});
		}

		return result;
	}

	export function equals(item1: Invoice, item2: Invoice): boolean {
		if (!item1 && !item2) {
			return true;
		}
		else if ((!!item1 || !!item2) && !(!!item1 && !!item2)) {
			return false;
		}

		const scalarProps = [
			'id',
			'clientId',
			'providerId',
			'comment',
			'invoiceNumber',
			'status',
			'deleted',
			'total',
			'reimbursement',
			'reimbursementRecipient',
			'reimbursementRecipientName',
			'planManager',
			'category'
		];

		let result = scalarProps.every( prop => item1[prop] === item2[prop]);

		result = result && item1.lineItems?.length === item2.lineItems?.length;
		if (result) {
			item1.lineItems?.forEach( (lineItem, idx) => {
				result = result && InvoiceLineItem.equals(lineItem, item2.lineItems[idx]);
			});
		}

		// Check the invoice date
		result = result && item1?.invoiceDate?.valueOf() === item2?.invoiceDate?.valueOf();

		// Check the nextActionDate
		result = result && item1?.nextActionDate?.valueOf() === item2?.nextActionDate?.valueOf();

		if (result) {
			// Compare attachments if there are no differences so far
			result = result && compareAttachments(item1.attachments, item2.attachments);
		}

		return result;
	}

	export function parse(src: any): Invoice {
		if (!src) {
			return undefined;
		}

		//console.log(`Invoice: ${JSON.stringify(src)}`);

		return {
			"id": src.id,
			"clientId": src.clientId,
			"clientName": src.clientName,
			"providerId": src.providerId,
			"providerName": src.providerName,
			"adjustment": src.adjustment,
			"comment": src.comment,
			"gst": src.gst,
			"invoiceDate": DateUtils.parse(src.invoiceDate),
			"invoiceNumber": src.invoiceNumber,
			"status": InvoiceStatus.parse(src.status),
			"deleted": !!src.deleted,
			"total": src.total,
			"clientNDISNumber": src.clientNDISNumber,
			"providerAbn": src.providerAbn,
			"createdDate": DateUtils.parse(src.createdDate, true),
			"createdBy": src.createdBy,
			"createdByName": src.createdByName,
			"reimbursement": src.reimbursement,
			"reimbursementRecipient": src.reimbursementRecipient,
			"reimbursementRecipientName": src.reimbursementRecipientName,
			"numLines": src.numLines,
			"clientApproved": src.clientApproved,
			"canPayAfter": DateUtils.parse(src.canPayAfter, true),
			"nextActionDate": DateUtils.parse(src.nextActionDate),
			"planManager": src.planManager,
			"planManagerName": src.planManagerName,
			"attachments": src.attachments?.map( AttachedFile.parse ),
			"lineItems": src.lineItems?.map( InvoiceLineItem.parse ),
			"timeBlogs": (src.timeBlogs || []).map( timeBlog => TimeBlog.parse(timeBlog) ),
			"submissions": src.submissions?.map( InvoiceSubmission.parse ),
			"category": src.category,
			"triage": src.triage?.map( InvoiceTriageDetails.parse ),
			"allocated": src.allocated?.map( InvoiceAllocated.parse ),
			"assignedToInvestigator": src.assignedToInvestigator?.map( AssignedToInvestigator.parse ),
			"payment": src.payment?.map( InvoicePayment.parse ),
			"communicationLog": src.communicationLog?.map( CommunicationLog.parse ),
			"lastTouched": DateUtils.parse(src.lastTouched, true),
			"firstArrived": DateUtils.parse(src.firstArrived, true),
			"currentInvestigator": src.currentInvestigator,
			"serverDateTimeLocal": DateUtils.parse(src.serverDateTimeLocal, true),
			"serverDateTimeUTC": DateUtils.parse(src.serverDateTimeUTC, true)
		};
	}

	export function toJSON(src: Invoice): any {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"clientId": src.clientId,
			"clientName": src.clientName,
			"providerId": src.providerId,
			"providerName": src.providerName,
			"adjustment": src.adjustment,
			"comment": src.comment,
			"gst": src.gst,
			"invoiceDate": DateUtils.toString(src.invoiceDate),
			"invoiceNumber": src.invoiceNumber,
			"status": InvoiceStatus.toPostgresEnum(src.status),
			"deleted": !!src.deleted,
			"total": src.total,
			"clientNDISNumber": src.clientNDISNumber,
			"providerAbn": src.providerAbn,
			"createdDate": DateUtils.toISOString(src.createdDate),
			"createdBy": src.createdBy,
			"createdByName": src.createdByName,
			"reimbursement": src.reimbursement,
			"reimbursementRecipient": src.reimbursementRecipient,
			"reimbursementRecipientName": src.reimbursementRecipientName,
			"numLines": src.numLines,
			"clientApproved": src.clientApproved,
			"canPayAfter": DateUtils.toISOString(src.canPayAfter),
			"nextActionDate": DateUtils.toISOString(src.nextActionDate),
			"planManager": src.planManager,
			"planManagerName": src.planManagerName,
			"attachments": src.attachments?.map( AttachedFile.toJSON ),
			"lineItems": src.lineItems.map( InvoiceLineItem.toJSON ),
			"timeBlogs": src.timeBlogs.map( TimeBlog.toJSON ),
			"submissions": src.submissions?.map( InvoiceSubmission.toJSON ),
			"category": src.category,
			"triage": src.triage?.map( InvoiceTriageDetails.toJSON ),
			"allocated": src.allocated?.map( InvoiceAllocated.toJSON ),
			"assignedToInvestigator": src.assignedToInvestigator?.map( AssignedToInvestigator.toJSON ),
			"payment": src.payment?.map( InvoicePayment.toJSON ),
			"communicationLog": src.communicationLog?.map( CommunicationLog.toJSON ),
		};
	}

	export function clone(src: Invoice): Invoice {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"clientId": src.clientId,
			"clientName": src.clientName,
			"providerId": src.providerId,
			"providerName": src.providerName,
			"adjustment": src.adjustment,
			"comment": src.comment,
			"gst": src.gst,
			"invoiceDate": DateUtils.clone(src.invoiceDate),
			"invoiceNumber": src.invoiceNumber,
			"status": src.status,
			"deleted": !!src.deleted,
			"total": src.total,
			"clientNDISNumber": src.clientNDISNumber,
			"providerAbn": src.providerAbn,
			"createdDate": DateUtils.clone(src.createdDate),
			"createdBy": src.createdBy,
			"createdByName": src.createdByName,
			"reimbursement": src.reimbursement,
			"reimbursementRecipient": src.reimbursementRecipient,
			"reimbursementRecipientName": src.reimbursementRecipientName,
			"numLines": src.numLines,
			"clientApproved": src.clientApproved,
			"canPayAfter": DateUtils.clone(src.canPayAfter),
			"nextActionDate": DateUtils.clone(src.nextActionDate),
			"planManager": src.planManager,
			"planManagerName": src.planManagerName,
			"attachments": src.attachments?.map( AttachedFile.clone ),
			"lineItems": src.lineItems?.map( InvoiceLineItem.clone ),
			"timeBlogs": src.timeBlogs?.map (TimeBlog.clone ),
			"submissions": src.submissions?.map( InvoiceSubmission.clone ),
			"category": src.category,
			"triage": src.triage?.map( InvoiceTriageDetails.clone ),
			"allocated": src.allocated?.map( InvoiceAllocated.clone ),
			"assignedToInvestigator": src.assignedToInvestigator?.map( AssignedToInvestigator.clone ),
			"payment": src.payment?.map( InvoicePayment.clone ),
			"communicationLog": src.communicationLog?.map( CommunicationLog.clone ),
			"lastTouched": DateUtils.clone(src.lastTouched),
			"firstArrived": DateUtils.clone(src.firstArrived),
			"currentInvestigator": src.currentInvestigator,
			"serverDateTimeLocal": DateUtils.clone(src.serverDateTimeLocal),
			"serverDateTimeUTC": DateUtils.clone(src.serverDateTimeUTC)
		};
	}

	export function blank(): Invoice {
		return {
			"id": undefined,
			"clientId": undefined,
			"clientName": undefined,
			"providerId": undefined,
			"providerName": undefined,
			"adjustment": undefined,
			"comment": undefined,
			"gst": undefined,
			"invoiceDate": undefined,
			"invoiceNumber": undefined,
			"status": InvoiceStatus.draft,
			"deleted": false,
			"total": undefined,
			"clientNDISNumber": undefined,
			"providerAbn": undefined,
			"createdDate": undefined,
			"createdBy": undefined,
			"createdByName": undefined,
			"reimbursement": undefined,
			"reimbursementRecipient": undefined,
			"reimbursementRecipientName": undefined,
			"numLines": 0,
			"clientApproved": undefined,
			"canPayAfter": undefined,
			"nextActionDate": undefined,
			"planManager": undefined,
			"planManagerName": undefined,
			"attachments": [],
			"lineItems": [],
			"timeBlogs": [],
			"submissions": [],
			"category": undefined,
			"triage": [],
			"allocated": [],
			"assignedToInvestigator": [],
			"payment": [],
			"communicationLog": [],
			"lastTouched": undefined,
			"firstArrived": undefined,
			"currentInvestigator":undefined,
			"serverDateTimeLocal": undefined,
			"serverDateTimeUTC": undefined
		};
	}
}


export namespace AvailableInvoice {

	export function parse(src: any): AvailableInvoice {
		if (!src) {
			return undefined;
		}

		return {
			id: src.id,
			clientId: src.clientId,
			clientName: src.clientName,
			providerId: src.providerId,
			providerName: src.providerName,
			invoiceDate: DateUtils.parse(src.invoiceDate),
			invoiceNumber: src.invoiceNumber,
			status: InvoiceStatus.parse(src.status),
			total: src.total,
			ndisPaid: src.ndisPaid,
			createdDate: DateUtils.parse(src.createdDate, true),
			readyToPay: src.readyToPay,
			validBankAccount: src.validBankAccount,
			reimbursement: src.reimbursement,
			reimbursementRecipient: src.reimbursementRecipient,
			reimbursementRecipientName: src.reimbursementRecipientName,
			clientApproved: src.clientApproved,
			canPayAfter: DateUtils.parse(src.canPayAfter),
			planManager: src.planManager,
			planManagerName: src.planManagerName
		};
	}

	export function toJSON(src: AvailableInvoice): any {
		if (!src) {
			return undefined;
		}

		return {
			id: src.id,
			clientId: src.clientId,
			clientName: src.clientName,
			providerId: src.providerId,
			providerName: src.providerName,
			invoiceDate: DateUtils.toString(src.invoiceDate),
			invoiceNumber: src.invoiceNumber,
			status: InvoiceStatus.toPostgresEnum(src.status),
			total: src.total,
			ndisPaid: src.ndisPaid,
			createdDate: DateUtils.toISOString(src.createdDate),
			readyToPay: src.readyToPay,
			validBankAccount: src.validBankAccount,
			reimbursement: src.reimbursement,
			reimbursementRecipient: src.reimbursementRecipient,
			reimbursementRecipientName: src.reimbursementRecipientName,
			clientApproved: src.clientApproved,
			canPayAfter: DateUtils.toISOString(src.canPayAfter),
			planManager: src.planManager,
			planManagerName: src.planManagerName
		};
	}

}


export namespace TriagedInvoice {

	export function parse(src: any): TriagedInvoice {
		if (!src) {
			return undefined;
		}

		return {
			id: src.id,
			clientId: src.clientId,
			clientName: src.clientName,
			providerId: src.providerId,
			providerName: src.providerName,
			invoiceDate: DateUtils.parse(src.invoiceDate),
			invoiceNumber: src.invoiceNumber,
			dataEntryInstructions: src.dataEntryInstructions,
			triageAttachmentAdded: DateUtils.parse(src.triageAttachmentAdded, true),
			triageAttachmentId: src.triageAttachmentId,
			triageLevel: TriageLevel.parse(src.triageLevel),
			attachments: src.attachments?.map( AttachedFile.parse )
		};
	}

	export function toJSON(src: TriagedInvoice): any {
		if (!src) {
			return undefined;
		}

		return {
			id: src.id,
			clientId: src.clientId,
			clientName: src.clientName,
			providerId: src.providerId,
			providerName: src.providerName,
			invoiceDate: DateUtils.toString(src.invoiceDate),
			invoiceNumber: src.invoiceNumber,
			dataEntryInstructions: src.dataEntryInstructions,
			triageAttachmentAdded: DateUtils.toISOString(src.triageAttachmentAdded),
			triageAttachmentId: src.triageAttachmentId,
			triageLevel: TriageLevel.toPostgresEnum(src.triageLevel),
			attachments: src.attachments?.map( AttachedFile.toJSON )
		};
	}

}
