import { DateTime } from "luxon";
import { DateUtils } from "@classes/utils";
import { SupportCategory, BasicSupportItem } from "@classes/supports";
import { Region } from "@classes/regions";
import { Provider } from "@classes/provider";
import { AttachedFile } from "@classes/files";
import { Entity } from "@classes/entities";
import { UUIDUtils } from "@classes/utils";
import { StatusNamedEntity } from "@classes/namedEntity";
import { PlanStatus } from "@classes/planStatus";

export interface PlanAttachmentConfirmation  {
	fullPlanAttached: boolean;
	prodaPlanAttached: boolean;
	serviceAgreementAttached: boolean;
	rfsAttached: boolean;
}

export interface PlanOnboarding  {
	nextActionDate: Date;
	onboardingStatusId: string;
	onboardingComment: string;
	prodaSubmissionDate: Date;
	responsiblePersonId: string;
	enduringServiceAgreement: boolean;
	serviceAgreementId: string;
}

export interface PlanSupportItem  {
	supportItem: BasicSupportItem;
	budget?: number;
	bookingCode?: string;
	specifiedItem: boolean;
	quantity?: number;
}

export interface PlanSupportCategory {
	supportCategory: SupportCategory;
	budget: number;
	previousSpend: number;
	bookingCode?: string;
	supportItems: PlanSupportItem[];
}

export interface PlanProvider {
	id: string;
	providerId: string;
	name: string;
	supportCategoryNumber: number;
	supportItemNumber: string;
	budget: number
}

export interface Plan extends Entity {
	userId: string;
	startDate: Date;
	endDate: Date;
	status: PlanStatus;
	total: number;
	region: Region;
	supportCategories: PlanSupportCategory[];
	providers: PlanProvider[];
	attachments: AttachedFile[];
	attachmentConfirmation: PlanAttachmentConfirmation;
	onboarding: PlanOnboarding;
	client: StatusNamedEntity;
	isPACE: boolean;
	planManager: string;
}

export namespace PlanAttachmentConfirmation {

	export function empty(): PlanAttachmentConfirmation {
		return {
			"fullPlanAttached": undefined,
			"prodaPlanAttached": undefined,
			"serviceAgreementAttached": undefined,
			"rfsAttached": undefined
		};
	}
}

export namespace PlanOnboarding {

	export function empty(): PlanOnboarding {
		return {
			"nextActionDate": undefined,
			"onboardingStatusId": undefined,
			"onboardingComment": undefined,
			"prodaSubmissionDate": undefined,
			"responsiblePersonId": undefined,
			"enduringServiceAgreement": undefined,
			"serviceAgreementId": undefined
		};
	}
	export function parse(src: any): PlanOnboarding {
		if (!src) {
			return undefined;
		}

		return {
			"nextActionDate": DateUtils.parse(src.nextActionDate),
			"onboardingStatusId": src.onboardingStatusId,
			"onboardingComment": src.onboardingComment,
			"prodaSubmissionDate": DateUtils.parse(src.prodaSubmissionDate),
			"responsiblePersonId": src.responsiblePersonId,
			"enduringServiceAgreement": src.enduringServiceAgreement,
			"serviceAgreementId": src.serviceAgreementId
		};
	}

	export function toJSON(src: PlanOnboarding): any {
		return {
			"nextActionDate": DateUtils.toString(src?.nextActionDate),
			"onboardingStatusId": UUIDUtils.isUUID(src?.onboardingStatusId) ? src?.onboardingStatusId : null,
			"onboardingComment": src?.onboardingComment,
			"prodaSubmissionDate": DateUtils.toString(src?.prodaSubmissionDate),
			"responsiblePersonId": src?.responsiblePersonId,
			"enduringServiceAgreement": src?.enduringServiceAgreement,
			"serviceAgreementId": UUIDUtils.isUUID(src?.serviceAgreementId) ? src?.serviceAgreementId : null
		};
	}

	export function clone(src: PlanOnboarding): PlanOnboarding {
		if (!src) {
			return undefined;
		}

		return {
			"nextActionDate": DateUtils.clone(src.nextActionDate),
			"onboardingStatusId": src.onboardingStatusId,
			"onboardingComment": src.onboardingComment,
			"prodaSubmissionDate": DateUtils.clone(src.prodaSubmissionDate),
			"responsiblePersonId": src.responsiblePersonId,
			"enduringServiceAgreement": src.enduringServiceAgreement,
			"serviceAgreementId": src.serviceAgreementId
		};
	}
}

export namespace PlanSupportItem {

	export function empty(supportItem?: BasicSupportItem): PlanSupportItem {
		return {
			"supportItem": BasicSupportItem.clone(supportItem),
			"budget": undefined,
			"bookingCode": undefined,
			"specifiedItem": false,
			"quantity": undefined
		};
	}

	export function parse(src: any): PlanSupportItem {
		if (!src) {
			return undefined;
		}

		return {
			"supportItem": BasicSupportItem.parse(src.supportItem),
			"budget": src.budget,
			"bookingCode": src.bookingCode,
			"specifiedItem": !!src.specifiedItem,
			"quantity": src.quantity
		};
	}

	export function toJSON(src: PlanSupportItem): any {
		if (!src) {
			return undefined;
		}

		return {
			"supportItem": BasicSupportItem.toJSON(src.supportItem),
			"budget": src.budget,
			"bookingCode": src.bookingCode,
			"specifiedItem": src.specifiedItem,
			"quantity": src.quantity
		};
	}

	export function clone(src: PlanSupportItem): PlanSupportItem {
		if (!src) {
			return undefined;
		}

		return {
			"supportItem": BasicSupportItem.clone(src.supportItem),
			"budget": src.budget,
			"bookingCode": src.bookingCode,
			"specifiedItem": src.specifiedItem,
			"quantity": src.quantity
		};
	}
}

export namespace PlanSupportCategory {

	export function empty(category?: SupportCategory) {
		return {
			"supportCategory": category,
			"budget": undefined,
			"previousSpend": undefined,
			"bookingCode": undefined,
			"supportItems": []
		};
	}

	export function parse(src: any): PlanSupportCategory {
		if (!src) {
			return undefined;
		}

		return {
			"supportCategory": SupportCategory.parse(src.supportCategory),
			"budget": src.budget,
			"bookingCode": src.bookingCode,
			"previousSpend": src.previousSpend,
			"supportItems": (src.supportItems || []).map( PlanSupportItem.parse )
		};
	}

	export function toJSON(src: PlanSupportCategory): any {
		return {
			"supportCategory": SupportCategory.toJSON(src?.supportCategory),
			"budget": src?.budget,
			"bookingCode": src?.bookingCode,
			"previousSpend": src?.previousSpend,
			"supportItems": (src?.supportItems || []).map( PlanSupportItem.toJSON )
		};
	}

	export function clone(src: PlanSupportCategory): PlanSupportCategory {
		if (!src) {
			return undefined;
		}

		return {
			"supportCategory": SupportCategory.clone(src.supportCategory),
			"budget": src.budget,
			"bookingCode": src.bookingCode,
			"previousSpend": src.previousSpend,
			"supportItems": (src.supportItems || []).map( PlanSupportItem.clone )
		};
	}
}

export namespace PlanProvider {

	export function empty(categoryNumber?: number, provider?: Provider): PlanProvider {
		return {
			"id": undefined,
			"providerId": provider?.id,
			"name": provider?.name,
			"supportCategoryNumber": categoryNumber,
			"supportItemNumber": undefined,
			"budget": undefined
		};
	}

	export function parse(src: any): PlanProvider {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"providerId": src.providerId,
			"name": src.name,
			"supportCategoryNumber": src.supportCategoryNumber,
			"supportItemNumber": src.supportItemNumber ?? undefined,
			"budget": src.budget
		};
	}

	export function toJSON(src: PlanProvider): any {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"providerId": src.providerId,
			"name": src.name,
			"supportCategoryNumber": src.supportCategoryNumber,
			"supportItemNumber": src.supportItemNumber,
			"budget": src.budget
		};
	}

	export function clone(src: PlanProvider): PlanProvider {
		return {...src};
	}
}

export namespace Plan {
	export function parse(src: any): Plan {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"userId": src.userId,
			"startDate": DateUtils.parse(src.startDate),
			"endDate": DateUtils.parse(src.endDate),
			"status": PlanStatus.parse(src.status),
			"total": Number(src.total),
			"region": Region.parse(src.region),
			"supportCategories": (src.categories || []).map( PlanSupportCategory.parse ),
			"providers": (src.providers || []).map( PlanProvider.parse ),
			"attachments": (src.attachments || []).map( file => AttachedFile.parse(file) ),
			"attachmentConfirmation": src.attachmentConfirmation,
			"onboarding": src.onboarding ? PlanOnboarding.parse(src.onboarding) : PlanOnboarding.empty(),
			"client": StatusNamedEntity.parse(src.client),
			"isPACE": src.isPACE,
			"planManager": src.planManager
		};
	}

	export function toJSON(src: Plan): any {
		return {
			"id": src?.id,
			"userId": src?.userId,
			"startDate": DateUtils.toString(src?.startDate),
			"endDate": DateUtils.toString(src?.endDate),
			"status": PlanStatus.toPostgresEnum(src.status),
			"total": src?.total,
			"region": Region.toPostgresEnum(src?.region),
			"categories": (src?.supportCategories || []).map( PlanSupportCategory.toJSON ),
			"providers": (src?.providers || []).map( PlanProvider.parse ),
			"attachments": (src?.attachments || []).map( file => file.toJSON() ),
			"attachmentConfirmation": src?.attachmentConfirmation,
			"onboarding": PlanOnboarding.toJSON(src?.onboarding),
			"client": StatusNamedEntity.toJSON(src.client),
			"isPACE": src.isPACE,
			"planManager": src?.planManager
		};
	}

	export function clone(src: Plan): Plan {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"userId": src.userId,
			"startDate": DateUtils.clone(src.startDate),
			"endDate": DateUtils.clone(src.endDate),
			"status": src.status,
			"total": src.total,
			"region": src.region,
			"supportCategories": src.supportCategories.map( PlanSupportCategory.clone ),
			"providers": src.providers.map( PlanProvider.clone ),
			"attachments": src.attachments.map( AttachedFile.clone ),
			"attachmentConfirmation": src.attachmentConfirmation,
			"onboarding": PlanOnboarding.clone(src.onboarding),
			"client": StatusNamedEntity.clone(src.client),
			"isPACE": src.isPACE,
			"planManager": src.planManager
		};
	}

	export function newPlan(): Plan {
		return {
			"id": undefined,
			"userId": undefined,
			"startDate": undefined,
			"endDate": undefined,
			"status": undefined,
			"total": undefined,
			"region": undefined,
			"supportCategories": [],
			"providers": [],
			"attachments": [],
			"attachmentConfirmation": PlanAttachmentConfirmation.empty(),
			"onboarding": PlanOnboarding.empty(),
			"client": undefined,
			"isPACE": false,
			"planManager": undefined
		};
	}

	export function description(plan: Plan): string {
		if (!plan) {
			return undefined;
		}

		const format = 'd MMM yyyy';
		return `${DateUtils.toString(plan.startDate, format)} - ${DateUtils.toString(plan.endDate, format)} (${PlanStatus.toString(plan.status)})`;
	}

	export function forDate(date: Date, plans: Plan[]): Plan {
		let result: Plan = undefined;

		if (date) {

			let idx = 0;
			const serviceDate = DateTime.fromJSDate(date);

			// Find the plan that covers the selected service date
			while (result === undefined && idx < plans.length) {
				const startDate = DateTime.fromJSDate(plans[idx].startDate);
				const endDate = DateTime.fromJSDate(plans[idx].endDate);
				if (serviceDate >= startDate && serviceDate <= endDate) {
					result = plans[idx];
				}

				idx++;
			}
		}

		return result;
	}

	export function getPlanCategory(plan: Plan, categoryId: number): PlanSupportCategory {
		if (!plan || !categoryId) {
			return undefined;
		}

		const categories = plan?.supportCategories || [];
		return categories.find( category => category.supportCategory.id === categoryId);
	}
}
