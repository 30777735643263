import { Address } from "@classes/address";
import { ProviderAutoTriage } from "@classes/providerAutoTriage";
import { BankStatus } from "@classes/bankStatus";
import { BankVerifiedStatus } from "@classes/bankVerifiedStatus";

export interface Provider {
	id: string;
	name: string;
	email: string;
	phone: string;
	abn: string;
	address?: Address;
	postalAddress?: Address;
	bankAccountNumber: string;
	bankAccountBSB: string;
	bankAccountName: string;
	bankAccountParticulars: string;
	bankAccountStatus?: BankVerifiedStatus;
	contactName: string;
	referenceCode: string;
	inactive: boolean;
	receiveRemittanceEmail: string;
	remittanceEmail: string;
	abnExemption: boolean;
	planManager: string;
	planManagerName: string;
	autoTriage?: ProviderAutoTriage;
	forceToInvestigation: string;
	bankStatus?: BankStatus;
	staffReferrerId: string;
}

export namespace Provider {

	export function parse(src: any): Provider {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"name": src.name,
			"email": src.email,
			"phone": src.phone,
			"abn": src.abn,
			"address": Address.parse(src.address),
			"postalAddress": Address.parse(src.postalAddress),
			"bankAccountNumber": src.bankAccountNumber,
			"bankAccountBSB": src.bankAccountBSB,
			"bankAccountName": src.bankAccountName,
			"bankAccountParticulars": src.bankAccountParticulars,
			"bankAccountStatus": BankVerifiedStatus.parse(src.bankAccountStatus),
			"contactName": src.contactName,
			"referenceCode": src.referenceCode,
			"inactive": src.inactive,
			"receiveRemittanceEmail": src.receiveRemittanceEmail,
			"remittanceEmail": src.remittanceEmail,
			"abnExemption": src.abnExemption,
			"planManager": src.planManager,
			"planManagerName": src.planManagerName,
			"autoTriage": ProviderAutoTriage.parse(src.autoTriage),
			"forceToInvestigation": src.forceToInvestigation,
			"bankStatus": BankStatus.parse(src.bankStatus),
			"staffReferrerId": src.staffReferrerId
		};
	}

	export function toJSON(src: Provider): any {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"name": src.name,
			"email": src.email,
			"phone": src.phone,
			"abn": src.abn,
			"address": Address.toJSON(src.address),
			"postalAddress": Address.toJSON(src.postalAddress),
			"bankAccountNumber": src.bankAccountNumber,
			"bankAccountBSB": src.bankAccountBSB,
			"bankAccountName": src.bankAccountName,
			"bankAccountParticulars": src.bankAccountParticulars,
			"bankAccountStatus": BankVerifiedStatus.toPostgresEnum(src.bankAccountStatus),
			"contactName": src.contactName,
			"referenceCode": src.referenceCode,
			"inactive": src.inactive,
			"receiveRemittanceEmail": src.receiveRemittanceEmail,
			"remittanceEmail": src.remittanceEmail,
			"abnExemption": src.abnExemption,
			"planManager": src.planManager,
			"planManagerName": src.planManagerName,
			"autoTriage": ProviderAutoTriage.toJSON(src.autoTriage),
			"forceToInvestigation": src.forceToInvestigation,
			"bankStatus": BankStatus.toJSON(src.bankStatus),
			"staffReferrerId": src.staffReferrerId
		};
	}

	export function clone(src: Provider): Provider {
		return {
			"id": src.id,
			"name": src.name,
			"email": src.email,
			"phone": src.phone,
			"abn": src.abn,
			"address": Address.clone(src.address),
			"postalAddress": Address.clone(src.postalAddress),
			"bankAccountNumber": src.bankAccountNumber,
			"bankAccountBSB": src.bankAccountBSB,
			"bankAccountName": src.bankAccountName,
			"bankAccountParticulars": src.bankAccountParticulars,
			"bankAccountStatus": src.bankAccountStatus,
			"contactName": src.contactName,
			"referenceCode": src.referenceCode,
			"inactive": src.inactive,
			"receiveRemittanceEmail": src.receiveRemittanceEmail,
			"remittanceEmail": src.remittanceEmail,
			"abnExemption": src.abnExemption,
			"planManager": src.planManager,
			"planManagerName": src.planManagerName,
			"autoTriage": src.autoTriage ? ProviderAutoTriage.clone(src.autoTriage) : ProviderAutoTriage.blank(),
			"forceToInvestigation": src.forceToInvestigation,
			"bankStatus": src.autoTriage ? BankStatus.clone(src.bankStatus) : BankStatus.blank(),
			"staffReferrerId": src.staffReferrerId
		}
	}

	export function blank(): Provider {
		return {
			"id": undefined,
			"name": undefined,
			"email": undefined,
			"phone": undefined,
			"abn": undefined,
			"address": Address.blank(),
			"postalAddress": Address.blank(),
			"bankAccountNumber": undefined,
			"bankAccountBSB": undefined,
			"bankAccountName": undefined,
			"bankAccountParticulars": undefined,
			"contactName": undefined,
			"referenceCode": undefined,
			"inactive": undefined,
			"receiveRemittanceEmail": undefined,
			"remittanceEmail": undefined,
			"abnExemption": undefined,
			"planManager": undefined,
			"planManagerName": undefined,
			"autoTriage": ProviderAutoTriage.blank(),
			"forceToInvestigation": undefined,
			"bankStatus": BankStatus.blank(),
			"staffReferrerId": undefined
		};
	}

}
