import { Component } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  "templateUrl": "./denied.component.html",
  "styleUrls": []
})
export class AccessDeniedComponent {
	constructor(private authService: AuthService) {
	}

	public get isLoggedIn(): boolean {
		return this.authService.isLoggedIn;
	}

	public get usersHomePage(): string {
		return this.authService.usersHomePage;
	}
}
