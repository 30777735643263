import { EnumMetadata } from "@classes/enumUtils";

export enum BankVerifiedStatus {
	not_started, in_progress, email_sent, sms_sent, report_available, pending_verification, manually_verified, verified, expired
}

export namespace BankVerifiedStatus {
	const enumData: EnumMetadata<BankVerifiedStatus>[] = [
		[BankVerifiedStatus.not_started, "Not Started", "not_started"],
		[BankVerifiedStatus.in_progress, "In Progress", "in_progress"],
		[BankVerifiedStatus.email_sent, "Email Sent", "email_sent"],
		[BankVerifiedStatus.sms_sent, "SMS Sent", "sms_sent"],
		[BankVerifiedStatus.report_available, "Report Available", "report_available"],
		[BankVerifiedStatus.pending_verification, "Pending Verification", "pending_verification"],
		[BankVerifiedStatus.manually_verified, "Manually Verified", "manually_verified"],
		[BankVerifiedStatus.verified, "Verified", "verified"],
		[BankVerifiedStatus.expired, "Expired", "expired"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): BankVerifiedStatus {
		return values.get(value);
	}

	export function toString(value: BankVerifiedStatus): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: BankVerifiedStatus): string {
		return pgEnums.get(value);
	}

	export function allValues(): BankVerifiedStatus[] {
		return enumData.map( item => item[0] );
	}
}
