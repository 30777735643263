import { Injectable } from '@angular/core';
import { RestService, API } from '@services/rest.service';
import { WebSocketService } from "@services/websocket.service";
import { StorageService } from '@services/storage.service';
import { FileNote } from "@classes/filenotes";
import { AuthService } from "@services/auth.service";
import WaitQueue from "@classes/waitQueue"

@Injectable()
export class FileNoteService {

	private static useWebsocket: boolean = false;

	private _pinnedNotesWaitQueue = new WaitQueue<FileNote[]>();

	constructor(
		private restService: RestService,
		private websocket: WebSocketService,
		private authService: AuthService) {}

	public async loadNote(noteId: string): Promise<FileNote> {
		const cachedNote = await StorageService.entities.get(noteId);
		if (!!cachedNote) {
			return FileNote.parse(cachedNote);
		}
		
		const data = await this.restService.get(API.filenotes, `note/${noteId}`);
		const filenote = FileNote.parse(data);
		await StorageService.entities.set(FileNote.toJSON(filenote));
		return filenote;
	}

	public async deleteNote(note: FileNote): Promise<void> {
		let promise: Promise<any>;

		if (FileNoteService.useWebsocket) {
			promise = this.websocket.send("filenote.delete", {"filenote": note.id});
		}
		else {
			const postData = {
				"note": FileNote.toJSON(note)
			};

			promise = this.restService.delete(API.filenotes, `note/${note.id}`);
		}

		const response = await promise;
		if (!!response.success) {
			await StorageService.entities.remove(note.id);
			return Promise.resolve();
		}
		else {
			return Promise.reject("Failed to delete journal entry");
		}
	}

	public async save(note: FileNote): Promise<FileNote> {
		let promise: Promise<any>;

		if (FileNoteService.useWebsocket) {
			promise = this.websocket.send("filenote.put", {"filenote": note});
		}
		else {
			const postData = {
				"note": FileNote.toJSON(note)
			};

			const options = this.authService.isMarauderAdmin ? { "planManager": note.planManager } : undefined;
			promise = this.restService.post(API.filenotes, "note", postData, options);
		}

		const response = await promise;
		const filenote = FileNote.parse(response.note);
		await StorageService.entities.set(FileNote.toJSON(filenote));

		const target = filenote.client.id || filenote.provider.id;
		const storageKey = `notes.${target}`;
		const noteList = await StorageService.general.get(storageKey);
		if (Array.isArray(noteList)) {
			if (!noteList.includes(filenote.id)) {
				noteList.push(filenote.id);
				await StorageService.general.set(storageKey, noteList);
			}
		}


		return filenote;
	}

	/**
	* Retrieves pinned notes for the requested client or provider.
	* Caches the results in local storage for faster access next time.
	*/
	private async fetchNotes(sourceId: string, storageKey: string): Promise<FileNote[]> {

		const data = await this.restService.get(API.filenotes, `pinned/${sourceId}`);
		const filenotes = data.map( FileNote.parse );
		const filenoteIds = filenotes.map( note => note.id );

		const promises = [
			StorageService.entities.set(filenotes.map(FileNote.toJSON)),
			StorageService.general.set(storageKey, filenoteIds)
		];

		await Promise.all(promises);
		return filenotes;
	}

	/**
	* Returns all pinned notes for the source entity.
	* Returns cached data if it exists, otherwise fetches from the server.
	*/
	public async pinnedNotes(sourceId: string, forceReload: boolean = false): Promise<FileNote[]> {

		const storageKey = `pinnedNotes.${sourceId}`;

		if (forceReload) {
			await StorageService.general.remove(storageKey);
		}

		// If we've already cached the filenotes for the target, retrieve them from storage and exit
		const cachedNotesList = await StorageService.general.get(storageKey);
		if (Array.isArray(cachedNotesList) && cachedNotesList.length > 0) {
			const promises = cachedNotesList.map( StorageService.entities.get );
			const data = await Promise.all(promises);
			const cachedNotes = data.map( FileNote.parse )

			// Make sure we have all of the notes in the session storage. If not, fetch them from the server
			if (cachedNotes.every(note => !!note)) {
				return cachedNotes;
			}
		}
		return this._pinnedNotesWaitQueue.enqueue( sourceId, this.fetchNotes.bind(this, sourceId, storageKey) );
	}

	/**
	* Remove any cached pinned notes.
	*/
	public async tidyPinnedNotes(sourceId: string): Promise<void> {

		const storageKey = `pinnedNotes.${sourceId}`;
		await StorageService.general.remove(storageKey);
		
	}

	/**
	* Remove any cached billing notes.
	*/
	public async tidyBillingNotes(sourceId: string): Promise<void> {

		const storageKey = `billingNotes.${sourceId}`;
		await StorageService.general.remove(storageKey);
		
	}

}
