export enum ThemeElementType {
	colour, text
}

export interface ThemeElement {
	id: string;
	name: string;
	type: ThemeElementType;
	sortOrder: number;
	varName: string;
	value?: string;
}

export interface Theme {
	logo: string;
	items: ThemeElement[];
}

export interface Branding {
	documentTitle: string;
	theme: Theme;
}

// export type Theme = ThemeElement[];

// <region> Namespaces
export namespace ThemeElement {
	export function parse(src: any): ThemeElement {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"name": src.name,
			"type": ThemeElementType.parse(src.data?.type),
			"varName": src.data?.varName,
			"sortOrder": Number(src.sortOrder) || 0,
			"value": src.value || undefined
		};
	}

	export function clone(src: ThemeElement): ThemeElement {
		return Object.assign({}, src);
	}
}

export namespace ThemeElementType {
	export function parse(src: string): ThemeElementType {
		switch (src) {
			case "colour": return ThemeElementType.colour;
			default: return ThemeElementType.text;
		}
	}
}

export namespace Theme {
	export function parse(src: any): Theme {
		if (!src) {
			return undefined;
		}

		return {
			"logo": src.logo,
			"items": (src.items || []).map( ThemeElement.parse )
		};
	}
}
// </region>
