import { NgModule, LOCALE_ID }                     from '@angular/core';
import { FormsModule }                             from '@angular/forms';
import { FontAwesomeModule }                       from '@fortawesome/angular-fontawesome';

import { CurrencyDirective }                       from './directives/currencyFormatter/currencyFormatter';
import { EmptyToUndefinedDirective }               from './directives/emptyToUndefined/emptyToUndefined';
import { FileDropDirective }                       from './directives/filedrop/filedrop.directive';
import { AutoFocus }			                         from './directives/autoFocus/autoFocus.directive';
import { ABNValidatorDirective }                   from './directives/abnValidator/abn.directive';
import { AuMobileValidatorDirective }              from './directives/phoneValidator/auphone.validator';
import { AuPhoneValidatorDirective }               from './directives/phoneValidator/auphone.validator';
import { EmailValidatorDirective }                 from './directives/emailValidator/email.validator';
import { OnlyDigitsDirective }                     from './directives/onlyDigits/onlyDigits.directive';
import { NumericDirective }                        from './directives/numeric/numeric.directive';
import { BSBValidatorDirective }                   from './directives/bsbValidator/bsb.directive';
import { RequiredFieldLabelDirective }             from './directives/requiredField/requiredFieldLabel';
import { IndeterminateDirective }                  from './directives/indeterminate/indeterminate.directive';
import { LinkDirective }                           from './directives/newWindowRouterLink/newWindowRouterLink';
import { CollapsibleElementDirective }             from './directives/collapsible/collapsible.directive';

import { FileSizePipe }                            from './pipes/filesize.pipe';
import { TruncatePipe }                            from './pipes/truncate.pipe';

import { TableHeaderComponent }                    from '@components/table/tableHeader.component';
import { PaginationComponent }                     from '@components/pagination/pagination.component';
import { TypeaheadComponent }                      from '@components/typeahead/typeahead.component';
import { BreadcrumbsComponent }                    from '@components/breadcrumbs/breadcrumbs.component';
import { LoadingComponent }                        from '@components/loading/loading.component';
import { SpinnerComponent }                        from '@components/spinner/spinner.component';
import { FileInputComponent }                      from '@components/fileInput/fileInput.component';
import { ImageInputComponent }                     from '@components/imageInput/imageInput.component';
import { PinnedNotesComponent }                    from '@modules/shared/components/pinnedNotes/pinnedNotes.component';
import { ToggleComponent }                         from '@components/toggle/toggle.component';

import { LabelledItemComponent }                   from '@modules/shared/components/labelledItem/labelledItem.component';

import { DateInputComponent }                      from '@components/dateControl/datecontrol.component';

import { CommonModule }                            from '@angular/common';
import { RouterModule }                            from '@angular/router';

import { FileNoteService }                         from '@services/filenote.service';
import { TrafficButtonComponent }                  from '@root/app/traffic-button/traffic-button.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FontAwesomeModule,
		RouterModule
	],
	declarations: [
		TableHeaderComponent,
		PaginationComponent,
		TypeaheadComponent,
		BreadcrumbsComponent,
		CurrencyDirective,
		EmptyToUndefinedDirective,
		RequiredFieldLabelDirective,
		FileDropDirective,
		LoadingComponent,
		SpinnerComponent,
		FileInputComponent,
		ImageInputComponent,
		FileSizePipe,
		TruncatePipe,
		AutoFocus,
		ABNValidatorDirective,
		AuMobileValidatorDirective,
		AuPhoneValidatorDirective,
		EmailValidatorDirective,
		OnlyDigitsDirective,
		NumericDirective,
		BSBValidatorDirective,
		IndeterminateDirective,
		LinkDirective,
		CollapsibleElementDirective,
		PinnedNotesComponent,
		DateInputComponent,
		LabelledItemComponent,
		ToggleComponent,
		TrafficButtonComponent
	],
	exports: [
		TableHeaderComponent,
		PaginationComponent,
		TypeaheadComponent,
		BreadcrumbsComponent,
		CurrencyDirective,
		EmptyToUndefinedDirective,
		RequiredFieldLabelDirective,
		LoadingComponent,
		SpinnerComponent,
		FileDropDirective,
		FileInputComponent,
		ImageInputComponent,
		FileSizePipe,
		TruncatePipe,
		AutoFocus,
		ABNValidatorDirective,
		AuMobileValidatorDirective,
		AuPhoneValidatorDirective,
		EmailValidatorDirective,
		OnlyDigitsDirective,
		NumericDirective,
		BSBValidatorDirective,
		IndeterminateDirective,
		LinkDirective,
		CollapsibleElementDirective,
		PinnedNotesComponent,
		DateInputComponent,
		LabelledItemComponent,
		ToggleComponent,
		TrafficButtonComponent
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'en-AU' },
		FileNoteService
	]
})
export class SharedModule { }
