import { NgModule }                                from '@angular/core';
import { Routes, RouterModule }                    from '@angular/router';

import { DefaultComponent }                        from '@components/app/default/default.component';
import { LoginComponent }                          from '@components/login/login.component';
import { AccessDeniedComponent }                   from '@components/accessdenied/denied.component';
import { AdminCanLoad }                            from "@classes/guards/admin.canload";
import { AdminOrSupportCoordinatorCanLoad }        from "@classes/guards/adminOrSupportCoordinator.canload";
import { NotLoggedInCanActivate }                  from "@classes/guards/notLoggedIn.canactivate";
import { MustBeLoggedInToLoad }                    from "@classes/guards/loggedIn.canload";
import { MaintenanceComponent }                    from '@components/maintenance/maintenance.component';

const globalRoutes: Routes = [
	{ "path": "", "component": DefaultComponent, "canActivate": [NotLoggedInCanActivate] },
	{ "path": "login", "component": LoginComponent, "canActivate": [NotLoggedInCanActivate]},
	{ "path": "maintenance", "component": MaintenanceComponent },
	{ "path": "forbidden", "component": AccessDeniedComponent },
	{ "path": "password", "loadChildren": () => import("./modules/password/password.module").then( m => m.PasswordModule ) }
];

const adminRoutes: Routes = [{
	"path": "admin",
	"loadChildren": () => {
		return import("./modules/admin/admin.module").then( m => m.AdminModule )
	},
	"canLoad": [AdminCanLoad] // AdminCanLoad prevents the admin module from loading if the user is not logged in and an administrator
}];

const userRoutes: Routes = [{
	"path": "user",
	"loadChildren": () => {
		return import("./modules/contacts/contact.module").then( m => m.ContactModule )
	},
	"canLoad": [AdminOrSupportCoordinatorCanLoad] // Must be an administrator or contact/support coordinator to load
}]

const clientRoutes: Routes = [{
	"path": "client",
	"loadChildren": () => {
		return import("./modules/client/client.module").then( m => m.ClientModule )
	},
	"canLoad": [MustBeLoggedInToLoad] // Must be an administrator or contact/support coordinator to load
}]

@NgModule({
  imports: [RouterModule.forRoot([].concat(globalRoutes, adminRoutes, userRoutes, clientRoutes))],
  exports: [
  	RouterModule
  ],
	"providers": [
		AdminCanLoad,
		AdminOrSupportCoordinatorCanLoad,
		NotLoggedInCanActivate,
		MustBeLoggedInToLoad
	]
})
export class AppRoutingModule {
}
