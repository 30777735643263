import { Injectable } from "@angular/core";
import { API, RestService } from "@services/rest.service";
import { Branding, Theme, ThemeElement } from "@classes/theme";
import { SimpleWaitQueue } from "@classes/waitQueue";
import { ReplaySubject, Observable } from "rxjs";

@Injectable({ "providedIn": "root" })
export class ThemeLoaderService {

	private _cache: Branding;
	private _allThemeOptions: Theme;
	private _queue: SimpleWaitQueue<any> = new SimpleWaitQueue<any>();
	private _themeLoaded = new ReplaySubject<Branding>(1);

	public readonly themeLoaded$: Observable<Branding> = this._themeLoaded.asObservable();

	constructor(private restService: RestService) {}

	private fetch() {
		return this.restService.get(API.system, 'theme');
	}

	public async loadThemeFromDomain() {

		if (this._cache) {
			this._themeLoaded.next(this._cache);
			return;
		}

		const data: any = await this._queue.enqueue(this.fetch.bind(this));

		// Cache the list of all theme options, used to reset the theme if needed
		if (!this._allThemeOptions && data.allOptions) {
			this._allThemeOptions = data.allOptions.map( ThemeElement.parse );
			delete data.allOptions;
		}

		const branding = {
			"documentTitle": data.name,
			"theme": Object.assign({}, {"items": this._allThemeOptions}, Theme.parse(data.theme))
		};

		this._cache = branding;
		this._themeLoaded.next(this._cache);
	}

	public get allThemeOptions(): Theme {
		return this._allThemeOptions;
	}
}
