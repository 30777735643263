import { EnumMetadata } from "@classes/enumUtils";

export enum PlanStatus {
	draft, current, expired
}

export namespace PlanStatus {
	const enumData: EnumMetadata<PlanStatus>[] = [
		[PlanStatus.draft, "Draft", "draft"],
		[PlanStatus.current, "Current", "current"],
		[PlanStatus.expired, "Expired", "expired"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): PlanStatus {
		return values.get(value);
	}

	export function toString(value: PlanStatus): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: PlanStatus): string {
		return pgEnums.get(value);
	}

	export function allValues(): PlanStatus[] {
		return enumData.map( item => item[0] );
	}
}
