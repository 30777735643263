import { Entity } from '@classes/entities';
import { PlanManagerOptions, AdminPlanManagerOptions } from '@classes/planManagerOptions';

/**
 * Simple plan manager interface, suitable for public-facing portions of the app.
 */
export interface PlanManager extends Entity {
  id: string;
  name: string;
  email: string;
  phone: string;
  logo: string;
  options: PlanManagerOptions;
}

/**
 * Extended plan manager definition, used by admins and Marauders
 */
export interface AdminPlanManager extends PlanManager {
  schema: string;
  contactName: string;
  registrationNumber: string;
  options: AdminPlanManagerOptions;
  readonly numClients?: number;
}

// tslint:disable-next-line:no-namespace
export namespace PlanManager {
  export function parse(src: any): PlanManager {
    if (!src) {
      return undefined;
    }

    return {
      id: src.id,
      name: src.name,
      email: src.email,
      phone: src.phone,
      logo: src.logo,
      options: PlanManagerOptions.parse(src.options)
    };
  }
}

// tslint:disable-next-line:no-namespace
export namespace AdminPlanManager {

  export function parse(src: any): AdminPlanManager {
    if (!src) {
      return undefined;
    }

    return Object.assign(PlanManager.parse(src), {
      schema: src.schema,
      contactName: src.contactName,
      registrationNumber: src.registrationNumber,
      numClients: src.numClients,
      options: AdminPlanManagerOptions.parse(src.options) || AdminPlanManagerOptions.empty()
    });
  }

  export function toJSON(src: AdminPlanManager): any {
    if (!src) {
      return undefined;
    }

    return {
      id: src.id,
      name: src.name,
      email: src.email,
      phone: src.phone,
      logo: src.logo,
      schema: src.schema,
      contactName: src.contactName,
      numClients: src.numClients,
      registrationNumber: src.registrationNumber,
      options: AdminPlanManagerOptions.toJSON(src.options)
    };
  }

  export function clone(src: AdminPlanManager): AdminPlanManager {
    if (!src) {
      return undefined;
    }

    return AdminPlanManager.parse(src);
  }

  export function empty(): AdminPlanManager {
    return {
      id: undefined,
      name: undefined,
      email: undefined,
      phone: undefined,
      logo: undefined,
      schema: undefined,
      contactName: undefined,
      registrationNumber: undefined,
      options: AdminPlanManagerOptions.empty()
    };
  }

}
