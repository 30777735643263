import { EnumMetadata } from "@classes/enumUtils";

export enum UnitOfMeasure {
	each, hour, day, week, month, year
}

export namespace UnitOfMeasure {
	const enumData: EnumMetadata<UnitOfMeasure>[] = [
		[UnitOfMeasure.each, "Each", "EA"],
		[UnitOfMeasure.hour, "per hour", "H"],
		[UnitOfMeasure.day, "per day", "D"],
		[UnitOfMeasure.week, "per week", "WK"],
		[UnitOfMeasure.month, "per month", "MON"],
		[UnitOfMeasure.year, "per year", "YR"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): UnitOfMeasure {
		return values.get(value);
	}

	export function toString(value: UnitOfMeasure): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: UnitOfMeasure): string {
		return pgEnums.get(value);
	}

	export function allValues(): UnitOfMeasure[] {
		return enumData.map( item => item[0] );
	}
}
