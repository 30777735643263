import { DateUtils } from "@classes/utils";
import { NamedEntity } from "./namedEntity";

export interface TimeBlog {
    id: string;
    content: string;
    eventDate: Date;
    createdBy: NamedEntity;
    createdDate: Date;
    modifiedBy: NamedEntity;
    lastModified: Date;
    filenoteId: string;
    invoiceId: string;
    deleted: boolean;
    totalMinutes: number;
    cbUnits: number;
}

export namespace TimeBlog {

	export function parse(src: any): TimeBlog {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"content": src.content,
			"eventDate": DateUtils.parse(src.eventDate),
			"createdBy": NamedEntity.parse(src.createdBy),
			"createdDate": DateUtils.parse(src.createdDate, true),
			"modifiedBy": NamedEntity.parse(src.modifiedBy),
			"lastModified": DateUtils.parse(src.lastModified, true),
			"filenoteId": src.filenoteId,
			"invoiceId": src.invoiceId,
			"deleted": !!src.deleted,
			"totalMinutes": src.totalMinutes,
			"cbUnits": src.cbUnits
		};
	}

	export function toJSON(src: TimeBlog): any {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"content": src.content,
			"eventDate": DateUtils.toString(src.eventDate),
			"createdBy": NamedEntity.toJSON(src.createdBy),
			"createdDate": DateUtils.toISOString(src.createdDate),
			"modifiedBy": NamedEntity.toJSON(src.modifiedBy),
			"lastModified": DateUtils.toISOString(src.lastModified),
			"filenoteId": src.filenoteId,
			"invoiceId": src.invoiceId,
			"deleted": !!src.deleted,
			"totalMinutes": src.totalMinutes,
			"cbUnits": src.cbUnits
		};
	}

	export function clone(src: TimeBlog): TimeBlog {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"content": src.content,
			"eventDate": DateUtils.clone(src.eventDate),
			"createdBy": NamedEntity.clone(src.createdBy),
			"createdDate": DateUtils.clone(src.createdDate),
			"modifiedBy": NamedEntity.clone(src.modifiedBy),
			"lastModified": DateUtils.clone(src.lastModified),
			"filenoteId": src.filenoteId,
			"invoiceId": src.invoiceId,
			"deleted": !!src.deleted,
			"totalMinutes": src.totalMinutes,
			"cbUnits": src.cbUnits
		};
	}

	export function blank(): TimeBlog {
		return {
			"id": undefined,
			"content": undefined,
			"eventDate": new Date(), // Defaulting to today for new timeblog entries
			"createdBy": NamedEntity.blank(),
			"createdDate": undefined,
			"modifiedBy": NamedEntity.blank(),
			"lastModified": undefined,
			"filenoteId": undefined,
			"invoiceId": undefined,
			"deleted": undefined,
			"totalMinutes": undefined,
			"cbUnits": 0
		};
	}
}
