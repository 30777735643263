import { EnumMetadata } from "@classes/enumUtils";

export enum Region {
	nsw, act, vic, qld, sa, wa, nt, tas, remote, veryRemote
};

export namespace Region {

	const enumData: EnumMetadata<Region>[] = [
		[Region.nsw, "NSW", "NSW"],
		[Region.act, "ACT", "ACT"],
		[Region.vic, "VIC", "VIC"],
		[Region.qld, "QLD", "QLD"],
		[Region.sa, "SA", "SA"],
		[Region.wa, "WA", "WA"],
		[Region.nt, "NT", "NT"],
		[Region.tas, "TAS", "TAS"],
		[Region.remote, "Remote", "REMOTE"],
		[Region.veryRemote, "Very Remote", "VERY REMOTE"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): Region {
		return values.get(value?.toUpperCase());
	}

	export function toString(value: Region): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: Region): string {
		return pgEnums.get(value);
	}

	export function allValues(): Region[] {
		return enumData.map( item => item[0] );
	}
}
