import { TriageLevel } from "@classes/triageLevel";

export interface ProviderAutoTriage {
	providerId: string;
	fromEmailName: string;
	invoiceNumberPattern: string;
	triageLevel?: TriageLevel;
	planManager: string;
	autoTriageEnabled: boolean;
	revelioEnabled: boolean;
}

export namespace ProviderAutoTriage {

	export function parse(src: any): ProviderAutoTriage {
		if (!src) {
			return undefined;
		}

		return {
			"providerId": src.providerId,
			"fromEmailName": src.fromEmailName,
			"invoiceNumberPattern": src.invoiceNumberPattern,
			"triageLevel": TriageLevel.parse(src.triageLevel),
			"planManager": src.planManager,
			"autoTriageEnabled": src.autoTriageEnabled,
			"revelioEnabled": src.revelioEnabled
		};
	}

	export function toJSON(src: ProviderAutoTriage): any {
		if (!src) {
			return undefined;
		}

		return {
			"providerId": src.providerId,
			"fromEmailName": src.fromEmailName,
			"invoiceNumberPattern": src.invoiceNumberPattern,
			"triageLevel": TriageLevel.toPostgresEnum(src.triageLevel),
			"planManager": src.planManager,
			"autoTriageEnabled": src.autoTriageEnabled,
			"revelioEnabled": src.revelioEnabled
		};
	}

	export function clone(src: ProviderAutoTriage): ProviderAutoTriage {
		return {
			"providerId": src.providerId,
			"fromEmailName": src.fromEmailName,
			"invoiceNumberPattern": src.invoiceNumberPattern,
			"triageLevel": src.triageLevel,
			"planManager": src.planManager,
			"autoTriageEnabled": src.autoTriageEnabled,
			"revelioEnabled": src.revelioEnabled
		}
	}

	export function blank(): ProviderAutoTriage {
		return {
			"providerId": undefined,
			"fromEmailName": undefined,
			"invoiceNumberPattern": undefined,
			"triageLevel": undefined,
			"planManager": undefined,
			"autoTriageEnabled": undefined,
			"revelioEnabled": undefined
		};
	}

}
