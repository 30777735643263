import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";
import { User, Admin } from "@classes/user";
import { Permission, Permissions } from "@classes/permissions";

/**
* Angular route guard to prevent loading of modules unless the user is logged in, and an administrator, and has
* any permissions that may be required for the route.
*
* Permissions are specified on the route's "data" property from the router module.
*/
@Injectable()
export class AdminCanLoad implements CanLoad {

	constructor(private authService: AuthService, private router: Router) {}

	private checkUserHasAllPermissions(user: Admin, requiredPermissions: Permission[]): boolean {

		// Return true if all of the requested permissions have been assigned to the current user
		try {
			const permissionIds = requiredPermissions.map( Permissions.getPermissionId )
			return permissionIds.every( permissionId => user.permissions.includes( permissionId ) );
		}
		catch (e) {
			return false;
		}
	}

	private checkUserHasAnyPermission(user: Admin, anyPermissions: Permission[]): boolean {

		// Return true if all of the requested permissions have been assigned to the current user
		try {
			const permissionIds = anyPermissions.map( Permissions.getPermissionId )
			return permissionIds.filter( permissionId => user.permissions.includes( permissionId ) ).length >= 1;
		}
		catch (e) {
			return false;
		}
	}

	canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {

			// Subscribe to the "userLoaded" observable on the authService.
			// Filter out the initial value (undefined)
			// Use the "first()" operator to automatically unsubsribe once a value has been received.
			this.authService.userLoaded$.pipe(filter(x => x !== undefined), first()).subscribe( userLoaded => {

				// If you're not logged in, you can't load the module
				if (!userLoaded) {
					this.router.navigate(['/login']);
					return resolve(false);
				}

				// Can't load this module unless you're an admin
				if (!User.isAdmin(this.authService.currentUser)) {
					return resolve(false);
				}
				
				// If no particular permissions are specified for this route, go ahead and load
				if (route.data?.loadPermissionsAny && route.data?.loadPermissionsAny?.length > 0) {
					const routePermissions = route.data?.loadPermissionsAny ?? []
					if (routePermissions.length === 0) {
						return resolve(true);
					}

					// Otherwise, make sure the user has at least one of the permissions required
					try {
						resolve( this.checkUserHasAnyPermission(this.authService.currentUser, routePermissions) );
					}
					catch (e) {
						resolve(false);
					}
				} else {
					const routePermissions = route.data?.loadPermissions ?? []
					if (routePermissions.length === 0) {
						return resolve(true);
					}

					// Otherwise, make sure the user has all of the permissions required
					try {
						resolve( this.checkUserHasAllPermissions(this.authService.currentUser, routePermissions) );
					}
					catch (e) {
						resolve(false);
					}
				}
			});

		});
	}
}
