import { Component } from '@angular/core';
import { AuthService } from "@services/auth.service";
import { User } from "@classes/user";

@Component({
	"selector": 'maintenance-banner',
	"templateUrl": './banner.component.html',
	"styleUrls": ['./banner.component.scss']
})
export class MaintenanceBannerComponent {

	constructor(private authService: AuthService) {}

	private get isMaintenanceMode(): boolean {
		return this.authService.maintenanceMode;
	}

	private get isMarauder(): boolean {
		return User.isAdmin(this.authService.currentUser) && this.authService.currentUser.marauder;
	}

	public get displayBanner(): boolean {
		return this.isMaintenanceMode && this.isMarauder;
	}
}
