import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from "@angular/router";
import { StringUtils } from '@classes/utils';
import { AuthService } from '@services/auth.service';
import { EPasswordResetRequired } from '@classes/errors';
import { ThemeLoaderService } from "@services/themeLoader.service";
import { Subscription } from "rxjs";
import { config } from "@root/config";

interface LoginModel {
	email: string;
	password: string;
}

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: []
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('username')
	private _usernameField: ElementRef<HTMLInputElement>;

	private _showPassword: boolean = false;
	private _subs: Subscription;
	private _loading: boolean = false;

	constructor(private authService: AuthService, private router: Router, private themeLoader: ThemeLoaderService) {}

	public model: LoginModel = <LoginModel>{};

	public get showPassword(): boolean {
		return this._showPassword;
	}

	public togglePasswordDisplay(): void {
		this._showPassword = !this._showPassword;
	}

	public async doLogin(): Promise<void> {
		try {
			this._loading = true;
			await this.authService.login(this.model.email, this.model.password);
		}
		catch (err) {
			this._loading = false;
			if (err instanceof EPasswordResetRequired) {
				this.router.navigate(['/password/reset']);
			}
		}
	}

	private notEmpty(value: string) {
		return StringUtils.trim(value ?? "") !== "";
	}

	private userLoaded(isLoggedIn: boolean) {
		if (isLoggedIn) {
			this.router.navigate([ this.authService.usersHomePage ]);
		}
	}

	public get canLogIn(): boolean {
		return this.notEmpty(this.model.email) && this.notEmpty(this.model.password);
	}

	public get isProd(): boolean {
		return config.stage === 'Prod';
	}

	ngOnInit() {
		// The the user is already logged in, redirect them to their homepage
		if (this.authService.isLoggedIn) {
			this.router.navigate([ this.authService.usersHomePage ]);
		}

		// Listen for authentication changes, and redirect if the user is logged in
		this._subs = this.authService.userLoaded$.subscribe( this.userLoaded.bind(this) );
	}

	ngAfterViewInit() {
		this._usernameField?.nativeElement.focus();
	}

	ngOnDestroy(): void {
		this._subs.unsubscribe();
	}

	public get loading(): boolean {
		return this._loading;
	}
}
