export class EInvalidArgument extends Error {
	constructor(message) {
		super(message);

		// Workaround to fix subclass of built-in classes
		// See https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, EInvalidArgument.prototype);
	}
}

export class ENotAuthorised extends Error {
	constructor(message?: string) {
		super(message);

		// Workaround to fix subclass of built-in classes
		Object.setPrototypeOf(this, EPermissionsNotLoaded.prototype);
	}
}

export class EPermissionsNotLoaded extends Error {
	constructor(message?: string) {
		super(message);

		// Workaround to fix subclass of built-in classes
		Object.setPrototypeOf(this, EPermissionsNotLoaded.prototype);
	}
}

export class EPasswordResetRequired extends Error {
	constructor(message?: string) {
		super(message);

		// Workaround to fix subclass of built-in classes
		Object.setPrototypeOf(this, EPasswordResetRequired.prototype);
	}
}

export class EAssertionFailed extends Error {
	constructor(message?: string) {
		super(message);

		// Workaround to fix subclass of built-in classes
		Object.setPrototypeOf(this, EAssertionFailed.prototype);
	}
}

export class ENotFound extends Error {
	constructor(message?: string) {
		super(message);

		// Workaround to fix subclass of built-in classes
		Object.setPrototypeOf(this, ENotFound.prototype);
	}
}

export function assert(value: unknown, msg?: string): asserts value {
	if (!value) {
		throw new EAssertionFailed(msg);
	}
}

export namespace ErrorUtils {
	export function getErrorMessage(error: Error | any, defaultErrorMessage?: string): string {

		const re = /^\[\d{3}\]\s/;

		if (error.isAxiosError) {
			// Retrieve error messages from API responses here
			const response = error?.response?.data || '';
			return re.test(response.errorMessage) ? response.errorMessage.replace(re, '') : defaultErrorMessage;
		}

		// Trap error (or error-like) objects here
		if (error instanceof Error || error?.message !== undefined) {
			return error.message ? error.message.replace(re, '') : defaultErrorMessage;
		}

		// Trap error (or error-like) objects here
		if (error instanceof Error || error?.errorMessage !== undefined) {
			return error.errorMessage ? error.errorMessage.replace(re, '') : defaultErrorMessage;
		}

		return defaultErrorMessage;
	}
}
