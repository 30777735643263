<section class="hero is-large has-text-centered">
	<div class="hero-body">
		<p class="title">
			Site under maintenance
		</p>
		<p class="subtitle">
			Please check back soon
		</p>
	</div>
</section>
