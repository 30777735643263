export interface NamedEntity {
	id: string;
	name: string;
	[otherProps: string]: any;
}

export interface LogoNamedEntity extends NamedEntity {
	logo: string;
}

export interface StatusNamedEntity extends NamedEntity {
	status: string;
}

export namespace NamedEntity {

	export function parse(src: any): NamedEntity {
		if (!src) {
			return undefined;
		}

		return <NamedEntity>{
			"id": src.id,
			"name": src.name
		};
	}

	export function toJSON(src: NamedEntity): any {
		if (!src?.id) {
			return undefined;
		}

		return {
			"id": src.id,
			"name": src.name
		};
	}

	export function clone(src: NamedEntity): NamedEntity {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"name": src.name
		};
	}

	export function blank(): NamedEntity {
		return {
			"id": undefined,
			"name": undefined
		};
	}

	export function equals(a: NamedEntity, b: NamedEntity): boolean {
		// Don't compare the name part, comparison of ID is sufficient
		return a.id === b.id;
	}
}

export namespace LogoNamedEntity {
	export function parse(src: any): LogoNamedEntity {
		if (!src) {
			return undefined;
		}

		const a = NamedEntity.parse(src);
		const b = {"logo": src.logo};

		return {...a, ...b};
	}

	export function toJSON(src: LogoNamedEntity): any {
		if (!src) {
			return undefined;
		}

		const a = NamedEntity.toJSON(src);
		const b = {"logo": src.logo};
		return {...a, ...b};
	}

	export function clone(src: LogoNamedEntity): LogoNamedEntity {
		if (!src) {
			return undefined;
		}

		const a = NamedEntity.clone(src);
		const b = {"logo": src.logo};
		return {...a, ...b};
	}

	export function blank(): LogoNamedEntity {
		return {
			"id": undefined,
			"name": undefined,
			"logo": undefined
		};
	}
}

export namespace StatusNamedEntity {
	export function parse(src: any): StatusNamedEntity {
		if (!src) {
			return undefined;
		}

		const a = NamedEntity.parse(src);
		const b = {"status": src.status};

		return {...a, ...b};
	}

	export function toJSON(src: StatusNamedEntity): any {
		if (!src) {
			return undefined;
		}

		const a = NamedEntity.toJSON(src);
		const b = {"status": src.status};
		return {...a, ...b};
	}

	export function clone(src: StatusNamedEntity): StatusNamedEntity {
		if (!src) {
			return undefined;
		}

		const a = NamedEntity.clone(src);
		const b = {"status": src.status};
		return {...a, ...b};
	}

	export function blank(): StatusNamedEntity {
		return {
			"id": undefined,
			"name": undefined,
			"status": undefined
		};
	}
}
