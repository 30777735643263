import * as libphonenumber from 'google-libphonenumber';
import {assert} from '@classes/errors';
import {DateTime} from 'luxon';
import {PalSpan, PalSpanElement} from '@modules/process/classes/process-item-constraints/types';

/**
 * The custom primary log level for the app. Set to error for production builds.
 */
const level: 'log'|'info'|'error'|'safeEval'|'palString'|'mapUtils'|'resultSet'|'reset'|'table'|'toggle'|'fullToggle'|'isComplete'
      |'trafficLights'|'trafficLightsAmount' = 'error';

/**
 * Container for a list of functions that help with typescript and/or Angular.js.
 */
export const GeneralUtils = {

  /**
   * Logs to the console, only if the log level is `'log'`
   */
  log: ['log'].includes(level) ? console.log : () => {},
  info: ['log', 'info'].includes(level) ? console.log : () => {},
  error: ['log', 'info', 'error', 'safeEval', 'mapUtils', 'palString', 'resultSet', 'reset', 'table', 'fullToggle'].includes(level) ?
    console.error : () => {},
  safeEval: ['log', 'safeEval', 'mapUtils'].includes(level) ? console.log : () => {},
  palString: ['log', 'palString', 'mapUtils'].includes(level) ? console.log : () => {},
  resultSet: ['log', 'resultSet'].includes(level) ? console.log : () => {},
  reset: ['log', 'reset'].includes(level) ? console.log : () => {},
  toggle: ['log', 'toggle'].includes(level) ? console.log : () => {},
  fullToggle: ['log', 'toggle', 'fullToggle'].includes(level) ? console.log : () => {},
  isComplete: ['log', 'isComplete'].includes(level) ? console.log : () => {},

  /**
   * Used to compare and validate traffic light representations of data within the app
   */
  trafficLights: [
    'log',
    'trafficLights'
  ].includes(level) ? console.log : () => {},

  /**
   * Used to compare and validate traffic light representations of data within the app, but only amount
   */
  trafficLightsAmount: [
    'log',
    'trafficLights',
    'trafficLightsAmount'
  ].includes(level) ? console.log : () => {},

  custom: (arr) => arr.includes(level) ? (console.log) : (() => {}),

  /**
   * Binds a list of functions to its owner so that it always interprets `this` as its owner
   *
   * @param input The owner of the function
   * @param args The functions to bind
   */
  bind: (input: any, ...args: Function[]) => {
    for (let arg of args) {
      const name: string = arg.name;
      input[name] = arg.bind(input);
    }
  },

  /**
   * Binds all functions of an object to it.
   *
   * <b><u>Usage</u></b>: Use this in the constructor of every single object.
   *
   * @param input The owner of the functions
   *
   * @see bind
   */
  bindAll: (input: any) => {
    const args: Function[] = Object.keys(input).filter(key => typeof (input)[key] === 'function').map(v => input[v]);
    GeneralUtils.bind(input, ...args);
  }

};

// tslint:disable-next-line:no-namespace
export namespace NumberUtils {

  export function parse(src: string|number): number {
    const n = Number(src);
    return Number.isNaN(n) ? undefined : n;
  }

  /**
   * Rounds a number to 2dp, suitable for comparing currency values and avoiding rounding errors.
   */
  export function currency(src: string|number): number {
    const n = NumberUtils.parse(src);
    if (n === undefined) {
      return undefined;
    }

    return Math.round(n * 100) / 100;
  }
}

export class StringUtils {

  public static readonly ellipsis = '…';

  public static sameString(a: string, b: string, allowNullAndUndefined: boolean = true): boolean {
    return !a && !b && allowNullAndUndefined ? true : a === b;
  }

  public static trim(value: string|null|undefined): string {
    return value?.trim() ?? value;
  }

  public static trimmedLength(value: string|null|undefined): number {
    return (value ?? '').trim().length;
  }

  public static truncateString(text: string, maxLength: number = 100): string {
    const content = text || '';
    return content.length > maxLength ? content.slice(0, maxLength).split(' ').slice(0, -1).join(' ') + StringUtils.ellipsis : content;
  }

  public static optionalString(value: string|undefined|null, defaultValue: string = ''): string {
    return value ?? defaultValue;
  }

  /**
   * Returns a string formatted as a 2dp number with commas and a leading $ sign. Suitable for error messages, etc
   */
  public static currency(value: string|number|undefined|null): string {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en-AU', {style: 'currency', currency: 'AUD'});
    const result = NumberUtils.parse(value);
    if (result !== undefined) {
      return formatter.format(result);
    } else {
      return undefined;
    }
  }

}

export class PhoneNumberUtils {

  private static readonly ignoreErrors: string[] = [
    `The string supplied is too long to be a phone number`,
    `The string supplied did not seem to be a phone number`
  ];

  public static isValid(potentialPhoneNumber: string): boolean {
    if (!potentialPhoneNumber) {
      return false;
    }

    try {

      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parseAndKeepRawInput(potentialPhoneNumber, 'AU');
      return phoneUtil.isValidNumber(phoneNumber);
    }
    catch (e) {
      if (!PhoneNumberUtils.ignoreErrors.includes(e.message)) {
        GeneralUtils.log(e);
      }
    }

    return false;
  }

  public static format(potentialPhoneNumber: string, mobileOnly?: boolean): string {

    if (!potentialPhoneNumber) {
      return '';
    }

    const input = `${potentialPhoneNumber}`.trim();

    try {

      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(input, 'AU');

      if (phoneUtil.isValidNumber(parsedPhoneNumber)) {
        if (!mobileOnly) {
          return phoneUtil.format(parsedPhoneNumber, libphonenumber.PhoneNumberFormat.NATIONAL);
        }
        else if (phoneUtil.getNumberType(parsedPhoneNumber) === libphonenumber.PhoneNumberType.MOBILE) {
          return phoneUtil.format(parsedPhoneNumber, libphonenumber.PhoneNumberFormat.NATIONAL);
        }
      }
    }
    catch (e) {
      if (!PhoneNumberUtils.ignoreErrors.includes(e.message)) {
        GeneralUtils.log(e);
      }
    }

    return '';
  }

  public static e164(potentialPhoneNumber: string): string {
    const input = `${potentialPhoneNumber}`.trim();

    if (input === '') {
      return undefined;
    }

    try {

      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const parsedPhoneNumber = phoneUtil.parse(input, 'AU');

      if ( phoneUtil.isValidNumber(parsedPhoneNumber) ) {
        return phoneUtil.format(parsedPhoneNumber, libphonenumber.PhoneNumberFormat.E164);
      }
    }
    catch (e) {
      if (!PhoneNumberUtils.ignoreErrors.includes(e.message)) {
        GeneralUtils.error(e);
      }
    }
  }


  public static parse(potentialPhoneNumber: string): string {
    if (!potentialPhoneNumber) {
      return '';
    }

    try {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parseAndKeepRawInput(potentialPhoneNumber, 'AU');
      return phoneUtil.isValidNumber(phoneNumber) ? phoneUtil.format(phoneNumber, libphonenumber.PhoneNumberFormat.E164) :
        potentialPhoneNumber;
    }
    catch (e) {
      return '';
    }
  }
}

export class DateUtils {

  /**
   * Creates a copy of a date object
   */
  public static clone(src: Date): Date|undefined {
    if (!src) {
      return undefined;
    }

    return new Date(src.valueOf());
  }

  public static equals(a: Date, b: Date): boolean {
    if ((!a && !!b) || (!!a && !b)) {
      return false;
    }

    return a.valueOf() === b.valueOf();
  }

  /**
   * Parses a string, and returns a data object truncated to the start of the day.
   * Returns undefined if the date string is not valid.
   */
  public static parse(src: string, includeTime?: boolean): Date|undefined {
    try {
      const d = DateTime.fromISO(src);
      if (d.isValid) {
        return includeTime ? d.toJSDate() : d.startOf('day').toJSDate();
      }
      return undefined;
    }
    catch (e) {
      return undefined;
    }
  }

  /**
   * Converts a Date object to a string.
   * If no format argument is provided, the result is an ISO date string (yyyy-mm-dd)
   */
  public static toString(src: Date, format?: string): string {
    if (!src) {
      return undefined;
    }

    const dateTime = DateTime.fromJSDate(src);
    return format ? dateTime.toFormat(format) : dateTime.toISODate();
  }

  public static toLocaleString(src: Date, formatOpts?: any): string {
  if (!src) {
    return undefined;
  }

  const dateTime = DateTime.fromJSDate(src);
  return formatOpts ? dateTime.toLocaleString(formatOpts) : dateTime.toLocaleString(DateTime.DATE_SHORT);
  }

  /**
   * Converts a Date object to a string, including the time component.
   */
  public static toISOString(src: Date): string {
    if (!src) {
      return undefined;
    }

    const dateTime = DateTime.fromJSDate(src);
    return dateTime.toISO();
  }

  /**
   * Compare dates.
   * https://moment.github.io/luxon/docs/class/src/datetime.js~DateTime.html#instance-method-diff
   */
  public static diff(d1: Date, d2: Date): number {
    if (!d1 || !d2) {
      return undefined;
    }

    const i1 = DateTime.fromJSDate(d1);
    const i2 = DateTime.fromJSDate(d2);
    return i2.diff(i1).toObject();
  }

}

// tslint:disable-next-line:no-namespace
export namespace UUIDUtils {
  export function isUUID(value: string): boolean {
    const re = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return re.test(value);
  }
}

export enum Key {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Escape = 27,
  ArrowLeft = 37,
  ArrowRight = 39,
  ArrowUp = 38,
  ArrowDown = 40,
  Comma = 188
}

export class FloatingPointUtils {

  public static readonly defaultPrecision = 0.01;

  // tslint:disable-next-line:typedef
  private static validatePrecision(precision: number) {
    assert( Math.log10(precision) === Math.round(Math.log10(precision)),  `Precision must be a power of 10` );
  }

  public static equals(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    FloatingPointUtils.validatePrecision(precision);
    return Math.round(a / precision) === Math.round(b / precision);
  }

  public static lessThan(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    FloatingPointUtils.validatePrecision(precision);
    return Math.round(a / precision) < Math.round(b / precision);
  }

  public static lessThanOrEqualTo(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    FloatingPointUtils.validatePrecision(precision);
    return Math.round(a / precision) <= Math.round(b / precision);
  }

  public static greaterThan(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    return FloatingPointUtils.lessThan(b, a, precision);
  }

  public static greaterThanOrEqualTo(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
    return FloatingPointUtils.lessThanOrEqualTo(b, a, precision);
  }
}

export class TextUtils {

  public static stringFormat(template: string, ...args: any[]): string {
    return template.replace(/{(\d+)}/g, (match, d: any) => {
      return typeof args[d] !== 'undefined'
        ? args[d]
        : match
        ;
    });
  }

  public static isBlank(value: string | null): boolean {
    return !!value;
  }

  public static capitalize(input: string): string {
    const arr = input.split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  }

 /**
  * Instantaneous replace algorithm
  * @param oldString The string that needs replacing
  * @param entries The entry list
  */
 public static smartReplace(oldString: string, entries: {old: string, new: string}[]): string {
   GeneralUtils.palString(`smartReplace ${oldString} ${JSON.stringify(entries)}`);
   if (oldString == null) {
     return null;
   }

  const encode = (arr: string | any[], depth: number, old: string) => {
   if (depth === 0 && typeof arr === 'string') {
    return arr.split(old);
   } else if (arr instanceof Array){
    return arr.map(v => encode(v, depth - 1, old));
   } else {
    return '';
   }
  };

  const decode = (arr: any[], depth: number, ne: string) => {
   if (depth === 0) {
    return arr.join(ne);
   } else {
    return arr.map(v => decode(v, depth - 1, ne));
   }
  };

  let current: string | any[] = oldString;
  // tslint:disable-next-line:forin
  for (const depth in entries) {
   const old = entries[depth].old;
   current = encode(current, parseInt(depth, 10), old);
  }

  // tslint:disable-next-line:forin
  for (const rev in entries) {
   const depth = entries.length - parseInt(rev, 10) - 1;
   const ne = entries[depth].new;
   if (current instanceof Array) {
    current = decode(current, depth, ne);
   }
  }

  if (typeof current === 'string') {
   GeneralUtils.log(`smartReplace ${current}`);
   return current;
  } else {
   GeneralUtils.log(`smartReplace`);
   return '';
  }
 }

}

export default class EvalUtils {

  public static parse(literal: string): number | string | null {
    if (literal === `null`) {
      return null;
    } else if (literal === `true`) {
      return 1;
    } else if (literal === `false`) {
      return 0;
    } else if (/^\s*'[^']*'\s*$/.test(literal)) {
      return literal.match(/^\s*'([^']*)'\s*$/)[1];
    } else {
      return parseFloat(literal);
    }
  }

  public static or(lhs: any, rhs: any): 0|1 {
    // tslint:disable-next-line:triple-equals
    return (lhs || rhs) ? 1 : 0;
  }

  public static and(lhs: any, rhs: any): 0|1 {
    // tslint:disable-next-line:triple-equals
    return (lhs && rhs) ? 1 : 0;
  }

  public static eq(lhs: any, rhs: any): 0|1 {
    // tslint:disable-next-line:triple-equals
    const result = (lhs == rhs || (typeof lhs == 'number' && typeof rhs == 'number' && isNaN(lhs) && isNaN(rhs))) ? 1 : 0;
    GeneralUtils.log(`eq ${lhs} ${rhs} ${result}`);
    return result;
  }

  public static neq(lhs: any, rhs: any): 0|1 {
    // console.log(`neq ${lhs} ${rhs}`);
    return EvalUtils.eq(lhs, rhs) ? 0 : 1;
  }

  /**
   * Evaluates a mathematical expression in this order /,*,-,+,!=,==,||,&&
   * @param expr The expression to evaluate
   */
  public static safeEval(expr: string): number | string {
    // console.log(`safeEval: ${expr}`);
    // if (!new RegExp('[0-9.+*/\s]*').test(expr)) {
    //   throw new Error(`Cannot evaluate expression ${expr}`);
    // }

    const tree = expr.split(/[\s]*\|\|[\s]*/)
      .map(a1 => a1.split(/[\s]*&&[\s]+/)
        .map(a2 => a2.split(/[\s]*==[\s]+/)
          .map(a3 => a3.split(/[\s]*!=[\s]+/)
            .map(a4 => a4.split(/[\s]*\+[\s]+/)
              .map(a5 => a5.split(/[\s]*-[\s]+/)
                .map(a6 => a6.split(/[\s]*\*[\s]*/)
                  .map(a7 => a7.split(/[\s]*\/[\s]*/))))))));
    const e0 = tree.map(a1 => {
      const e1 = a1.map(a2 => {
        const e2 = a2.map(a3 => {
          const e3 = a3.map(a4 => {
            const e4 = a4.map(a5 => {
              const e5 = a5.map(a6 => {
                const e6 = a6.map(a7 => {
                  let sum7: string | number = 0;
                  for (const item7 of a7) {
                    const num7 = EvalUtils.parse(item7);
                    if (sum7 === 0) {
                      sum7 = num7;
                    } else {
                      sum7 = parseFloat(`${sum7}`) / parseFloat(`${num7}`);
                    }
                  }
                  return sum7;
                });
                let sum6: string | number = 0;
                for (const num6 of e6) {
                  if (sum6 === 0) {
                    sum6 = num6;
                  } else {
                    sum6 = parseFloat(`${sum6}`) * parseFloat(`${num6}`);
                  }
                }
                return sum6;
              });
              let sum5: string | number = NaN;
              for (const num5 of e5) {
                if (typeof sum5 === 'number' && isNaN(sum5)) {
                  sum5 = num5;
                } else {
                  sum5 = parseFloat(`${sum5}`) * parseFloat(`${num5}`);
                }
              }
              return sum5;
            });
            let sum4: string | number| null = null;
            for (const num4 of e4) {
              if (sum4 === null) {
                sum4 = num4;
              } else {
                sum4 = parseFloat(`${sum4}`) + parseFloat(`${num4}`);
              }
            }
            return sum4;
          });
          let sum3: string | number | null = null;
          for (const num3 of e3) {
            if (sum3 === null) {
              sum3 = num3;
            } else {
              // tslint:disable-next-line:triple-equals
              sum3 = EvalUtils.neq(sum3, num3);
            }
          }
          return sum3;
        });
        let sum2: string | number = NaN;
        for (const num2 of e2) {
          if (typeof sum2 === 'number' && isNaN(sum2)) {
            sum2 = num2;
          } else {
            // tslint:disable-next-line:triple-equals
            sum2 = EvalUtils.eq(sum2, num2);
          }
        }
        return sum2;
      });
      let sum1: string | number = NaN;
      for (const num1 of e1) {
        if (typeof sum1 === 'number' && isNaN(sum1)) {
          sum1 = num1;
        } else {
          // tslint:disable-next-line:triple-equals
          sum1 = EvalUtils.and(sum1, num1);
        }
      }
      return sum1;
    });
    let sum0: string | number = NaN;
    for (const num0 of e0) {
      if (typeof sum0 === 'number' && isNaN(sum0)) {
        sum0 = num0;
      } else {
        // tslint:disable-next-line:triple-equals
        sum0 = EvalUtils.or(sum0, num0);
      }
    }
    GeneralUtils.safeEval(`safeEval result: ${expr} -> ${sum0}`);
    return sum0;
  }
}

export class EqualityUtils {

  static equals(lhs: any, rhs: any): boolean {
    if (lhs instanceof Array) {
      return (rhs instanceof Array) && (rhs.length === lhs.length) && lhs.every((v, i) => EqualityUtils.equals(v, rhs[i]));
    }
    if (lhs && lhs.hasOwnProperty('value')) {
      // tslint:disable-next-line:no-string-literal
      return (rhs && rhs.hasOwnProperty('value')) && EqualityUtils.equals(lhs['value'], rhs['value']);
    }
    return lhs === rhs;
  }

}

export class MapUtils {

  /**
   * Returns the values of arguments, from a set of value, key pairs, in the order the arguments were provided.
   *
   * @param argsList A 2D array of argument names. For each argument name, the first index is the depth of the argument, which determines
   * how many index path indices will be used to extract the argument from the {@link valueMap}.
   * @param valueMap The map to get the values from
   * @param indexPath The index path to get values from if they are nested inside a multi dimensional array
   */
  static getArgMap(argsList: string[][], valueMap: {}, indexPath: number[] = []): {name: string, value: any}[] {
    GeneralUtils.log(`getArgMap ${argsList} ${indexPath}`);
    const argValues = [];
    // tslint:disable-next-line:forin
    for (const idx in argsList) {
      const depth = (parseInt(idx, 10)); // The current depth
      const argList = argsList[idx]; // A list of args at the current depth
      for (const arg of argList) {
        const value = MapUtils.evaluate(arg, valueMap, depth, indexPath);
        if (/\?/.test(arg) || value !== undefined) {
          argValues.push({name: arg, value});
        }
      }
    }
    return argValues;
  }

  /**
   * Returns the values of arguments, from a set of value, key pairs, in the order the arguments were provided.
   *
   * @param argsList A 2D array of argument names. For each argument name, the first index is the depth of the argument, which determines
   * how many index path indices will be used to extract the argument from the {@link valueMap}.
   * @param valueMap The map to get the values from
   * @param indexPath The index path to get values from if they are nested inside a multi dimensional array
   */
  static getArgObject(argsList: string[][], valueMap: {}, indexPath: number[] = []): {}[] {
    GeneralUtils.log(`getArgMap ${argsList} ${indexPath}`);
    const argValues = [];
    // tslint:disable-next-line:forin
    for (const idx in argsList) {
      const depth = (parseInt(idx, 10)); // The current depth
      const argList = argsList[idx]; // A list of args at the current depth
      for (const arg of argList) {
        const value = MapUtils.evaluate(arg, valueMap, depth, indexPath);
        if (/\?/.test(arg) || value !== undefined) {
          argValues.push({name: arg, value});
        }
      }
    }
    return argValues;
  }

  static getFlatArgs(argsList: string[][]): string[] {
    return argsList.reduce((a, b) => [...a, ...b], []);
  }

  /**
   * Returns the values of arguments, from a set of value, key pairs, in the order the arguments were provided.
   *
   * @param argsList A 2D array of argument names. For each argument name, the first index is the depth of the argument, which determines
   * how many index path indices will be used to extract the argument from the {@link valueMap}.
   * @param valueMap The map to get the values from
   * @param indexPath The index path to get values from if they are nested inside a multi dimensional array
   */
  static getArgValues(argsList: string[][], valueMap: {}, indexPath: number[] = []): any[] {
    GeneralUtils.log(`getArgValues ${argsList} ${indexPath}`);
    const argValues = [];
    // tslint:disable-next-line:forin
    for (const idx in argsList) {
      const depth = (parseInt(idx, 10)); // The current depth
      const argList = argsList[idx]; // A list of args at the current depth
      for (const arg of argList) {
        const value = MapUtils.evaluate(arg, valueMap, depth, indexPath);
        if (value !== undefined) {
          argValues.push(value);
        }
      }
    }
    return argValues;
  }

  /**
   * Performs a PAL assignment to a value map.
   *
   * @deprecated TODO Incomplete
   *
   * @param expr The left hand side of the assignment expression.
   * @param valueMap The value map to assign to.
   * @param arrayDims A list of the counts of all ancestors of this object (and the object itself)
   * @param depth The length of {@link arrayDims} and {@link indexPath}
   * @param indexPath The index path from the context to the object.
   * @param newValue The right hand side of the assignment expression.
   */
  static set(expr: string, valueMap: {}, arrayDims: number[], depth: number = 0, indexPath: number[] = [], newValue: any): void {
    GeneralUtils.log(`evaluate ${expr} ${depth} ${indexPath}`);
    let nContextValueMap;
    const contextValueMap = valueMap;
    let param0;
    let funs;
    const splits = expr.split('.');
    if (splits.length === 0) {
      throw new Error(`Cannot resolve empty expression ${expr}`);
    }
    if (splits[0].includes('$')) { // This is a global context
      if (splits.length === 1) {
        throw new Error(`Cannot resolve expression ${expr}`);
      }
      const contextName = splits[0];
      nContextValueMap = contextValueMap[contextName];
      if (!nContextValueMap) {
        contextValueMap[contextName] = {};
        nContextValueMap = contextValueMap[contextName];
      }
      param0 = splits[1];
      funs = [...splits.splice(2)];
    } else {
      param0 = splits[0];
      funs = [...splits.splice(1)];
    }
    /**
     * The array or object that needs to be inserted into
     */
    let value = nContextValueMap[param0];
    if (depth > 0 && (!(value instanceof Array) || value[indexPath[0]].length !== arrayDims[0])) {
      nContextValueMap[param0] = new Array(arrayDims[0]).fill(null);
      value = nContextValueMap[param0];
    }

    for (let i = 0; i < depth; i++) {
      value = value[indexPath[i]];
      if (i !== depth - 1) {
        if (!(value[indexPath[i + 1]] instanceof Array) || value[indexPath[i + 1]].length !== arrayDims[i + 1]) {
          value[indexPath[i + 1]] = new Array(arrayDims[i + 1]).fill(null);
        }
      }
    }
    if (funs.length === 0) {
      if (depth === 0) {
        nContextValueMap[param0] = newValue;
      } else {
        value[indexPath[depth - 1]] = newValue;
      }
    } else {
      // tslint:disable-next-line:forin
      for (const funIdx in funs) {
        const fun = funs[funIdx];
        if (parseInt(funIdx, 10) === funs.length - 1) {
          value[fun] = newValue;
        }
        if (value instanceof Array) {
          // If at this point we have an array, we need to use param1 to extract the params from it.
          value = value.map(v => v != null && v.hasOwnProperty(fun) ? v[fun] : v);
        } else {
          // Otherwise we have an object or literal, we need to use param1 to extract the param from it.
          value = value != null && value.hasOwnProperty(fun) ? value[fun] : value;
        }
      }
    }

    GeneralUtils.log(`evaluate = ${value}`);
  }

  /**
   * Interprets Pal Strings
   *
   * @param expr The Pal String to interpret
   * @param depth The depth of the context
   * @param valueMap The value map to read from
   * @param indexPath The index path of the context
   */
  static evaluate(expr: string, valueMap: {}, depth: number = 0, indexPath: number[] = []): any {

    // Determine if the arg is optional
    const optional = /\?/.test(expr);
    const key = expr.replace('?', '');

    GeneralUtils.log(`evaluate ${key} ${optional} ${depth} ${indexPath} ${JSON.stringify(valueMap)}`);
    let contextValueMap = valueMap;
    let param0;
    let funs;
    const splits = key.split('.');
    if (splits.length === 0) {
      if (!optional) { throw new Error(`Cannot resolve empty expression ${expr}`); } else { return null; }
    }
    if (splits[0].includes('$')) { // This is a global context
      if (splits.length === 1) {
        if (!optional) { throw new Error(`Cannot resolve expression ${expr}`); } else { return null; }
      }
      const contextName = splits[0];
      contextValueMap = contextValueMap[contextName];
      if (!contextValueMap) {
        if (!optional) { throw new Error(`Invalid global context: ${contextName}`); } else { return null; }
      }
      param0 = splits[1];
      funs = [...splits.splice(2), 'value'];
    } else {
      param0 = splits[0];
      funs = [...splits.splice(1), 'value'];
    }
    let value = contextValueMap[param0];
    for (let i = 0; i < depth; i++) {
      if (value instanceof Array) {
        value = value[indexPath[i]]; // Hopefully this is an instance of array at this point
      }
    }
    for (const fun of funs) {
      // Check for valid functions
      switch (fun) {
        case 'len()':
          if (value instanceof Array) {
            value = value.length;
          } else if (value == null) {
            // In a lot of instances null signifies an empty array, so null.len() will be legal and equals 0.
            value = 0;
          } else {
            if (!optional) { throw new Error(`Cannot resolve function len() on ${JSON.stringify(value)}`); } else { return null; }
          }
          break;
        case 'sum()':
          if (value instanceof Array) {
            value = value.reduce((s, a) => s + a, 0);
          } else if (value == null) {
            // In a lot of instances null signifies an empty array, so null.sum() will be legal and equals 0.
            value = 0;
          } else {
            if (!optional) { throw new Error(`Cannot resolve function sum() on ${JSON.stringify(value)}`); } else { return null; }
          }
          break;
        default:
          if (value instanceof Array) {
            // If at this point we have an array, we need to use param1 to extract the params from it.
            value = value.map(v => v != null && v.hasOwnProperty(fun) ? v[fun] : v);
          } else {
            // Otherwise we have an object or literal, we need to use param1 to extract the param from it.
            value = value != null && value.hasOwnProperty(fun) ? value[fun] : value;
          }
      }
    }
    GeneralUtils.log(`evaluate = ${value}`);

    // If the value is undefined at this point but is optional, return null instead
    if (optional && value === undefined) { return null; }

    return value;
  }

  static getPalString(text: string, argsList: string[][], valueMap: {}, depth: number = 0, indexPath: number[] = []): string {
    GeneralUtils.palString(`getPalString ${text} ${JSON.stringify(argsList)} ${indexPath}`);
    const argMap = MapUtils.getArgMap(argsList, valueMap, indexPath);
    GeneralUtils.palString(`getPalString argMap ${JSON.stringify(argMap)}`);
    const value =  TextUtils.smartReplace(text, argMap.map(v => ({old: `{{${v.name}}}`, new: v.value})));
    GeneralUtils.palString(`getPalString result ${text} -> ${value}`);
    return value;
  }

  static getReport(span: PalSpan, valueMap: {}, depth: number = 0, indexPath: number[] = [], strict = false): string {
    let result = '';

    const split = (splitCount: number, element: PalSpanElement) => {
      if (element.hasOwnProperty('text')) {
        // tslint:disable-next-line:no-string-literal
        const textString = element['text'] as string;
        for (let i = 0; i < splitCount; i++) {
          result += MapUtils.getPalString(textString, element.argsList, valueMap, depth + 1, [...indexPath, i]);
        }
      } else {
        // tslint:disable-next-line:no-string-literal
        const palSpan = element['span'] as PalSpan;
        for (let i = 0; i < splitCount; i++) {
          result += MapUtils.getReport(palSpan, valueMap, depth + 1, [...indexPath, i]);
        }
      }
    };

    for (const element of span) {
      GeneralUtils.log(`Element: ${JSON.stringify(element)}`);
      if (element.hasOwnProperty('splitCount')) {
        // tslint:disable-next-line:no-string-literal
        const elementAsString = element['splitCount'] as string;
        let splitCount = MapUtils.evaluate(elementAsString, valueMap, depth, indexPath);
        if (typeof splitCount !== 'number' || !Number.isFinite(splitCount) || splitCount < 0) {
          if (strict) {
            throw new Error(`Invalid split count in report: ${JSON.stringify(splitCount)}`);
          } else {
            splitCount = 0;
          }
        }
        split(splitCount, element);
      } else if (element.hasOwnProperty('splitOn')) {
        // tslint:disable-next-line:no-string-literal
        const elementAsString = element['splitOn'] as string;
        let splitOn = MapUtils.evaluate(elementAsString, valueMap, depth, indexPath);
        if (!(splitOn instanceof Array)) {
          if (strict) {
            throw new Error(`Cannot split on non-array in report: ${JSON.stringify(splitOn)}`);
          }
          else {
            splitOn = [];
          }
        }
        const splitCount = splitOn.length;
        split(splitCount, element);
      } else {
        if (element.hasOwnProperty('text')) {
          // tslint:disable-next-line:no-string-literal
          const textString = element['text'] as string;
          result += MapUtils.getPalString(textString, element.argsList, valueMap, depth, indexPath);
        } else {
          // tslint:disable-next-line:no-string-literal
          const palSpan = element['span'] as PalSpan;
          result += MapUtils.getReport(palSpan, valueMap, depth, indexPath);
        }
      }
      result += '';
    }
    return result;
  }

}
