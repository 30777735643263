import { AttachedFile } from './files';
import { DateUtils, StringUtils } from "@classes/utils";
import { EmailPrefs } from '@classes/user';
import { Entity } from "@classes/entities";
import { Client } from "@classes/user";

export interface UserRelationship extends Entity {
	userId: string;
	clientId: string;
	validFrom?: Date;
	validTo?: Date;
	description: string;
	notes: string;
	attachments: AttachedFile[];
	settings: any;
	emailPrefs: EmailPrefs;
	hasBankDetails: boolean; // Used for identifying suitable reimbursement candidates in invoice entry
	isLAC: boolean;
	isSupportCoordinator: boolean;
	isReferrer: boolean;
	isNominee: boolean;
	authorised: boolean;
	organisation: string;
	// Non standard/imported attributes - for listing display
	name: string;
	phone: string;
	mobile: string;
	email: string;
}

export namespace UserRelationship {

	export function blank(clientId?: string): UserRelationship {
		return {
			"id": undefined,
			"userId": undefined,
			"clientId": clientId,
			"authorised": false,
			"validFrom": undefined,
			"validTo": undefined,
			"description": undefined,
			"notes": undefined,
			"attachments": [],
			"settings": undefined,
			"emailPrefs": EmailPrefs.blank(),
			"hasBankDetails": undefined,
			"isLAC": undefined,
			"isSupportCoordinator": undefined,
			"isReferrer": undefined,
			"isNominee": undefined,
			"organisation": undefined,
			"name": undefined,
			"phone": undefined,
			"mobile": undefined,
			"email": undefined
		};
	}

	export function linkToSelf(client: Client): UserRelationship {
		return {
			"id": undefined,
			"userId": client.id,
			"clientId": client.id,
			"authorised": false,
			"validFrom": undefined,
			"validTo": undefined,
			"description": "Client",
			"notes": undefined,
			"attachments": [],
			"settings": undefined,
			"emailPrefs": EmailPrefs.blank(),
			"hasBankDetails": client.hasBankDetails,
			"isLAC": false,
			"isSupportCoordinator": false,
			"isReferrer": false,
			"isNominee": false,
			"organisation": undefined,
			"name": `${client.firstName} ${client.lastName}`,
			"phone": client.phone,
			"mobile": client.mobile,
			"email": client.email
		};
	}

	export function parse(src: any): UserRelationship {
		return {
			"id": src.id,
			"userId": src.userId,
			"clientId": src.clientId,
			"authorised": !!src.authorised,
			"validFrom": DateUtils.parse(src.validFrom),
			"validTo": DateUtils.parse(src.validTo),
			"description": src.description,
			"notes": src.notes,
			"attachments": (src.attachments || []).map( props => AttachedFile.parse(props) ),
			"settings": src.settings,
			"hasBankDetails": src.hasBankDetails,
			"emailPrefs": EmailPrefs.parse(src.emailPrefs),
			"isLAC": src.isLAC,
			"isSupportCoordinator": src.isSupportCoordinator,
			"isReferrer": src.isReferrer,
			"isNominee": src.isNominee,
			"organisation": src.organisation,
			"name": src.name,
			"phone": src.phone,
			"mobile": src.mobile,
			"email": src.email
		};
	}

	export function toJSON(src: UserRelationship): any {
		return {
			"id": src.id,
			"userId": src.userId,
			"clientId": src.clientId,
			"authorised": src.authorised,
			"validFrom": DateUtils.toString(src.validFrom),
			"validTo": DateUtils.toString(src.validTo),
			"description": StringUtils.trim(src.description) || undefined,
			"notes": StringUtils.trim(src.notes) || undefined,
			"attachments": (src.attachments || []).map( AttachedFile.toJSON ),
			"settings": src.settings || undefined,
			"emailPrefs": EmailPrefs.toJSON(src.emailPrefs),
			"hasBankDetails": src.hasBankDetails,
			"isLAC": src.isLAC,
			"isSupportCoordinator": src.isSupportCoordinator,
			"isReferrer": src.isReferrer,
			"isNominee": src.isNominee,
			"organisation": src.organisation || undefined,
			"name": src.name || undefined,
			"phone": src.phone || undefined,
			"mobile": src.mobile || undefined,
			"email": src.email || undefined
		};
	}

	export function clone(src: UserRelationship): UserRelationship {
		let result = {...src};
		result.emailPrefs = EmailPrefs.clone(src.emailPrefs);
		result.validFrom = DateUtils.clone(src.validFrom);
		result.validTo = DateUtils.clone(src.validTo);
		result.attachments = (src.attachments || []).map( AttachedFile.clone );
		return result;
	}
}
