import { User } from "@classes/user";
import { Plan } from "@classes/plans";
import { Invoice } from "@classes/invoices";
import { Provider } from "@classes/provider";
import { FileNote } from "@classes/filenotes";
import { UserRelationship } from "@classes/linkedUser";

export interface DeleteRecord {
	delete: string;
}

export type BroadcastMessageType = "delete" | "user" | "provider" | "invoice" | "plan" | "filenote" | "linked_user" | "maintenance" | "expireCache";

type BroadcastEntityType = User | Plan | FileNote | Provider | DeleteRecord | Invoice | UserRelationship;

export abstract class BroadcastMessage {
	public constructor(public data: BroadcastEntityType) {}

	public static from(entityType: BroadcastMessageType, data: BroadcastEntityType): BroadcastMessage {
		switch (entityType) {
			case 'maintenance': return new MaintenanceMessage(data);
			case 'delete': return new DeleteMessage(<DeleteRecord>data);
			case 'user': return new UserBroadcastMessage(<User>data);
			case 'plan': return new PlanBroadcastMessage(<Plan>data);
			case 'filenote': return new FileNoteBroadcastMessage(<FileNote>data);
			case 'provider': return new ProviderBroadcastMessage(<Provider>data);
			case 'invoice': return new InvoiceBroadcastMessage(<Invoice>data);
			case 'linked_user': return new RelationshipBroadcastMessage(<UserRelationship>data);
			case 'expireCache': return new ExpireCacheMessage(data);
			default: return undefined;
		}
	}
}

export class MaintenanceMessage extends BroadcastMessage {
	public get enabled(): boolean {
		return this.data['enabled'] === true;
	}
}

export class ExpireCacheMessage extends BroadcastMessage {
	public get cache(): string {
		return this.data['cache'];
	}
}

export class DeleteMessage extends BroadcastMessage {
	public get entityId(): string {
		return this.data['delete'];
	}
}

export class UserBroadcastMessage extends BroadcastMessage {
	public get user(): User {
		return <User>this.data;
	}
}

export class PlanBroadcastMessage extends BroadcastMessage {
	public get plan(): Plan {
		return <Plan>this.data;
	}
}

export class FileNoteBroadcastMessage extends BroadcastMessage {
	public get filenote(): FileNote {
		return <FileNote>this.data;
	}
}

export class ProviderBroadcastMessage extends BroadcastMessage {
	public get provider(): Provider {
		return <Provider>this.data;
	}
}

export class InvoiceBroadcastMessage extends BroadcastMessage {
	public get invoice(): Invoice {
		return <Invoice>this.data;
	}
}

export class RelationshipBroadcastMessage extends BroadcastMessage {
	public get userRelationship(): UserRelationship {
		return <UserRelationship>this.data;
	}
}

export class WebsocketState {
	public static isOpen: boolean = false;
}
