import { EnumMetadata } from "@classes/enumUtils";

export enum LineItemStatus {
	draft, submitted, rejected, discrepancy, reconciled, locked, paid, error, cancelled, deleted
}

export namespace LineItemStatus {

	const enumData: EnumMetadata<LineItemStatus>[] = [
		[LineItemStatus.draft,       "Draft",       "draft"],
		[LineItemStatus.submitted,   "Submitted",   "submitted"],
		[LineItemStatus.rejected,    "Rejected",    "rejected"],
		[LineItemStatus.discrepancy, "Discrepancy", "discrepancy"],
		[LineItemStatus.reconciled,  "Reconciled",  "reconciled"],
		[LineItemStatus.locked,      "Locked",      "locked"],
		[LineItemStatus.paid,        "Paid",        "paid"],
		[LineItemStatus.error,       "Error",       "error"],
		[LineItemStatus.cancelled,   "Cancelled",   "cancelled"],
		[LineItemStatus.deleted,     "Deleted",     "deleted"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): LineItemStatus {
		return values.get(value);
	}

	export function toString(value: LineItemStatus): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: LineItemStatus): string {
		return pgEnums.get(value);
	}

	export function allValues(): LineItemStatus[] {
		return enumData.map( item => item[0] );
	}

	const activeStates: LineItemStatus[] = [LineItemStatus.draft, LineItemStatus.locked, LineItemStatus.submitted, LineItemStatus.discrepancy, LineItemStatus.error];
	const finalisedStates: LineItemStatus[] = [LineItemStatus.rejected, LineItemStatus.reconciled, LineItemStatus.paid, LineItemStatus.cancelled, LineItemStatus.deleted];
	const removedStates: LineItemStatus[] = [LineItemStatus.rejected, LineItemStatus.cancelled, LineItemStatus.deleted];
	const errorStates: LineItemStatus[] = [LineItemStatus.error, LineItemStatus.discrepancy];

	export function isError(status: LineItemStatus): boolean {
		return errorStates.includes(status);
	}

	export function isActive(status: LineItemStatus): boolean {
		return activeStates.includes(status);
	}

	export function isFinalised(status: LineItemStatus): boolean {
		return finalisedStates.includes(status);
	}

	export function isRemoved(status: LineItemStatus): boolean {
		return removedStates.includes(status);
	}
}

