import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { Subscription } from "rxjs";
import { filter, first } from "rxjs/operators";
import { User, Admin } from "@classes/user";
import { Permission, Permissions } from "@classes/permissions";

/**
* Angular route guard to prevent loading of modules unless the user is logged in, and an administrator or support coordinator.
* If the user is an administrator, they must also be granted any permissions that may be required for the route.
*
* Permissions are specified on the route's "data" property from the router module.
*/
@Injectable()
export class MustBeLoggedInToLoad implements CanLoad {

	constructor(private authService: AuthService, private router: Router) {}

	canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {

			// Subscribe to the "userLoaded" observable on the authService.
			// Filter out the initial value (undefined)
			// Use the "first()" operator to automatically unsubsribe once a value has been received.
			this.authService.userLoaded$.pipe(filter(x => x !== undefined), first()).subscribe( userLoaded => {

				// If you're not logged in, you can't load the module
				if (!userLoaded) {
					this.router.navigate(['/login']);
					return resolve(false);
				}

				return resolve(true);
			});

		});
	}
}
