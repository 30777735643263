import { EnumMetadata } from "@classes/enumUtils";
import { Region } from "@classes/regions";
import { DateUtils } from "@classes/utils";
import { UnitOfMeasure } from "@classes/unitOfMeasure";

export interface SupportCategory {
	id: number;
	name: string;
	paceName: string;
}

export namespace SupportCategory {
	export function parse(src: any): SupportCategory {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"name": src.name,
			"paceName": src.paceName
		};
	}

	export function toJSON(src: SupportCategory): any {
		return {
			"id": src?.id,
			"name": src?.name,
			"paceName": src?.paceName
		};
	}

	export function clone(src: SupportCategory): SupportCategory {
		return !!src ? {...src} : undefined;
	}
}

export interface BasicSupportItem {
	supportItemNumber: string;
	name: string;
	description?: string;
	priceLimit?: number;
	priceControl: boolean;
}

export namespace BasicSupportItem {
	export function parse(src: any): BasicSupportItem {
		if (!src) {
			return undefined;
		}

		return {
			"supportItemNumber": src.supportItemNumber,
			"name": src.name,
			"description": src.description,
			"priceLimit": src.priceLimit,
			"priceControl": !!src.priceControl
		}
	}

	export function toJSON(src: BasicSupportItem): any {
		if (!src) {
			return undefined;
		}

		return Object.assign({}, src);
	}

	export function clone(src: BasicSupportItem): BasicSupportItem {
		if (!src) {
			return undefined;
		}

		return Object.assign({}, src);
	}
}

export interface SupportItem extends BasicSupportItem {
	id: string;
	supportCategory: SupportCategory;
	registrationGroup?: string;
	unitOfMeasure: UnitOfMeasure;
	quote: boolean;
	region: Region;
	dateFrom: Date;
	dateTo: Date;
	validDays: number[];
	paceSupportCategory: SupportCategory;
}

export namespace SupportItem {
	export function parse(src: any): SupportItem {
		if (!src) {
			return undefined;
		}

		const a = BasicSupportItem.parse(src);
		const b = {
			"id": src.id,
			"supportCategory": SupportCategory.parse(src.supportCategory),
			"registrationGroup": src.registrationGroup,
			"unitOfMeasure": UnitOfMeasure.parse(src.unitOfMeasure),
			"quote": !!src.quote,
			"region": Region.parse(src.region),
			"dateFrom": DateUtils.parse(src.dateFrom),
			"dateTo": DateUtils.parse(src.dateTo),
			"validDays": src.validDays,
			"paceSupportCategory": SupportCategory.parse(src.paceSupportCategory)
		};

		return {...a, ...b};
	}

	export function toJSON(src: SupportItem): any {
		if (!src) {
			return undefined;
		}

		const a = BasicSupportItem.toJSON(src);
		const b = {
			"id": src?.id,
			"supportCategory": SupportCategory.toJSON(src.supportCategory),
			"registrationGroup": src?.registrationGroup,
			"unitOfMeasure": UnitOfMeasure.toPostgresEnum(src.unitOfMeasure),
			"quote": src?.quote,
			"region": Region.toPostgresEnum(src.region),
			"dateFrom": DateUtils.toString(src?.dateFrom),
			"dateTo": DateUtils.toString(src?.dateTo),
			"validDays": src.validDays,
			"paceSupportCategory": SupportCategory.toJSON(src.paceSupportCategory)
		};

		return {...a, ...b};
	}

	export function clone(src: SupportItem): SupportItem {
		if (!src) {
			return undefined;
		}

		const a = BasicSupportItem.clone(src);
		const b = {
			"id": src.id,
			"supportCategory": SupportCategory.clone(src.supportCategory),
			"registrationGroup": src.registrationGroup,
			"unitOfMeasure": src.unitOfMeasure,
			"quote": src.quote,
			"region": src.region,
			"dateFrom": DateUtils.clone(src.dateFrom),
			"dateTo": DateUtils.clone(src.dateTo),
			"validDays": src.validDays,
			"paceSupportCategory": SupportCategory.clone(src.paceSupportCategory)
		};

		return {...a, ...b};
	}
//	export function parseToPACE(src: any): SupportItem {
//		if (!src) {
//			return undefined;
//		}
//
//		const a = BasicSupportItem.parse(src);
//		const b = {
//			"id": src.id,
//			"supportCategory": SupportCategory.parse(src.paceSupportCategory),
//			"registrationGroup": src.registrationGroup,
//			"unitOfMeasure": UnitOfMeasure.parse(src.unitOfMeasure),
//			"quote": !!src.quote,
//			"region": Region.parse(src.region),
//			"dateFrom": DateUtils.parse(src.dateFrom),
//			"dateTo": DateUtils.parse(src.dateTo),
//			"validDays": src.validDays,
//			"paceSupportCategory": SupportCategory.parse(src.paceSupportCategory)
//		};
//
//		return {...a, ...b};
//	}

}
