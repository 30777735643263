<!--video autoplay loop playsinline muted src="assets/potter.mp4" type="video/mp4"></video-->

<div class="container">

	<div class="box">
		<h1 class="title">Welcome to the Dark Arts</h1>
		<blockquote>
			<p class="is-italic is-size-5">"Let us step into the night and pursue that flighty temptress, adventure."</p>
			<p>- Albus Dumbledore</p>
		</blockquote>
	</div>

</div>
