import Base64Utils from "@classes/base64Utils";

export default class ImageResizer {
	public static maxImageFileSize: number = 1024 * 1024; // 1MB file size limit for images

	private originalFileSize: number;

	private readImage(content: string): Promise<HTMLImageElement> {
		return new Promise( resolve => {

			const img = document.createElement("img");
			img.crossOrigin = "Anonymous";

			const imageLoaded = (img) => {
				return resolve(img);
			}

			img.onload = imageLoaded.bind(null, img);
			img.src = content;
		});
	}

	private imageFromBlob(src: Blob): Promise<HTMLImageElement> {
		return new Promise( resolve => {
			const reader = new FileReader();
			reader.onloadend = () => {
				this.readImage(reader.result as string).then( el => {
					resolve(el);
				});
			};
			reader.readAsDataURL(src);
		});
	}

	/**
	* Determines approximate image dimensions for new image based on the desired file size. Calculation
	* is really a back-of-the-envelope rough guess, actual file size will vary dramatically based on JPEG
	* compression level applied to output image.
	*
	* Uses:
	* filesize = c * imageWidth * imageHeight, where c is a constant that approximates bit depth and compression level.
	*/
	private scaleImageToFilesize(img: HTMLImageElement): Promise<string> {
		const srcAspectRatio = 1.0 * img.naturalWidth / img.naturalHeight;
		const c = this.originalFileSize / (img.naturalWidth * img.naturalHeight);
		const newX = Math.floor( Math.sqrt( ImageResizer.maxImageFileSize * srcAspectRatio / c ) );
		const newY = Math.floor( newX / srcAspectRatio );

		const canvas = document.createElement("canvas");
		canvas.width = newX;
		canvas.height = newY;
		let context = canvas.getContext("2d");

		if (srcAspectRatio >= 1.0) {
			// Image is landscape or square
			let scaleFactor = 1.0 * img.naturalHeight / newY;
			let scaledWindowWidth = newX * scaleFactor;
			let offsetX = (img.naturalWidth - scaledWindowWidth) / 2;
			context.drawImage(img, offsetX, 0, scaledWindowWidth, img.naturalHeight, 0, 0, newX, newY);
		}
		else {
			// Image is portrait
			let scaleFactor = 1.0 * img.naturalWidth / newX;
			let scaledWindowHeight = newY * scaleFactor;
			let offsetY = (img.naturalHeight - scaledWindowHeight) / 2;
			context.drawImage(img, 0, offsetY, img.naturalWidth, scaledWindowHeight, 0, 0, newX, newY);
		}

		const result = canvas.toDataURL("image/jpeg", 0.8);
		return Promise.resolve( result );
	}

	/**
	* Scales an image to the specified width and height.
	*/
	public scaleImageToDimensions(img: HTMLImageElement, width: number, height: number, outputFormat: string): Promise<string> {
		const canvas = document.createElement("canvas");
		canvas.width = width;
		canvas.height = height;
		let context = canvas.getContext("2d");

		const xPadding = (width > img.naturalWidth) ? (width - img.naturalWidth) / 2 : 0;
		const yPadding = (height > img.naturalHeight) ? (height - img.naturalHeight) / 2 : 0;
		const destWidth = (width > img.naturalWidth) ? img.naturalWidth : width;
		const destHeight = (height > img.naturalHeight) ? img.naturalHeight : height;

		context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, xPadding, yPadding, destWidth, destHeight);
		const result = canvas.toDataURL(outputFormat);
		return Promise.resolve(result);
	}

	public fetchAndScale(url: string, maxWidth: number, maxHeight: number): Promise<string> {

		return this.readImage(url).then( img => {

			const srcAspectRatio = 1.0 * img.naturalWidth / img.naturalHeight;
			const canvas = document.createElement("canvas");
			canvas.width = maxWidth;
			canvas.height = maxHeight;
			let context = canvas.getContext("2d");

			if (srcAspectRatio >= 1.0) {
				// Image is landscape or square
				let scaleFactor = 1.0 * img.naturalHeight / maxHeight;
				let scaledWindowWidth = maxWidth * scaleFactor;
				let offsetX = (img.naturalWidth - scaledWindowWidth) / 2;
				context.drawImage(img, offsetX, 0, scaledWindowWidth, img.naturalHeight, 0, 0, maxWidth, maxHeight);
			}
			else {
				// Image is portrait
				let scaleFactor = 1.0 * img.naturalWidth / maxWidth;
				let scaledWindowHeight = maxHeight * scaleFactor;
				let offsetY = (img.naturalHeight - scaledWindowHeight) / 2;
				context.drawImage(img, 0, offsetY, img.naturalWidth, scaledWindowHeight, 0, 0, maxWidth, maxHeight);
			}

			const result = canvas.toDataURL("image/jpeg", 0.8);
			return Promise.resolve( result );
		});
	}

	public scaleBlob(blob: Blob, maxWidth: number, maxHeight: number, format: string = "image/png"): Promise<string> {
		return this.imageFromBlob(blob).then( img => {

			const srcAspectRatio = 1.0 * img.naturalWidth / img.naturalHeight;
			const canvas = document.createElement("canvas");
			canvas.width = maxWidth;
			canvas.height = maxHeight;
			let context = canvas.getContext("2d");

			if (srcAspectRatio >= 1.0) {
				// Image is landscape or square
				let scaleFactor = 1.0 * img.naturalHeight / maxHeight;
				let scaledWindowWidth = maxWidth * scaleFactor;
				let offsetX = (img.naturalWidth - scaledWindowWidth) / 2;
				context.drawImage(img, offsetX, 0, scaledWindowWidth, img.naturalHeight, 0, 0, maxWidth, maxHeight);
			}
			else {
				// Image is portrait
				let scaleFactor = 1.0 * img.naturalWidth / maxWidth;
				let scaledWindowHeight = maxHeight * scaleFactor;
				let offsetY = (img.naturalHeight - scaledWindowHeight) / 2;
				context.drawImage(img, 0, offsetY, img.naturalWidth, scaledWindowHeight, 0, 0, maxWidth, maxHeight);
			}

			const result = canvas.toDataURL(format);
			return Promise.resolve( result );
		});

	}

	public scaleToFilesize(content: string, filesize: number = ImageResizer.maxImageFileSize): Promise<string> {
		const base64 = new Base64Utils(content);
		this.originalFileSize = base64.filesize;

		return this.readImage(content).then( img => {
			return this.scaleImageToFilesize(img);
		});
	}

	public scaleToDimensions(content: string, width: number, height: number, outputFormat: string = "image/png"): Promise<string> {
		const base64 = new Base64Utils(content);
		this.originalFileSize = base64.filesize;

		return this.readImage(content).then( img => {
			return this.scaleImageToDimensions(img, width, height, outputFormat);
		});
	}
}
