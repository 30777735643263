import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AuthService } from '@services/auth.service';
import { NotificationService } from '@services/notification.service';

@Component({
  "templateUrl": "./default.component.html",
  "styleUrls": ["./default.component.scss"]
})
export class DefaultComponent implements OnInit {

	constructor(private authService: AuthService, private router: Router) {}

	ngOnInit() {
		// This page is really just a landing page for the site. Redirect to the
		// user's home page if they are logged in.
		if (this.authService.isLoggedIn) {
			this.router.navigate([ this.authService.usersHomePage ]);
		}
		else {
			this.router.navigate([ "login" ]);
		}
	}

}
