import { ExecOptionsWithStringEncoding } from "child_process";

export interface Address {
	address1: string;
	address2?: string;
	suburb: string;
	state: string;
	postcode: string;
	googleData?: any;
	addressSha1?: string;
	addressGoogleVerifiedSha1?: string;
}

export namespace Address {

	const checkfields = ["address1", "address2", "suburb", "state", "postcode"];
	const fields = ["address1", "address2", "suburb", "state", "postcode", "googleData", "addressSha1", "addressGoogleVerifiedSha1"];

	function address_components(src: Address): string[] {
		if (src.googleData) {
			if (src.googleData.addressGoogleVerifiedResponse) {
				return src.googleData.addressGoogleVerifiedResponse["address_components"] || [];
			}
		}
		return [];
	}
	
	/**
	 * Get the value for a given key in address_components
	 * 
	 * @param {Array} components address_components returned from Google maps autocomplete
	 * @param type key for desired address component
	 * @returns {String} value, if found, for given type (key)
	 */
	 function extractFromGoogleAddress(components: any[], type: string, short: boolean = false) {
		//return components.filter((component) => component.types.indexOf(type) === 0).map((item) => item.long_name).pop() || null;
		if (components.length > 1) {
			return components.filter((component) => component.types.indexOf(type) === 0 || component.types.indexOf(type) === 1).map((item) => (short ? item.short_name : item.long_name)).pop() || '';
		}
		return '';
	}

	export const states: string[] = ['ACT', 'QLD', 'NSW', 'NT', 'SA', 'TAS', 'VIC', 'WA'];

	export const types: string[] = ['residential', 'postal'];

	export function blank(): Address {
		return {
			"address1": undefined,
			"address2": undefined,
			"suburb": undefined,
			"state": undefined,
			"postcode": undefined,
			"googleData": undefined,
			"addressSha1": undefined,
			"addressGoogleVerifiedSha1": undefined
		};
	}

	export function isEmpty(address: Address): boolean {
		if ([undefined, null].includes(address)) {
			return true;
		}

		return checkfields.map( field => address[field] === undefined || address[field] === null || address[field].trim() === '').every( result => result === true );
	}

	export function parse(src: any): Address {
		if (!src) {
			return undefined;
		}

		return {
			"address1": src.address1,
			"address2": src.address2,
			"suburb": src.suburb,
			"state": src.state,
			"postcode": src.postcode,
			"googleData": src.googleData,
			"addressSha1": src.addressSha1,
			"addressGoogleVerifiedSha1": src.addressGoogleVerifiedSha1
		};
	}

	export function clone(src: Address): Address {
		if (!src) {
			return undefined;
		}

		return <Address>{...src};
	}

	export function equals(a: Address, b: Address): boolean {

		const aIsEmpty = isEmpty(a);
		const bIsEmpty = isEmpty(b);

		if (aIsEmpty && bIsEmpty) {
			return true;
		}

		if (aIsEmpty !== bIsEmpty) {
			return false;
		}

		return fields.map( field => a[field] === b[field]).every( result => result === true );
	}

	export function toJSON(src: Address): any {
		return isEmpty(src) ? {} : {...src};
	}

	
	export function googleVerified(src: Address): boolean {
		if (src.addressSha1) {
			return src.addressSha1 === src.addressGoogleVerifiedSha1;
		}
		return undefined;
	}
	
	export function gaFormattedAddress(src: Address): string {
		if (src.googleData) {
			if (src.googleData.addressGoogleVerifiedResponse) {
				if (src.googleData.addressGoogleVerifiedResponse.formatted_address) {
					return src.googleData.addressGoogleVerifiedResponse.formatted_address;
				}
			}
		}
		return undefined;
	}

	export function gaError(src: Address): string {
		if (src.googleData) {
			if (src.googleData.addressGoogleVerifiedResponse) {
				if (src.googleData.addressGoogleVerifiedResponse.error) {
					return src.googleData.addressGoogleVerifiedResponse.error;
				}
			}
		}
		return undefined;
	}
	
	export function gaVerifiedResponse(src: Address): boolean {
		if (src.googleData) {
			return !!src.googleData.addressGoogleVerifiedResponse;
		}
		return false;
	}
	
	export function gaSubpremise(src: Address): string {
		let initialSubpremise = extractFromGoogleAddress(address_components(src), "subpremise");
		if (initialSubpremise) {
			if (Number(initialSubpremise)) {
				initialSubpremise = 'Unit ' + initialSubpremise;
			}
			return initialSubpremise;
		}
		return undefined;
		//return extractFromGoogleAddress(this.address_components, "subpremise");
	}

	export function gaStreetNumber(src: Address): string {
		return extractFromGoogleAddress(address_components(src), "street_number");
	}

	export function gaRoute(src: Address): string {
		return extractFromGoogleAddress(address_components(src), "route", true);
	}

	export function gaLocality(src: Address): string {
		return extractFromGoogleAddress(address_components(src), "locality");
	}

	export function gaState(src: Address): string {
		return extractFromGoogleAddress(address_components(src), "administrative_area_level_1", true);
	}

	export function gaPostCode(src: Address): string {
		return extractFromGoogleAddress(address_components(src), "postal_code");
	}

}
