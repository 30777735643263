import { EnumMetadata } from "@classes/enumUtils";

export enum AccountStatus {
	prospective, active, inactive, deceased
}

export namespace AccountStatus {
	const enumData: EnumMetadata<AccountStatus>[] = [
		[AccountStatus.prospective, "Prospective", "prospective"],
		[AccountStatus.active, "Active", "active"],
		[AccountStatus.inactive, "Inactive", "inactive"],
		[AccountStatus.deceased, "Deceased", "deceased"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): AccountStatus {
		return values.get(value);
	}

	export function toString(value: AccountStatus): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: AccountStatus): string {
		return pgEnums.get(value);
	}

	export function allValues(): AccountStatus[] {
		return enumData.map( item => item[0] );
	}
}
