// tslint:disable:quotemark import-spacing indent object-literal-key-quotes no-namespace
import { EnumMetadata } from "@classes/enumUtils";
import { DateTime } from "luxon";
import { StringUtils, DateUtils } from "@classes/utils";
import { AttachedFile } from "@classes/files";
import { Entity } from "@classes/entities";
import { NamedEntity } from "@classes/namedEntity";
import { TimeBlog } from "./timeblog";

export interface FileNoteCategory {
	id: string;
	name: string;
	sortOrder: number;
	grouping: number;
}

export enum FileNoteStatus {
	open,
	completed,
	not_started
}

export namespace FileNoteStatus {
	const enumData: EnumMetadata<FileNoteStatus>[] = [
		[FileNoteStatus.open, "Open", "open"],
		[FileNoteStatus.completed, "Completed", "completed"],
		[FileNoteStatus.not_started, "Not Started", "not_started"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): FileNoteStatus {
		return values.get(value);
	}

	export function toString(value: FileNoteStatus): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: FileNoteStatus): string {
		return pgEnums.get(value);
	}

	export function allValues(): FileNoteStatus[] {
		return enumData.map( item => item[0] );
	}
}

export interface FileNote extends Entity {
	client: NamedEntity;
	provider: NamedEntity;
	clientName: string;
	title?: string;
	content?: string;
	owner: NamedEntity;
	createdBy: NamedEntity;
	createdDate: Date;
	lastModified?: Date;
	lastModifiedBy?: NamedEntity;
	assignedTo?: NamedEntity;
	sticky: boolean;
	billingNote: boolean;
	reminder?: Date;
	eventDate?: Date;
	status: FileNoteStatus;
	attachments: AttachedFile[];
	icon: string;
	category: string[];
	totalMinutes: number;
	cbUnits: number;
	referenceCode: string;
	timeBlogs?: TimeBlog[];
	planManager: string;
	planId: string;
	parentProcessExecution?: string;
	childProcessExecution?: string;
	childProcessId?: string;
}

export namespace FileNote {

	export function toJSON(note: FileNote): any {
		if (!note) {
			return undefined;
		}

		return {
			"id": note.id ? note.id : undefined,
			"client": NamedEntity.toJSON(note.client),
			"provider": NamedEntity.toJSON(note.provider),
			"title": StringUtils.trim(note.title),
			"content": StringUtils.trim(note.content),
			"owner": NamedEntity.toJSON(note.owner),
			"assignedTo": NamedEntity.toJSON(note.assignedTo) || NamedEntity.blank(),
			"createdBy": NamedEntity.toJSON(note.createdBy),
			"createdDate": DateUtils.toISOString(note.createdDate),
			"lastModifiedBy": NamedEntity.toJSON(note.lastModifiedBy),
			"lastModified": DateUtils.toISOString(note.lastModified),
			"sticky": note.sticky,
			"billingNote": note.billingNote,
			"reminder": DateUtils.toString(note.reminder),
			"eventDate": DateUtils.toString(note.eventDate),
			"icon": StringUtils.trim(note.icon),
			"status": FileNoteStatus.toPostgresEnum(note.status),
			"category": note.category && note.category.filter( item => !!item ) || undefined,
			"totalMinutes": note.totalMinutes,
			"cbUnits": note.cbUnits,
			"referenceCode": note.referenceCode,
			"timeBlogs": (note?.timeBlogs || []).map( timeBlog => TimeBlog.toJSON(timeBlog) ),
			"attachments": (note?.attachments || []).map( file => file.toJSON() ),
			"planManager": note.planManager,
			"planId": note.planId,
			"parentProcessExecution": note.parentProcessExecution,
			"childProcessExecution": note.childProcessExecution,
			"childProcessId": note.childProcessId
		};
	}

	export function parse(data: any): FileNote {
		if (!data) {
			return null;
		}

		return {
			"id": data.id,
			"client": NamedEntity.parse(data.client),
			"provider": NamedEntity.parse(data.provider),
			"clientName": data.clientName,
			"title": data.title,
			"content": data.content,
			"assignedTo": NamedEntity.parse(data.assignedTo),
			"owner": NamedEntity.parse(data.ownerUser),
			"sticky": !!data.sticky,
			"billingNote": !!data.billingNote,
			"reminder": DateUtils.parse(data.reminder),
			"eventDate": DateUtils.parse(data.eventDate),
			"createdDate": DateUtils.parse(data.createdDate, true),
			"createdBy": NamedEntity.parse(data.createdBy),
			"lastModified": DateUtils.parse(data.lastModified),
			"lastModifiedBy": NamedEntity.parse(data.modifiedBy),
			"status": FileNoteStatus.parse(data.status),
			"icon": data.icon,
			"category": data.category && data.category || [],
			"totalMinutes": data.totalMinutes,
			"cbUnits": data.cbUnits,
			"referenceCode": data.referenceCode,
			"timeBlogs": (data.timeBlogs || []).map( timeBlog => TimeBlog.parse(timeBlog) ),
			"planManager": data.planManager,
			"planId": data.planId,
			"attachments": (data.attachments || []).map( file => AttachedFile.parse(file) ),
			"parentProcessExecution": data.parentProcessExecution,
			"childProcessExecution": data.childProcessExecution,
			"childProcessId": data.childProcessId

		};
	}

	export function clone(src: FileNote): FileNote {
		return {
			"id": src.id,
			"client": NamedEntity.clone(src.client),
			"provider": NamedEntity.clone(src.provider),
			"clientName": src.clientName,
			"title": src.title,
			"content": src.content,
			"createdBy": NamedEntity.clone(src.createdBy),
			"owner": NamedEntity.clone(src.owner),
			"createdDate": DateUtils.clone(src.createdDate),
			"lastModified": DateUtils.clone(src.lastModified),
			"lastModifiedBy": NamedEntity.clone(src.lastModifiedBy),
			"assignedTo": NamedEntity.clone(src.assignedTo),
			"sticky": src.sticky,
			"billingNote": src.billingNote,
			"reminder": DateUtils.clone(src.reminder),
			"eventDate": DateUtils.clone(src.eventDate),
			"icon": src.icon,
			"status": src.status,
			"category": src.category && src.category || [],
			"totalMinutes": src.totalMinutes,
			"cbUnits": src.cbUnits,
			"referenceCode": src.referenceCode,
			"timeBlogs": src.timeBlogs.map (TimeBlog.clone ),
			"planManager": src.planManager,
			"planId": src.planId,
			"attachments": src.attachments.map( AttachedFile.clone ),
			"parentProcessExecution": src.parentProcessExecution,
			"childProcessExecution": src.childProcessExecution,
			"childProcessId": src.childProcessId
		};
	}

	export function newNote(): FileNote {
		return {
			"id": undefined,
			"client": NamedEntity.blank(),
			"provider": NamedEntity.blank(),
			"clientName": undefined,
			"title": "",
			"content": "",
			"owner": NamedEntity.blank(),
			"createdBy": NamedEntity.blank(),
			"createdDate": undefined,
			"lastModified": undefined,
			"lastModifiedBy": NamedEntity.blank(),
			"assignedTo": NamedEntity.blank(),
			"sticky": false,
			"billingNote": false,
			"reminder": undefined,
			"eventDate": DateTime.local().startOf('day').toJSDate(),
			"icon": undefined,
			"status": FileNoteStatus.completed,
			"category": [],
			"totalMinutes": undefined,
			"cbUnits": 0,
			"referenceCode": undefined,
			"timeBlogs": [].map( timeBlog => TimeBlog.parse(timeBlog) ),
			"planManager": undefined,
			"planId": undefined,
			"attachments": []
		};
	}

	export function isSame(a: FileNote, b: FileNote): boolean {
		const items = [a, b].map(FileNote.toJSON).map( x => JSON.stringify(x) );
		return items.length === 2 && items[0] === items[1];
	}
}
