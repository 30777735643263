import { EnumMetadata } from '@classes/enumUtils';

export enum ClientType {
  regular, opg, cso
}

// tslint:disable-next-line:no-namespace
export namespace ClientType {
  const enumData: EnumMetadata<ClientType>[] = [
    [ClientType.regular, 'Regular', 'regular'],
    [ClientType.opg, 'OPG', 'opg'],
    [ClientType.cso, 'CSO', 'cso']
  ];

  const descriptions = EnumMetadata.descriptionMap(enumData);
  const values = EnumMetadata.valueMap(enumData);
  const pgEnums = EnumMetadata.pgEnumMap(enumData);

  export function parse(value: string): ClientType {
    return values.get(value);
  }

  export function toString(value: ClientType): string {
    return descriptions.get(value);
  }

  export function toPostgresEnum(value: ClientType): string {
    return pgEnums.get(value);
  }

  export function allValues(): ClientType[] {
    return enumData.map( item => item[0] );
  }
}
