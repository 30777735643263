import { Component, OnInit, HostListener } from '@angular/core';
import { DialogService } from "@services/dialog.service";
import { BrandingService } from "@services/branding.service";
import { ThemeLoaderService } from "@services/themeLoader.service";
import { Branding, Theme } from "@classes/theme";
//import { environment } from '../../environments/environment';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	public readonly date: Date = new Date();
	//public readonly buildVersion: string = environment.appVersion;

	constructor(private brandingService: BrandingService, private themeLoader: ThemeLoaderService) {}

	public get dialogVisible(): boolean {
		return DialogService.visible;
	}

	private loadTheme(branding: Branding) {
		this.brandingService.setTheme(branding.theme);
		this.brandingService.setFavicon(branding.theme);
		this.brandingService.setDocumentTitle(branding.documentTitle);
	}

	ngOnInit() {
		this.themeLoader.themeLoaded$.subscribe( this.loadTheme.bind(this) );
		this.themeLoader.loadThemeFromDomain();
	}
}
