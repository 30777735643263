import { EnumMetadata } from "@classes/enumUtils";
import { PlanStatus } from './planStatus';
import { DateUtils } from "@classes/utils";
import { Client } from "@classes/user";
import { FileNote } from "@classes/filenotes";
import { Provider } from "@classes/provider";
import { Invoice } from "@classes/invoices";
import { Plan } from "@classes/plans";

export enum AttachmentTargetType {
	invoice, provider, plan, user, note, linked_user, other, planManager, triage, aba_line_item
};

export namespace AttachmentTargetType {
	const enumData: EnumMetadata<AttachmentTargetType>[] = [
		[AttachmentTargetType.invoice, "Invoice", "invoice"],
		[AttachmentTargetType.provider, "Provider", "provider"],
		[AttachmentTargetType.plan, "Plan", "plan"],
		[AttachmentTargetType.user, "User", "user"],
		[AttachmentTargetType.note, "File Note", "note"],
		[AttachmentTargetType.linked_user, "User", "linked_user"],
		[AttachmentTargetType.planManager, "Plan Manager", "planManager"],
		[AttachmentTargetType.triage, "Triage Item", "triage"],
		[AttachmentTargetType.aba_line_item, "ABA Line Item", "aba_line_item"],
		[AttachmentTargetType.other, "Other", "other"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: any): AttachmentTargetType {
		return values.get(value);
	}

	export function toString(value: AttachmentTargetType): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: AttachmentTargetType): string {
		return pgEnums.get(value);
	}

	export function allValues(): AttachmentTargetType[] {
		return enumData.map( item => item[0] );
	}
}

export interface AttachmentTarget {
	id: string;
	type: AttachmentTargetType;
}

export interface InvoiceTarget extends AttachmentTarget {
	date: Date;
}

export interface PlanTarget extends AttachmentTarget {
	status: PlanStatus;
}

export interface ProviderTarget extends AttachmentTarget {
	name: string;
}

export interface FileNoteTarget extends AttachmentTarget {
	title: string;
}

export interface ClientTarget extends AttachmentTarget {
	name: string;
	ndisNumber: string;
}

export namespace AttachmentTarget {
	export function parse(src: any): AttachmentTarget {

		let result = {
			"id": src.target,
			"type": AttachmentTargetType.parse(src.targetType)
		};

		switch (result.type) {
			case AttachmentTargetType.invoice:
				(<InvoiceTarget>result).date = DateUtils.parse(src.date);
				break;

			case AttachmentTargetType.plan:
				(<PlanTarget>result).status = PlanStatus.parse(src.status);
				break;

			case AttachmentTargetType.provider:
				(<ProviderTarget>result).name = src.name;
				break;

			case AttachmentTargetType.note:
				(<FileNoteTarget>result).title = src.title;
				break;
		}

		return result;
	}

	export function toJSON(src: AttachmentTarget): any {
		return {
			"id": src.id,
			"type": AttachmentTargetType.toPostgresEnum(src.type)
		};
	}

	export function user(user: Client): ClientTarget {
		return <ClientTarget> {
			"id": user.id,
			"type": AttachmentTargetType.user,
			"name": user.name,
			"ndisNumber": user.ndisNumber
		};
	}

	export function invoice(invoice: Invoice): InvoiceTarget {
		return <InvoiceTarget> {
			"id": invoice.id,
			"type": AttachmentTargetType.invoice,
			"date": invoice.invoiceDate
		};
	}

	export function provider(provider: Provider): ProviderTarget {
		return <ProviderTarget> {
			"id": provider.id,
			"type": AttachmentTargetType.provider,
			"name": provider.name
		};
	}

	export function plan(plan: Plan): PlanTarget {
		return <PlanTarget> {
			"id": plan.id,
			"type": AttachmentTargetType.plan,
			"status": plan.status
		};
	}

	export function filenote(note: FileNote): FileNoteTarget {
		return <FileNoteTarget> {
			"id": note.id,
			"type": AttachmentTargetType.note,
			"title": note.title
		};
	}

	export function planManager(id: string): AttachmentTarget {
		return <AttachmentTarget>{
			"id": id,
			"type": AttachmentTargetType.planManager
		};
	}

	export function target(id: string, type: AttachmentTargetType): AttachmentTarget {
		return <AttachmentTarget>{
			"id": id,
			"type": type
		};
	}

	export function description(target: AttachmentTarget): string {
		switch (target.type) {
			case AttachmentTargetType.user: return (<ClientTarget> target).name;
			case AttachmentTargetType.invoice: return DateUtils.toLocaleString( (<InvoiceTarget> target).date );
			case AttachmentTargetType.provider: return (<ProviderTarget> target).name;
			case AttachmentTargetType.plan: return PlanStatus.toString( (<PlanTarget> target).status );
			case AttachmentTargetType.note: return (<FileNoteTarget> target).title;
			default: return undefined;
		}
	}
}
