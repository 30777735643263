<section class="hero is-large is-align-items-center">
	<div class="hero-body">

		<article class="media">
			<figure class="media-left has-text-danger">
				<fa-icon [icon]="['fas', 'ban']" size="5x"></fa-icon>
			</figure>
			<div class="media-content">
				<h1 class="title has-text-danger">Access Denied</h1>
				<p clas="subtitle">You do not have permission to view the requested resource.</p>
				<a *ngIf="isLoggedIn" class="button" [routerLink]="usersHomePage">Home</a>
			</div>
		</article>
	</div>
</section>
