import { Component, Input } from '@angular/core';

@Component({
	"selector": "loading",
	"styleUrls": ["./loading.component.scss"],
	"templateUrl": "./loading.component.html"
})
export class LoadingComponent {
	@Input('overlay')
	public isOverlay: boolean = false;
}

