import { DateUtils } from "@classes/utils";
import { EnumMetadata } from "@classes/enumUtils";
import { ServiceAgreementStatus } from "@classes/serviceAgreementStatus";

export interface LatestPlan {
	startDate: Date;
	endDate: Date;
	planId: string;
	planStatus: string;
	onboardingStatusName: string;
	onboardingComment: string;
	serviceAgreementStatus: ServiceAgreementStatus;
	nonCurrentServiceAgreement: boolean;
	currentServiceAgreement: boolean;
	prodaPlanAttached: boolean;
	fullPlanAttached: boolean;
	rfsAttached: boolean;
}

export namespace LatestPlan {
	export function blank(): LatestPlan {
		return {
			"startDate": undefined,
			"endDate": undefined,
			"planId": undefined,
			"planStatus": undefined,
			"onboardingStatusName": undefined,
			"onboardingComment": undefined,
			"serviceAgreementStatus": undefined,
			"nonCurrentServiceAgreement": undefined,
			"currentServiceAgreement": undefined,
			"prodaPlanAttached": undefined,
			"fullPlanAttached": undefined,
			"rfsAttached": undefined
		};
	}

	export function parse(src: any): LatestPlan {
		if (!src) {
			return undefined;
		}

		return {
			"startDate": DateUtils.parse(src.startDate),
			"endDate": DateUtils.parse(src.endDate),
			"planId": src.planId,
			"planStatus": src.planStatus,
			"onboardingStatusName": src.onboardingStatusName,
			"onboardingComment": src.onboardingComment,
			"serviceAgreementStatus": src.serviceAgreementStatus,
			"nonCurrentServiceAgreement": src.nonCurrentServiceAgreement,
			"currentServiceAgreement": src.currentServiceAgreement,
			"prodaPlanAttached": src.prodaPlanAttached,
			"fullPlanAttached": src.fullPlanAttached,
			"rfsAttached": src.rfsAttached
		};
	}

	export function toJSON(src: LatestPlan): any {
		if (!src) {
			return undefined;
		}

		return {
			"startDate": DateUtils.toString(src.startDate),
			"endDate": DateUtils.toString(src.endDate),
			"planId": src.planId,
			"planStatus": src.planStatus,
			"onboardingStatusName": src.onboardingStatusName,
			"onboardingComment": src.onboardingComment,
			"serviceAgreementStatus": src.serviceAgreementStatus,
			"nonCurrentServiceAgreement": src.nonCurrentServiceAgreement,
			"currentServiceAgreement": src.currentServiceAgreement,
			"prodaPlanAttached": src.prodaPlanAttached,
			"fullPlanAttached": src.fullPlanAttached,
			"rfsAttached": src.rfsAttached
		};
	}

	export function clone(src: LatestPlan): LatestPlan {
		if (!src) {
			return undefined;
		}

		return {
			"startDate": DateUtils.clone(src.startDate),
			"endDate": DateUtils.clone(src.endDate),
			"planId": src.planId,
			"planStatus": src.planStatus,
			"onboardingStatusName": src.onboardingStatusName,
			"onboardingComment": src.onboardingComment,
			"serviceAgreementStatus": src.serviceAgreementStatus,
			"nonCurrentServiceAgreement": src.nonCurrentServiceAgreement,
			"currentServiceAgreement": src.currentServiceAgreement,
			"prodaPlanAttached": src.prodaPlanAttached,
			"fullPlanAttached": src.fullPlanAttached,
			"rfsAttached": src.rfsAttached
		};
	}
}
