import { StringUtils, DateUtils } from "@classes/utils";

export interface VerifiedCustomer {
	fullName: string;
	first: string;
	last: string;
	middle: string;
}

export namespace VerifiedCustomer {

	export function parse(src: any): VerifiedCustomer {
		if (!src) {
			return undefined;
		}

		return {
			"fullName": src.fullName,
			"first": src.first,
			"last": src.last,
			"middle": src.middle
		};
	}

	export function toJSON(src: any): VerifiedCustomer {
		if (!src) {
			return undefined;
		}

		return {
			"fullName": src.fullName,
			"first": src.first,
			"last": src.last,
			"middle": src.middle
		};
	}

	export function clone(src: any): VerifiedCustomer {
		if (!src) {
			return undefined;
		}

		return {
			"fullName": src.fullName,
			"first": src.first,
			"last": src.last,
			"middle": src.middle
		};
	}
}

export interface VerifiedAccount {
	accountNumber: string;
	maskedNumber: string;
	bsb: string;
}

export namespace VerifiedAccount {

	export function parse(src: any): VerifiedAccount {
		if (!src) {
			return undefined;
		}

		return {
			"accountNumber": src.accountNumber,
			"maskedNumber": src.maskedNumber,
			"bsb": src.bsb
		};
	}

	export function toJSON(src: any): VerifiedAccount {
		if (!src) {
			return undefined;
		}

		return {
			"accountNumber": src.accountNumber,
			"maskedNumber": src.maskedNumber,
			"bsb": src.bsb
		};
	}

	export function clone(src: any): VerifiedAccount {
		if (!src) {
			return undefined;
		}

		return {
			"accountNumber": src.accountNumber,
			"maskedNumber": src.maskedNumber,
			"bsb": src.bsb
		};
	}
}

export interface VerifiedBankAccount {
	customer: VerifiedCustomer[];
	account: VerifiedAccount[];
}

export namespace VerifiedBankAccount {

	export function parse(src: any): VerifiedBankAccount {
		if (!src) {
			return undefined;
		}

		return {
			"customer": src.customer?.map( VerifiedCustomer.parse ),
			"account": src.account?.map( VerifiedAccount.parse )
		};
	}

	export function toJSON(src: VerifiedBankAccount): any {
		if (!src) {
			return undefined;
		}

		return {
			"customer": src.customer?.map( VerifiedCustomer.toJSON ),
			"account": src.account?.map( VerifiedAccount.toJSON )
		};
	}

	export function clone(src: any): VerifiedBankAccount {
		if (!src) {
			return undefined;
		}

		return {
			"customer": src.customer?.map( VerifiedCustomer.clone ),
			"account": src.account?.map( VerifiedAccount.clone )
		};
	}
}

export interface BankStatus {
	quicklinkId: string;
	quicklinkToken: string;
	referenceCode: string;
	linkClicked: boolean;
	expiry: Date;
	sentViaEmail: Date;
	sentViaSms: Date;
	verifiedBankAccount: VerifiedBankAccount;
}

export namespace BankStatus {

	export function parse(src: any): BankStatus {
		if (!src) {
			return undefined;
		}

		return {
			"quicklinkId": src.quicklinkId,
			"quicklinkToken": src.quicklinkToken,
			"referenceCode": src.referenceCode,
			"linkClicked": src.linkClicked,
			"expiry": DateUtils.parse(src.expiry, true),
			"sentViaEmail": DateUtils.parse(src.sentViaEmail, true),
			"sentViaSms": DateUtils.parse(src.sentViaSms, true),
			"verifiedBankAccount": VerifiedBankAccount.parse(src.verifiedBankAccount)
		};
	}

	export function toJSON(src: BankStatus): any {
		if (!src) {
			return undefined;
		}

		return {
			"quicklinkId": src.quicklinkId,
			"quicklinkToken": src.quicklinkToken,
			"referenceCode": src.referenceCode,
			"linkClicked": src.linkClicked,
			"expiry": DateUtils.toISOString(src.expiry),
			"sentViaEmail": DateUtils.toISOString(src.sentViaEmail),
			"sentViaSms": DateUtils.toISOString(src.sentViaSms),
			"verifiedBankAccount": VerifiedBankAccount.toJSON(src.verifiedBankAccount)
		};
	}

	export function clone(src: BankStatus): BankStatus {
		return {
			"quicklinkId": src.quicklinkId,
			"quicklinkToken": src.quicklinkToken,
			"referenceCode": src.referenceCode,
			"linkClicked": src.linkClicked,
			"expiry": DateUtils.clone(src.expiry),
			"sentViaEmail": DateUtils.clone(src.sentViaEmail),
			"sentViaSms": DateUtils.clone(src.sentViaSms),
			"verifiedBankAccount": VerifiedBankAccount.clone(src.verifiedBankAccount)
		}
	}

	export function blank(): BankStatus {
		return {
			"quicklinkId": undefined,
			"quicklinkToken": undefined,
			"referenceCode": undefined,
			"linkClicked": undefined,
			"expiry": undefined,
			"sentViaEmail": undefined,
			"sentViaSms": undefined,
			"verifiedBankAccount": undefined
		};
	}

}
