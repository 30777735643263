import * as CryptoJS from 'crypto-js';
import { AttachmentTarget  } from '@classes/attachments';
import { AttachmentType } from '@classes/attachmentType';
import Base64Utils from "@classes/base64Utils";
import ImageResizer from "@classes/imageResizer";
import { DateUtils } from "@classes/utils";

/**
* Simple interface defining file metadata
*/
export interface FileMetaData {
	id?: string;
	name: string;
	mimeType: string;
	size: number;
	md5: string;
	dateAdded?: Date;
	description?: string;
	attachmentType?: AttachmentType;
}

namespace FileMetaData {
	export function parse(src: any): FileMetaData {
		return {
			"id": src.id,
			"name": src.name,
			"mimeType": src.mimeType,
			"size": src.size,
			"md5": src.md5,
			"dateAdded": DateUtils.parse(src.dateAdded),
			"description": src.description,
			"attachmentType": AttachmentType.parse(src.attachmentType)
		};
	}
}

export interface FileDownloader {
	loadAttachment: (attachmentId: string, clientId?: string) => Promise<any>;
}

export enum FileType {
	csv, image, pdf, document, audio
}

export namespace FileType {
	export function name(fileType: FileType): string {
		switch (fileType) {
			case FileType.csv: return "Spreadsheet";
			case FileType.image: return "Image";
			case FileType.pdf: return "PDF Document";
			case FileType.document: return "Office Document";
			case FileType.audio: return "Audio";
			default: return "Unknown";
		}
	}
}

const fileMimeTypeMatchers = new Map<FileType, RegExp[]>([
	[FileType.csv, [/^text\/csv$/, /^application\/vnd.ms-excel$/, /^application\/vnd\.oasis\.opendocument\.spreadsheet$/]],
	[FileType.pdf, [/^application\/pdf$/]],
	[FileType.image, [/^image\/.*$/]],
	[FileType.audio, [/^audio\/.*$/]],
	[FileType.document, [/^application\/msword$/, /^application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document/, /^application\/vnd\.oasis\.opendocument\.text$/]]
]);



/**
* Implementation of the FileMetaData interface, with static factory methods for initialising from differnt sources.
* Provides a static method for calculating the MD5 checksum for the specified file.
*/
export class AttachedFile implements FileMetaData {

	public static maxImageFileSize: number = 1024 * 1024; // 1MB file size limit for images

	private _md5: string;
	private _textContent: string;
	private _content: string;
	private _size: number;

	readonly file?: File;
	readonly name: string;
	readonly mimeType: string;
	readonly dateAdded?: Date;
	readonly description?: string;
	public id?: string;
	public attachmentType: AttachmentType;

	readonly targets?: AttachmentTarget[];

	get md5(): string {
		return this._md5;
	}

	get size(): number {
		return this._size;
	}

	get asBlob(): Blob {
		return this.file;
	}

	public toJSON(): any {
		return {
			"id": this.id,
			"name": this.name,
			"mimeType": this.mimeType,
			"size": this.size,
			"md5": this.md5,
			"dateAdded": DateUtils.toISOString(this.dateAdded),
			"description": this.description,
			"attachmentType": AttachmentType.toPostgresEnum(this.attachmentType)
		};
	}

	static clone(src: AttachedFile): AttachedFile {
		return new AttachedFile(undefined, src);
	}

	private get isImage(): boolean {
		return this.type === FileType.image;
	}

	private get imageTooLarge(): boolean {
		return this.size > ImageResizer.maxImageFileSize;
	}

	private readFileAsBase64(): Promise<string> {

		if (!this.file) {
			return Promise.reject( new Error("Cannot read contents of non-local file") );
		}

		return new Promise( resolve => {

			const fileLoaded = (event) => {
				return resolve(event.target.result);
			};

			const reader = new FileReader();
			reader.onloadend = fileLoaded;
			reader.readAsDataURL(this.file);
		});
	}

	private resizeImage(): Promise<string> {

		return this.readFileAsBase64().then( content => {

			const resizer = new ImageResizer();
			return resizer.scaleToFilesize(content);

		}).then( result => {

			const base64 = new Base64Utils(result);
			this._size = base64.filesize;
			this._md5 = CryptoJS.MD5(result);
			return Promise.resolve(result);

		});
	}

	get content(): Promise<string> {

		// Return cached value if available
		if (this._content) {
			return Promise.resolve(this._content);
		}

		return new Promise( (resolve, reject) => {

			if (!this.file) {
				return reject("Local file not available");
			}

			if (this.isImage && this.imageTooLarge) {
				this.resizeImage().then( result => {
					this._content = result;
					resolve(this._content);
				})
			}
			else {
				this.readFileAsBase64().then( result => {
					this._content = result;
					resolve(this._content);
				});
			}
		});
	}

	get textContent(): Promise<string> {
		// Return cached value if available
		if (this._textContent) {
			return Promise.resolve(this._textContent);
		}

		return new Promise( (resolve, reject) => {

			if (!this.file) {
				return reject("Local file not available");
			}

			const reader = new FileReader();
			reader.onloadend = (event) => {
				this._textContent = reader.result as string;
				resolve( this._textContent );
			};

			reader.readAsText(this.file);
		});
	}

	get type(): FileType|undefined {

		return Array.from(fileMimeTypeMatchers.keys()).reduce( (result, key) => {

			if (result !== undefined) {
				return result;
			}

			const match = fileMimeTypeMatchers.get(key).some( expr => expr.test(this.mimeType) );
			return match ? key : undefined;

		}, undefined);
	}

	static calcMD5(file: File): Promise<string> {

		if (typeof Worker !== 'undefined') {

			// Use a Web Worker to load the file and calculate it's MD5
			// console.log("Using web worker");

			return new Promise((resolve, reject) => {
				const worker = new Worker("@classes/filereader.worker", { type: "module", name: "filereader" });
				worker.onmessage = ({data}) => {
					worker.terminate();
					resolve( data );
				};
				worker.postMessage({"file": file});
			});
		}
		else {

			// console.log("Using main thread");

			// Fall back to using the main thread if a Web Worker is unavailable / not supported.
			return new Promise( (resolve, reject) => {

				const fileLoaded = (event) => {
					const binary = event.target.result;
					const md5 = CryptoJS.MD5(binary).toString();
					resolve( md5 );
				};

				const reader = new FileReader();
				reader.onloadend = fileLoaded;
				reader.readAsBinaryString(file);
			});
		}
	}

	public static parse(src: any): AttachedFile {
		return new AttachedFile(undefined, FileMetaData.parse(src), (src.targets || []).map( AttachmentTarget.parse ) );
	}

	public static toJSON(src: AttachedFile): any {
		return src.toJSON();
	}

	public constructor(file?: File, props?: Partial<FileMetaData>, targets?: AttachmentTarget[]) {
		if (file) {
			this.file = file;
			this.name = file.name;
			this.mimeType = file.type;
			this._size = file.size;
			this.description = props?.description;
			this.attachmentType = props?.attachmentType;
			AttachedFile.calcMD5(file).then( md5 => {
				this._md5 = md5;
			});
		}
		else if (props) {
			this.id = props.id;
			this.name = props.name;
			this._size = props.size;
			this.mimeType = props.mimeType;
			this._md5 = props.md5;
			this.dateAdded = DateUtils.clone(props.dateAdded);
			this.description = props.description;
			this.attachmentType = props.attachmentType;
		}

		this.targets = targets;
	}

	static async fromFile(file: File, description?: string, targets?: AttachmentTarget[]): Promise<AttachedFile> {
		const result = new AttachedFile(file, {"description": description}, targets);
		result._md5 = await AttachedFile.calcMD5(file);
		return result;
	}

	static fromMetaData(props: FileMetaData, targets?: AttachmentTarget[]): AttachedFile {
		return new AttachedFile(undefined, props, targets);
	}
}

/**
* Helper class defining allowed mime types for file attachments.
* Provides a method for identifying a suitable icon (from the FontAwesome collection) for
* each allowed file type.
*/
export class FileMimeTypes {
	private mimeTypes = new Map<RegExp, string>();

	constructor(...allowedFormats: FileType[]) {

		const knownTypes = new Map<FileType, any>([
			[FileType.csv, { "file-excel": fileMimeTypeMatchers.get(FileType.csv) }],
			[FileType.pdf, { "file-pdf": fileMimeTypeMatchers.get(FileType.pdf) }],
			[FileType.image, { "file-image": fileMimeTypeMatchers.get(FileType.image) }],
			[FileType.audio, { "file-audio": fileMimeTypeMatchers.get(FileType.audio) }],
			[FileType.document, { "file-word": fileMimeTypeMatchers.get(FileType.document) }]
		]);

		// Defaults to any type of file
		if (!allowedFormats || allowedFormats.length === 0) {
			allowedFormats = Array.from(knownTypes.keys());
		}

		allowedFormats.forEach( format => {

			const mimeTypesForFormat = knownTypes.get(format);
			Object.keys( mimeTypesForFormat ).forEach( icon => {
				mimeTypesForFormat[icon].forEach( mimeType => {
					this.mimeTypes.set(mimeType, icon);
				});
			});
		});
	}

	public isAllowed(mimeType: string): boolean {
		return Array.from( this.mimeTypes.keys() ).reduce( (acc, cur) => acc || cur.test(mimeType), false );
	}

	public getIcon(mimeType: string): string {
		let result = "file";

		Array.from(this.mimeTypes.keys()).forEach( re => {
			if (re.test(mimeType)) {
				result = this.mimeTypes.get(re);
			}
		});

		return result;
	}
}
