<div
	id="overlay"
	class="modal"
	*ngIf="mode !== dialogMode.none"
	[class.is-active]="isActive"
	[class.closing]="isClosing"
	[class.loading]="mode === dialogMode.wait"
	[class.error]="mode === dialogMode.error"
	[class.success]="mode === dialogMode.success"
	[class.dialog]="mode === dialogMode.dialog"
	[class.template]="mode === dialogMode.template"
	[class.panel]="mode === dialogMode.panel">

	<div class="modal-background"></div>

	<div class="modal-wrap">
		<a *ngIf="mode === dialogMode.template && !template?.hideCloseButton" class="delete is-large" (click)="triggerClose()"></a>
		<div *ngIf="mode === dialogMode.panel" resize-ew class="modal-content" [class.ready]="templateReady">
			<div class="template" *ngIf="template !== undefined">
				<ng-container [ngTemplateOutlet]="template.template" [ngTemplateOutletContext]="{'$implicit': template.context}"></ng-container>
			</div>
		</div>

		<div *ngIf="mode !== dialogMode.panel" class="modal-content" [class.ready]="templateReady">

			<ng-container [ngSwitch]="mode">
				<ng-container *ngSwitchCase="dialogMode.template">
					<div class="box template" [class.transparent]="!templateReady" *ngIf="template !== undefined">
						<ng-container [ngTemplateOutlet]="template.template" [ngTemplateOutletContext]="{$implicit: template.context}"></ng-container>
					</div>
				</ng-container>

				<div class="box message has-text-centered" *ngSwitchCase="dialogMode.wait">
					<p><spinner></spinner></p>
					<p id="overlaymessage">{{ loadingMessage }}</p>
				</div>

				<div class="box errorinfo" *ngSwitchCase="dialogMode.error">
					<h3 id="errortitle">{{ errorMessage.title }}</h3>
					<div class="mt-3" id="errormessage" [innerHtml]="errorMessage.message"></div>
					<div class="has-text-right mt-4">
						<button type="button" #dialogButton (click)="triggerClose()" class="button">{{ errorMessage.buttonText }}</button>
					</div>
				</div>

				<ng-container *ngSwitchCase="dialogMode.dialog">
					<div class="box dialog" *ngIf="dialog !== undefined">
						<h2 class="title" id="dialogtitle">{{ dialog.title }}</h2>
						<div class="mt-3" [innerHtml]="dialog.content"></div>
						<div class="has-text-right dialogbuttons">
							<button *ngFor="let btn of dialog.buttons" (click)="btn.handler()" class="button">{{ btn.text }}</button>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
</div>
