import { EnumMetadata } from "@classes/enumUtils";

/**
 * Note that this is our System Attachment Type (eg: invoice), not the File Type (eg: pdf)
 */
export enum AttachmentType {
	triage, invoice, remittance, proda_plan, full_plan, service_agreement, logo, enduring_service_agreement, statement, authorisation, payment_receipt
}

export namespace AttachmentType {
	const enumData: EnumMetadata<AttachmentType>[] = [
		[AttachmentType.triage, "Triage", "triage"],
		[AttachmentType.invoice, "Invoice", "invoice"],
		[AttachmentType.remittance, "Remittance", "remittance"],
		[AttachmentType.proda_plan, "PRODA Plan", "proda_plan"],
		[AttachmentType.full_plan, "Full Plan", "full_plan"],
		[AttachmentType.service_agreement, "Service Agreement", "service_agreement"],
		[AttachmentType.logo, "Logo", "logo"],
		[AttachmentType.enduring_service_agreement, "Enduring Service Agreement", "enduring_service_agreement"],
		[AttachmentType.statement, "Monthly Statement", "statement"],
		[AttachmentType.authorisation, "Authorisation", "authorisation"],
		[AttachmentType.payment_receipt, "Payment Receipt", "payment_receipt"]
	];

	const descriptions = EnumMetadata.descriptionMap(enumData);
	const values = EnumMetadata.valueMap(enumData);
	const pgEnums = EnumMetadata.pgEnumMap(enumData);

	export function parse(value: string): AttachmentType {
		return values.get(value);
	}

	export function toString(value: AttachmentType): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: AttachmentType): string {
		return pgEnums.get(value);
	}

	export function allValues(): AttachmentType[] {
		return enumData.map( item => item[0] );
	}
}
