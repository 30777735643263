/**
* Utility class to calculate the size of a file from it's base64-encoded data URL
*/
export default class Base64Utils {
	private readonly base64Data: string;

	private get paddingBytes() {
		const re = /={1,2}$/;
		const r = this.base64Data.match(re);
		return r === null ? 0 : r[0].length;
	}

	constructor(src: string) {
		this.base64Data = src.replace(/^.*,/, '');
	}

	get filesize(): number {
		return (this.base64Data.length * 0.75) - this.paddingBytes;
	}
}
